import React from 'react'
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from 'react';

const Index = ({
  handleChange,
  value,
}) => {
  const [searchTextShow, setSearchTextShow] = useState(false);

  return (
    <>
      <div className={`searchbox-wrapper ${searchTextShow ? "searchbox-show" : ""}`}>
        <input
          type="text"
          name="search-text"
          placeholder="Masukkan kata kunci"
          onChange={handleChange}
          defaultValue={value}
        />
        <IconButton onClick={(e) => setSearchTextShow(!searchTextShow)}>
          <Search />
        </IconButton>
      </div>
    </>
  )
}

export default (Index);