import React, { useState, useEffect, useMemo } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { connect } from "react-redux";
import _ from "lodash";
import ModalFilterDocMingguan from "./ModalFilterDocMingguan";
import ModalUnduhReportDocMingguan from "./ModalUnduhReportDocMingguan";
import { listKandang, searchKandang } from "../../../store/actions/kandang";
import { detailFarm } from "../../../store/actions/farm";
import { detailDoc } from "../../../store/actions/doc";
import DetailDoc from "./DetailDoc";
import { getPageData, getSearchText } from "../../../store/reducers/kandang";
import {
  getDetailFarmSuccess,
  getDetailFarmPending,
  getDetailFarmError,
} from "../../../store/reducers/farm";
import {
  getDetailDocSuccess,
  getDetailDocError,
  getDetailDocPending,
} from "../../../store/reducers/doc";
import ReportDocMingguan from "./ReportDocMingguan";
import { listDocMingguan, filterDocMingguan } from "../../../store/actions/doc";
import { getFiltersMingguan } from "../../../store/reducers/doc";
import { downloadReportDocMingguan } from "../../../store/actions/doc";
import {
  getDownloadDocMingguanError,
  getDownloadDocMingguanPending,
} from "../../../store/reducers/doc";

function SidebarDetailDoc(props) {
  const {
    filters,
    searchText,
    detailFarmSuccess,
    detailFarmPending,
    detailFarmError,
    detailDocSuccess,
    detailDocError,
    detailDocPending,
    filterDocMingguan,
    downloadDocMingguanPending,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [showDetail, setShowDetail] = useState("DOC Mingguan");
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [idDocSurvey, setIdDocSurvey] = useState(null);
  const [showModalUnduhReport, setShowModalUnduhReport] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    props.detailDoc(props.match.params.id);
  }, []);

  useEffect(() => {
    if (detailDocSuccess) {
      props.filterDocMingguan({
        // id_farm: {
        //   value: detailDocSuccess?.id_farm,
        //   label: detailDocSuccess?.id_farm,
        // },
        // id_cage: {
        //   value: detailDocSuccess?.id_cage,
        //   label: detailDocSuccess?.id_cage,
        // },
        id_doc_survey: {
          value: detailDocSuccess?.id,
          label: detailDocSuccess?.id,
        },
      });
    }
  }, [detailDocSuccess]);

  //   const handleSearchChange = _.debounce((e) => {
  //     // setSearchText(e.target.value);
  //     props.searchKandang(e.target.value);
  //     if (e?.target?.value !== "" && e?.target?.value?.length < 2) return;
  //     props.listKandang({
  //       length: pageData.lengthItem,
  //       page: 1,
  //       search: e.target.value === "" ? null : e.target.value,
  //       nama_farm: namaFarm,
  //       surveyor: filters.surveyor.value,
  //       status: filters.status.value,
  //     });
  //   }, 500);

  const handleUnduhReportClick = () => {
    // setShowModalUnduhReport(true);
    const param = {
      id_doc_survey: detailDocSuccess?.id,
    };

    props.downloadReportDocMingguan(param);
  };

  const switchComponent = (show) => {
    switch (show) {
      case "Detail Doc":
        return (
          <DetailDoc
            activeTab={location?.state?.activeTab}
            showDoc={location?.state?.showDoc}
            detailDocSuccess={detailDocSuccess}
            detailDocPending={detailDocPending}
            detailDocError={detailDocError}
          />
        );
      case "DOC Mingguan":
        return <ReportDocMingguan filterIdDocSurvey={idDocSurvey} />;
      default:
        return <div>Loading...</div>;
    }
  };

  const buttonSwitch = (title = "", className = "", onClick = () => {}) => {
    return (
      <Button
        variant="contained"
        disableElevation
        className={className}
        sx={{
          paddingY: "10px",
        }}
        onClick={onClick}>
        {title}
      </Button>
    );
  };

  const onDocMingguanClick = () => {
    setShowDetail("DOC Mingguan");
  };

  const onDocDetailClick = () => {
    setShowDetail("Detail Doc");
  };

  useEffect(() => {
    if (downloadDocMingguanPending) setDisabled(true);
  }, [downloadDocMingguanPending]);
  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <ArrowBack
            sx={{ cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: "/report",
                state: {
                  activeTab: location?.state?.activeTab,
                  showDoc: location?.state?.showDoc,
                },
              })
            }
          />
          <span
            className="text-extra-bold"
            style={{
              fontSize: "24px",
              fontFamily: "PlusJakartaSans-Bold",
              marginLeft: "26px",
            }}>
            Daftar Report Mingguan
          </span>
        </div>
        {showDetail === "DOC Mingguan" && (
          <div className="d-flex align-items-center">
            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                color: "black",
                fontWeight: "800",
              }}
              size="large"
              className={`filters`}
              onClick={(e) => setShowModalFilter(!showModalFilter)}>
              {filters?.minggu?.value ? <span className="dot"></span> : ``}
              Filter
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                fontWeight: "800",
                marginLeft: "12px",
                width: "inherit !important",
              }}
              size="large"
              onClick={handleUnduhReportClick}>
              Unduh Report
            </Button>
          </div>
        )}
      </div>
      {/* <Row style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Col>
          {buttonSwitch(
            "Report Kualitas DOC",
            showDetail === "Detail Doc"
              ? "detail-farm-new-btn-active"
              : "detail-farm-new-btn-non-active",
            onDocDetailClick,
          )}
          {buttonSwitch(
            "Report Mingguan",
            showDetail === "DOC Mingguan"
              ? "btn-report-new-active"
              : "btn-report-new-non-active",
            onDocMingguanClick,
          )}
        </Col>
      </Row> */}
      <div className="mt-5">{switchComponent(showDetail)}</div>

      <ModalFilterDocMingguan
        show={showModalFilter}
        setShow={setShowModalFilter}
        idDocSurvey={{
          value: detailDocSuccess?.id,
          label: detailDocSuccess?.id,
        }}
      />
      <ModalUnduhReportDocMingguan
        show={showModalUnduhReport}
        setShow={setShowModalUnduhReport}
        idDocSurvey={{
          value: detailDocSuccess?.id,
          label: detailDocSuccess?.id,
        }}
      />
    </Container>
  );
}

const mapStateToProps = ({
  listKandang,
  detailFarm,
  detailDoc,
  listDocMingguan,
  downloadReportDocMingguan,
}) => {
  return {
    detailFarmSuccess: getDetailFarmSuccess(detailFarm),
    detailFarmPending: getDetailFarmPending(detailFarm),
    detailFarmError: getDetailFarmError(detailFarm),
    pageData: getPageData(listKandang),
    searchText: getSearchText(listKandang),
    detailDocSuccess: getDetailDocSuccess(detailDoc),
    detailDocPending: getDetailDocPending(detailDoc),
    detailDocError: getDetailDocError(detailDoc),
    filters: getFiltersMingguan(listDocMingguan),
    downloadReportDocMingguanError: getDownloadDocMingguanError(
      downloadReportDocMingguan,
    ),
    downloadReportDocMingguanPending: getDownloadDocMingguanPending(
      downloadReportDocMingguan,
    ),
  };
};

export default connect(mapStateToProps, {
  detailFarm,
  listKandang,
  searchKandang,
  detailDoc,
  listDocMingguan,
  filterDocMingguan,
  downloadReportDocMingguan,
})(SidebarDetailDoc);
