import React, { useState, useEffect, useMemo } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { connect } from "react-redux";
import _ from "lodash";

import PageDetailFarm from "./PageDetailFarm";
import DaftarKandang from "./kandang";
import SearchBox from "../../components/button/buttonSearch";
import ModalFilter from "./kandang/ModalFilterKandang";
import { listKandang, searchKandang } from "../../store/actions/kandang";
import { detailFarm } from "../../store/actions/farm";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/kandang";
import {
  getDetailFarmSuccess,
  getDetailFarmPending,
  getDetailFarmError,
} from "../../store/reducers/farm";

function SidebarDetailFarm(props) {
  const {
    pageData,
    filters,
    searchText,
    detailFarmSuccess,
    detailFarmPending,
    detailFarmError,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [showDetail, setShowDetail] = useState(
    location?.state?.activeTab !== undefined
      ? location?.state?.activeTab
      : "Farm",
  );
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [namaFarm, setNamaFarm] = useState(null);
  const [idFarm, setIdFarm] = useState(null);

  useEffect(() => {
    props.detailFarm(props.match.params.id);
  }, []);

  useEffect(() => {
    if (detailFarmSuccess) {
      setIdFarm(detailFarmSuccess?.id);
      setNamaFarm(detailFarmSuccess?.nama_farm);
    }
  }, [detailFarmSuccess]);

  // const [searchText, setSearchText] = useState("");
  // const [filters, setFilters] = useState({
  //   nama_farm: {},
  //   surveyor: {},
  //   group_id: {},
  //   status: {}
  // });
  // const [pageDataKandang, setPageDataKandang] = useState({
  //   total: null,
  //   lengthItem: 10,
  //   page: 1,
  // });

  // const handlePaginationKandang = (data, page) => {
  //   let from = (page * 10) - 10;
  //   let to = (page * 10);
  //   setPageDataKandang({
  //     total: data?.length,
  //     lengthItem: 10,
  //     page: page,
  //   });

  //   return data?.slice(from, to);
  // };

  // useEffect(() => {
  //   let cloneDataCages = detailFarmSuccess?.cages?.map((item) => ({ ...item }));

  //   if (searchText) {
  //     cloneDataCages = cloneDataCages.filter((cage) => {
  //       return cage.nama.toLowerCase().includes(searchText.toLowerCase()) ||
  //         cage.barcode.toLowerCase().includes(searchText.toLowerCase()) ||
  //         cage.group.toLowerCase().includes(searchText.toLowerCase()) ||
  //         cage.surveyor.toLowerCase().includes(searchText.toLowerCase())
  //     });
  //     if (
  //       filters?.nama_farm?.value ||
  //       filters?.surveyor?.value ||
  //       filters?.group_id?.value ||
  //       filters?.status?.value
  //     ) {
  //       cloneDataCages = doFilter(cloneDataCages);
  //     }
  //   } else {
  //     cloneDataCages = doFilter(cloneDataCages);
  //   }

  //   setDataCages(
  //     handlePaginationKandang(
  //       !searchText &&
  //       !filters?.nama_farm?.value &&
  //       !filters?.surveyor?.value &&
  //       !filters?.group_id?.value &&
  //       !filters?.status?.value
  //         ? detailFarmSuccess?.cages
  //         : cloneDataCages,
  //       1
  //     )
  //   );
  // }, [filters, searchText]);

  // const doFilter = (data) => {
  //   const filterNamaFarm = filters?.nama_farm?.value;
  //   const filterSurveyor = filters?.surveyor?.value;
  //   const filterGroup = filters?.group_id?.value;
  //   const filterStatus = filters?.status?.value;

  //   if (filterNamaFarm) {
  //     data = data.filter((cage) => cage?.nama === filterNamaFarm);
  //   }
  //   if (filterSurveyor) {
  //     data = data.filter((cage) => cage?.surveyor === filterSurveyor);
  //   }
  //   if (filterGroup) {
  //     data = data.filter((cage) => cage?.group === filterGroup);
  //   }
  //   if (filterStatus) {
  //     data = data.filter((cage) => cage?.status === filterStatus);
  //   }

  //   return data;
  // };

  const handleSearchChange = _.debounce((e) => {
    // setSearchText(e.target.value);
    props.searchKandang(e.target.value);
    if (e?.target?.value !== "" && e?.target?.value?.length < 2) return;
    props.listKandang({
      length: pageData.lengthItem,
      page: 1,
      search: e.target.value === "" ? null : e.target.value,
      nama_farm: namaFarm,
      surveyor: filters.surveyor.value,
      status: filters.status.value,
    });
  }, 500);

  const switchComponent = (show) => {
    switch (show) {
      case "Kandang":
        return (
          <DaftarKandang
            showTab={showDetail}
            idFarm={idFarm}
            publicIdFarm={props?.match?.params?.id}
            filterNamaFarm={namaFarm}
          />
        );
      case "Farm":
        return (
          <PageDetailFarm
            detailFarmSuccess={detailFarmSuccess}
            detailFarmPending={detailFarmPending}
            detailFarmError={detailFarmError}
          />
        );
      default:
        return <div>Loading...</div>;
    }
  };

  const buttonSwitch = (title = "", className = "", onClick = () => {}) => {
    return (
      <Button
        variant="contained"
        disableElevation
        className={className}
        sx={{
          paddingY: "10px",
        }}
        onClick={onClick}>
        {title}
      </Button>
    );
  };

  const onKandangClick = () => {
    setShowDetail("Kandang");
  };

  const onFarmClick = () => {
    setShowDetail("Farm");
  };

  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <ArrowBack
            sx={{ cursor: "pointer" }}
            onClick={() => history.push({ pathname: "/farmmanagement" })}
          />
          <span
            className="text-extra-bold"
            style={{
              fontSize: "24px",
              fontFamily: "PlusJakartaSans-Bold",
              marginLeft: "26px",
            }}>
            Farm Management
          </span>
        </div>
        {showDetail === "Kandang" && (
          <div className="d-flex align-items-center">
            <SearchBox handleChange={handleSearchChange} value={searchText} />
            <div className="custom-devider" />
            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                color: "black",
                fontWeight: "800",
              }}
              size="large"
              className={`filters`}
              onClick={(e) => setShowModalFilter(!showModalFilter)}>
              {filters?.surveyor?.value || filters?.status?.value ? (
                <span className="dot"></span>
              ) : (
                ``
              )}
              Filter
            </Button>
          </div>
        )}
      </div>
      <Row style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Col>
          {buttonSwitch(
            "Informasi Farm",
            showDetail === "Farm"
              ? "detail-farm-new-btn-active"
              : "detail-farm-new-btn-non-active",
            onFarmClick,
          )}
          {buttonSwitch(
            "Daftar Kandang",
            showDetail === "Kandang"
              ? "btn-report-new-active"
              : "btn-report-new-non-active",
            onKandangClick,
          )}
        </Col>
      </Row>
      <div>{switchComponent(showDetail)}</div>
      <ModalFilter
        show={showModalFilter}
        setShow={setShowModalFilter}
        filterNamaFarm={namaFarm}
      />
    </Container>
  );
}

const mapStateToProps = ({ listKandang, detailFarm }) => {
  return {
    detailFarmSuccess: getDetailFarmSuccess(detailFarm),
    detailFarmPending: getDetailFarmPending(detailFarm),
    detailFarmError: getDetailFarmError(detailFarm),
    pageData: getPageData(listKandang),
    filters: getFilters(listKandang),
    searchText: getSearchText(listKandang),
  };
};

export default connect(mapStateToProps, {
  detailFarm,
  listKandang,
  searchKandang,
})(SidebarDetailFarm);
