import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import SelectComponent from "../../components/commons/forms/select";
import { useEffect, useState } from "react";
import Input from "../../components/commons/forms/authentication/input";
import TextArea from "../../components/commons/forms/textarea";
import Radio from "../../components/commons/forms/radio";
import { connect, useDispatch } from "react-redux";
import {
  getListRole,
  getListRoleError,
  getListRolePending,
} from "../../store/reducers/role";
import { listRole } from "../../store/actions/role";
import { editUser, listUser } from "../../store/actions/user";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/user";
import inputRules from "../../utils/inputRules";
// import createBrowserHistory from "history/createBrowserHistory";
import { createBrowserHistory } from "history";
import { set } from "lodash";

const ModalEditUser = (props) => {
  // membuat variabel untuk request
  const history = createBrowserHistory({ forceRefresh: true });

  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [job_position, setRole] = useState("");
  const [role_name, setRoleName] = useState("");
  const [full_name, setName] = useState("");
  const [phone_number, setHandphone] = useState(props.data.phone_number);
  const [status, setStatus] = useState("active");
  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);
  const [role_code, setRole_code] = useState("surveyor");
  const [username, setNoPelanggan] = useState("");
  const [password, setPassword] = useState("");
  const [passwordEye, setPasswordEye] = useState(false);

  // variable handle error input
  const [errorEmail, setErrorEmail] = useState("");
  const [errorRole, setErrorRole] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorHandphone, setErrorHandphone] = useState("");
  const [errorAlamat, setErrorAlamat] = useState("");
  const [errorNoPelanggan, setErrorNoPelanggan] = useState("");

  // variabel untuk opsi select
  const { listRoleSuccess, pageData, filters, searchText } = props;

  useEffect(() => {
    props.listRole();
    if (props.data) {
      setId(props.data.id);
      setEmail(props.data.email);
      setRole_code("surveyor");
      setRoleName(props.data.role_name);
      setName(props.data.full_name);
      setHandphone(props.data.phone_number);
      setStatus(props.data.status);
      setNoPelanggan(props.data.username);
      // set the error nulls
      setErrorName(``);
      setErrorRole(``);
      setErrorHandphone(``);
      setErrorAlamat(``);
      setErrorEmail(``);
    }
  }, [props.data]);

  const handleInput = (e, set, error, customRule = false) => {
    let value;
    let label = true;

    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
      label = false;
    }

    const rule = customRule ? customRule : e.target.getAttribute(`rule`);
    if (label) setRoleName(e.label ? e.label : role_name);
    if (rule !== "Email" && !value) {
      error(`${rule} wajib diisi`);
    } else {
      if (inputRules[rule]) {
        const match = new RegExp(inputRules[rule]).test(value);
        if (!match) {
          error(`Format ${rule} tidak sesuai`);
        } else error(``);
      }
      error(``);
    }
    set(value);
  };

  const handleError = (data) => {
    if (data.email) {
      const match = new RegExp(inputRules["Email"]).test(data.email);
      match ? setErrorEmail("") : setErrorEmail("Format email tidak sesuai");
    }
    data.full_name ? setErrorName("") : setErrorName("Nama wajib diisi");
    if (data.phone_number) {
      data.phone_number.length < 10
        ? setErrorHandphone("Nomor yang dimasukkan minimal 10 digit")
        : setErrorHandphone("");
    } else {
      setErrorHandphone("Nomor telepon wajib diisi");
    }
    data.alamat ? setErrorAlamat("") : setErrorAlamat("Alamat wajib diisi");
    data.job_position ? setErrorRole("") : setErrorRole("Role wajib diisi");
  };

  const setDefaultState = () => {
    setId(props.data.id);
    setEmail(props.data.email);
    setRole_code("surveyor");
    setRoleName(props.data.role_name);
    setName(props.data.full_name);
    setHandphone(props.data.phone_number);
    setStatus(props.data.status);
    setNoPelanggan(props.data.username);
    //set the error nulls
    setErrorName(``);
    setErrorRole(``);
    setErrorHandphone(``);
    setErrorAlamat(``);
    setErrorEmail(``);
  };

  const submitForm = () => {
    setDisableBtnSubmit(true);
    let data = {};
    data = {
      id,
      full_name,
      phone_number,
      job_position: role_code,
      username,
      status,
    };

    // if (email) {
    //   data.email = email.toLocaleLowerCase();
    //   const match = new RegExp(inputRules["Email"]).test(email);
    //   if (!match) {
    //     setErrorEmail("Format email tidak sesuai");
    //     setDisableBtnSubmit(false);
    //     return;
    //   } else {
    //     setErrorEmail("");
    //   }
    // }

    handleError(data);
    for (var key in data) {
      if (data[key] === "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    if (data.phone_number.length < 10) {
      setDisableBtnSubmit(false);
      return;
    }

    props.editUser(data, successEdit, failedEdit); 
  };

  const successEdit = () => {
    setTimeout(() => {
      props.setShow(false);
      setDefaultState();
      props.listUser({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        role_code: filters.role_code.value,
        status: filters.status.value,
      });
      setDisableBtnSubmit(false);
    }, 1000);
    //history.push("/usermanagement")
  };

  const failedEdit = () => {
    setDisableBtnSubmit(false);
  };

  const handleButtonBatal = () => {
    setDefaultState();
    props.setShow(false);
  };

  return (
    <div>
      <Modal
        size={"md"}
        onHide={(e) => props.setShow(false)}
        show={props.show}
        centered>
        <ModalHeader closeButton>Edit User</ModalHeader>
        <ModalBody>
          <SelectComponent
            options={
              listRoleSuccess && listRoleSuccess[1].value === "surveyor"
                ? [{ value: "surveyor", label: "Kepala Kandang" }]
                : []
            }
            label={"Role"}
            isAsync={true}
            asyncUrl={"/v1/role"}
            onChange={(e) => handleInput(e, setRole_code, setErrorRole, `Role`)}
            value={{ value: "surveyor", label: "Kepala Kandang" }}
            isError={errorRole !== ``}
            error={errorRole}
            rule={"role"}
          />
          <Input
            label={"No. Pelanggan"}
            placeholder={"Masukkan nomor pelanggan"}
            onChange={(e) =>
              handleInput(e, setNoPelanggan, setErrorNoPelanggan)
            }
            value={username || ""}
            rule={"Nomor Pelanggan"}
            error={errorNoPelanggan}
          />
          <Input
            label={"Nama"}
            placeholder={"Masukkan nama"}
            onChange={(e) => handleInput(e, setName, setErrorName)}
            value={full_name || ""}
            rule={"Nama"}
            error={errorName}
          />
          <Input
            label={"Nomor Telepon"}
            placeholder={"Masukkan nomor telepon"}
            onChange={(e) => handleInput(e, setHandphone, setErrorHandphone)}
            defaultValue={props.data.phone_number || ""}
            rule={`Nomor telepon`}
            error={errorHandphone}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            maxLength={13}
          />
          <Input
            label={"Email"}
            placeholder={"Masukkan email"}
            onChange={(e) => handleInput(e, setEmail, setErrorEmail)}
            value={email || ""}
            rule="Email"
            error={errorEmail}
            isError={errorEmail !== ``}
          />

          <hr style={{ margin: "50px 0" }} />
          <Radio status={status} setStatus={setStatus} />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            disableElevation
            className="btn-btl-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={handleButtonBatal}>
            Batal
          </Button>

          <Button
            variant="contained"
            disableElevation
            className="btn-simpan-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={(e) => submitForm()}
            disabled={disableBtnSubmit}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listRole, listUser }) {
  return {
    listRolePending: getListRolePending(listRole),
    listRoleSuccess: getListRole(listRole),
    listRoleError: getListRoleError(listRole),
    pageData: getPageData(listUser),
    filters: getFilters(listUser),
    searchText: getSearchText(listUser),
  };
}

export default connect(mapStateToProps, { listRole, editUser, listUser })(
  ModalEditUser,
);
