import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../../utils/constants";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Container } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import Pagination from "../../../components/commons/table/pagination";
import { listStandarGrower } from "../../../store/actions/report";
import {
  getDataStandartGrower,
  getDataStandartGrowerError,
  getDataStandartGrowerPending,
} from "../../../store/reducers/report";
import { getSearchText } from "../../../store/reducers/report";
import {
  listDocMingguan,
  detailMingguanDoc,
  filterDocMingguan,
  searchDoc,
} from "../../../store/actions/doc";
import {
  getDetailDocMingguanError,
  getDetailDocMingguanSuccess,
  getDetailDocMingguanPending,
  getListDocMingguan,
  getListDocErrorMingguan,
  getListDocPendingMingguan,
  getFiltersMingguan,
  getPageDataMingguan,
} from "../../../store/reducers/doc";

const Index = (props) => {
  const {
    pageData,
    filters,
    searchText,
    listGrowerSuccess,
    listDocMingguanSuccess,
    listDocMingguanPending,
    listDocMingguanError,
  } = props;

  const history = useHistory();
  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalUnduhReport, setShowModalUnduhReport] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [idSurvey, setIdSurvey] = useState([]);
  const dispatch = useDispatch();
  const standarGrower = useSelector((state) => state.ip);

  useEffect(() => {
    props.listDocMingguan({
      length: pageData.lengthItem,
      page: pageData.page,
      search_text: searchText === "" ? null : searchText,
      //   id_farm: filters?.id_farm?.value,
      //   id_cage: filters?.id_cage?.value,
      id_doc_survey: filters?.id_doc_survey?.value,
      minggu: filters?.minggu ? filters?.minggu?.value : "",
    });
  }, [filters]);

  useEffect(() => {
    if (listDocMingguanSuccess) {
      setTotalData(listDocMingguanSuccess?.length);
    }
  }, [listDocMingguanSuccess]);

  useEffect(() => {
    props.listStandarGrower();
  }, []);

  useEffect(() => {
    dispatch(listStandarGrower());
  }, [dispatch]);

  useEffect(() => {}, [standarGrower]);

  const handleDetailButtonClick = (id) => {
    history.push(`/report/${id}`);
  };

  const handleUnduhReportClick = () => {
    setShowModalUnduhReport(true);
  };

  const reportLists = (data, error) => {
    if (!data || error) {
      return (
        <TableRow>
          <TableCell colSpan={14} align="center">
            Gagal Mengambil Data Report
          </TableCell>
        </TableRow>
      );
    }
    return data?.length < 1 ? (
      <TableRow>
        <TableCell colSpan={14} align="center">
          Data Report tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data?.map((row, i) => {
        const parseAge = parseInt(row.minggu);
        const min = listGrowerSuccess?.data[row.minggu]?.body_weight;
        const max = listGrowerSuccess?.data[parseAge + 1]?.body_weight;

        return (
          <TableRow key={i}>
            <TableCell align="center">{i + 1}</TableCell>
            <TableCell>
              {row?.tanggal
                ? moment(row?.tanggal)?.format("DD MMMM YYYY")
                : "-"}
            </TableCell>
            <TableCell>{row?.minggu ?? ""}</TableCell>
            <TableCell>
              {numberWithCommas(String(row?.jumlah_doc_mati)) ?? 0}
            </TableCell>
            <TableCell>
              {numberWithCommas(String(row?.berat_pakan)) ?? 0}
            </TableCell>
            <TableCell>
              <div style={{ width: "100%" }}>
                {numberWithCommas(String(row?.berat_badan)) ?? 0}
              </div>
            </TableCell>
            <TableCell>{row?.uniformity}</TableCell>
            <TableCell>
              <div
                style={{ width: "100%" }}
                className={
                  row?.kualitas_ayam <= min - 0.1 * min
                    ? "btn-status btn-sm bg-danger rounded"
                    : row?.kualitas_ayam < min &&
                      row?.kualitas_ayam >= min - 0.1 * min
                    ? "btn-status btn-sm bg-warning rounded"
                    : row?.kualitas_ayam >= min && row?.kualitas_ayam <= max
                    ? "btn-status btn-sm bg-success rounded"
                    : "btn-status btn-sm bg-dark rounded"
                }>
                {numberWithCommas(String(row?.kualitas_ayam)) ?? 0}
              </div>
            </TableCell>
            <TableCell colSpan={2}>
              {min && max ? `${min} - ${max - 1}` : "0-0"}
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  return (
    <Container>
      <>
        <TableContainer className="mb-4">
          <Table className="table-with-border">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  rowSpan={2}
                  sx={{ fontWeight: "800", paddingY: "16px" }}>
                  NO
                </TableCell>
                <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                  TANGGAL
                </TableCell>
                <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                  MINGGU
                </TableCell>
                <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                  JUMLAH DOC MATI (EKOR)
                </TableCell>
                <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                  BERAT PAKAN (KG)
                </TableCell>
                <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                  BERAT BADAN (GR)
                </TableCell>
                <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                  UNIFORMITY (%)
                </TableCell>
                <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                  KUALITAS DOC
                </TableCell>
                <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                  RANGE STANDAR KUALITAS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listDocMingguanPending ? (
                <TableRow>
                  <TableCell colSpan={14} align="center">
                    Mengambil data report ...
                  </TableCell>
                </TableRow>
              ) : (
                reportLists(listDocMingguanSuccess, listDocMingguanError)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          totalData={totalData}
          pageData={pageData}
          handleClickPage={(page) => {
            if (page) {
              props.listDocMingguan({
                length: pageData.lengthItem,
                page: page,
                search: searchText === "" ? null : searchText,
                id_doc_survey: filters?.id_doc_survey?.value,
                minggu: filters?.minggu ? filters?.minggu?.value : "",
              });
            }
          }}
        />
        <div style={{ marginBottom: "210px" }}></div>
      </>
    </Container>
  );
};

const mapStateToProps = ({ detailMingguanDoc, listDocMingguan, listIP }) => {
  return {
    pageData: getPageDataMingguan(listDocMingguan),
    filters: getFiltersMingguan(listDocMingguan),
    searchText: getSearchText(listDocMingguan),
    listDocMingguanSuccess: getListDocMingguan(listDocMingguan),
    listDocMingguanPending: getListDocPendingMingguan(listDocMingguan),
    listDocMingguanError: getListDocErrorMingguan(listDocMingguan),
    detailDocMingguanSuccess: getDetailDocMingguanSuccess(detailMingguanDoc),
    detailDocMingguanPending: getDetailDocMingguanPending(detailMingguanDoc),
    detailDocMingguanError: getDetailDocMingguanError(detailMingguanDoc),
    listGrowerSuccess: getDataStandartGrower(listIP),
    listGrowerPending: getDataStandartGrowerPending(listIP),
    listGrowerError: getDataStandartGrowerError(listIP),
  };
};

export default connect(mapStateToProps, {
  filterDocMingguan,
  searchDoc,
  listStandarGrower,
  detailMingguanDoc,
  listDocMingguan,
})(Index);
