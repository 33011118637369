import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  Button,
} from "@mui/material";
import { Container, Navbar, Row, Col } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import ModalFilterReport from "./ModalFilterReport";
import ModalUnduhReport from "./ModalUnduhReport";
import SearchBoxFarm from "../../components/button/buttonSearch";
import Pagination from "../../components/commons/table/pagination";
import DetailButton from "../../components/commons/table/buttonAction";
import DOCReport from "./docmanagement";
import {
  listReport,
  filterReport,
  searchReport,
  listStandarGrower,
} from "../../store/actions/report";
import {
  getListReport,
  getListReportPending,
  getListReportError,
  getPageData,
  getFilters,
  getSearchText,
  getDataStandartGrower,
  getDataStandartGrowerPending,
  getDataStandartGrowerError,
} from "../../store/reducers/report";
import { listDoc, searchDoc } from "../../store/actions/doc";
import { getSearchTextDoc, getListDoc } from "../../store/reducers/doc";

const Index = (props) => {
  const {
    pageData,
    listReportSuccess,
    listReportPending,
    listReportError,
    filters,
    searchText,
    listGrowerSuccess,
  } = props;

  const history = useHistory();
  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
  const [showDetail, setShowDetail] = useState("Produksi");
  const [activeTab, setActiveTab] = useState(
    history?.location?.state?.activeTab ?? "Produksi",
  );
  const [showModalUnduhReport, setShowModalUnduhReport] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const dispatch = useDispatch();
  const standarGrower = useSelector((state) => state.ip);

  useEffect(() => {
    props.listStandarGrower();
  }, []);

  useEffect(() => {
    dispatch(listStandarGrower());
  }, [dispatch]);

  useEffect(() => {}, [standarGrower]);

  useEffect(() => {
    props.listReport({
      length: pageData.lengthItem,
      page: pageData.page,
      search: searchText === "" ? null : searchText,
      id_farm: filters?.farm ? filters?.farm?.value : null,
      id_cage: filters?.kandang ? filters?.kandang?.value : null,
      id_period: filters?.period ? filters?.period?.value : null,
      tanggal_mulai: filters?.tanggal_mulai,
      tanggal_akhir: filters?.tanggal_akhir,
      tanggal_mulai_pullet_in: filters?.tanggal_mulai_pullet_in,
      tanggal_akhir_pullet_in: filters?.tanggal_akhir_pullet_in,
      ip: filters?.ip?.value,
    });
  }, [filters]);

  useEffect(() => {
    if (listReportSuccess) setTotalData(listReportSuccess?.length);
  }, [listReportSuccess]);

  const handleResetState = () => {
    props.searchReport(null);
    props.filterReport({
      farm: {},
      kandang: {},
      period: {},
      tanggal_mulai: null,
      tanggal_akhir: null,
      tanggal_mulai_pullet_in: null,
      tanggal_akhir_pullet_in: null,
      ip: {},
    });
    props.listReport({
      length: 10,
      page: 1,
    });
  };

  useEffect(() => {
    return () => handleResetState();
  }, []);

  const handleSearchChange = _.debounce((e) => {
    props.searchReport(e.target.value);
    if (e?.target?.value !== "" && e?.target?.value?.length < 2) return;
    props.listReport({
      length: pageData.lengthItem,
      page: 1,
      search: e.target.value === "" ? null : e.target.value,
      id_farm: filters?.farm ? filters?.farm?.value : null,
      id_cage: filters?.kandang ? filters?.kandang?.value : null,
      id_period: filters?.period ? filters?.period?.value : null,
      tanggal_mulai: filters?.tanggal_mulai,
      tanggal_akhir: filters?.tanggal_akhir,
      tanggal_mulai_pullet_in: filters?.tanggal_mulai_pullet_in,
      tanggal_akhir_pullet_in: filters?.tanggal_akhir_pullet_in,
      ip: filters?.ip?.value,
    });
  }, 500);

  const handleDetailButtonClick = (id) => {
    history.push(`/report/${id}`);
  };

  const handleUnduhReportClick = () => {
    setShowModalUnduhReport(true);
  };

  const reportLists = (data, error) => {
    if (!data || error) {
      return (
        <TableRow>
          <TableCell colSpan={14} align="center">
            Gagal Mengambil Data Report
          </TableCell>
        </TableRow>
      );
    }
    return data?.length < 1 ? (
      <TableRow>
        <TableCell colSpan={14} align="center">
          Data Report tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data?.map((row, i) => {
        const parseAge = parseInt(row.umur_mingguan);
        const min = listGrowerSuccess?.data[row.umur_mingguan]?.body_weight;
        const max = listGrowerSuccess?.data[parseAge + 1]?.body_weight;


        return (
          <TableRow key={i}>
            <TableCell align="center">{i + 1}</TableCell>
            <TableCell>
              {row?.period?.tanggal_mulai
                ? moment(row?.period?.tanggal_mulai)?.format("DD/MM/YYYY")
                : "-"}
            </TableCell>
            <TableCell>
              {row?.period?.tanggal_pullet_in
                ? moment(row?.period?.tanggal_pullet_in)?.format("DD/MM/YYYY") +
                  " / "
                : "-"}
              <br />
              {row?.umur_mingguan ? `${row?.umur_mingguan} Minggu` : "-"}
            </TableCell>
            <TableCell>
              {row?.tanggal ? moment(row?.tanggal)?.format("DD/MM/YYYY") : "-"}
            </TableCell>
            <TableCell>
              {row?.farm?.nama_farm
                ? row?.farm?.nama_farm?.length > 20
                  ? row?.farm?.nama_farm?.slice(0, 20) + "..." + " / "
                  : row?.farm?.nama_farm + " / "
                : "-"}
              <br />
              {row?.cage?.barcode ?? "-"}
            </TableCell>
            <TableCell>{row?.jumlah_produksi_telur ?? 0}</TableCell>
            <TableCell>{row?.berat_produksi_telur ?? 0}</TableCell>
            <TableCell>{row?.jumlah_produksi_abnormal ?? 0}</TableCell>
            <TableCell>{row?.berat_produksi_abnormal ?? 0}</TableCell>
            <TableCell>{row?.jumlah_mati ?? 0}</TableCell>
            <TableCell>{row?.jumlah_afkir ?? 0}</TableCell>
            <TableCell>{row?.fcr ?? "-"}</TableCell>
            <TableCell>
              <div
                style={{ width: "100%" }}
                className={
                  row?.ip <= min - 0.1 * min
                    ? "btn-status btn-sm bg-danger rounded"
                    : row?.ip < min && row?.ip >= min - 0.1 * min
                    ? "btn-status btn-sm bg-warning rounded"
                    : row?.ip >= min && row?.ip <= max
                    ? "btn-status btn-sm bg-success rounded"
                    : "btn-status btn-sm bg-dark rounded"
                }>
                {row?.ip}
              </div>
              {/* <div style={{ width: "100%" }}>{`${min}-${max - 1}`}</div> */}
            </TableCell>
            <TableCell colSpan={2}>{`${min}-${max - 1}`}</TableCell>
            <TableCell align="center">
              <DetailButton>
                <MenuItem
                  test={row.id}
                  onClick={(e) => handleDetailButtonClick(row?.id)}
                  sx={styleMenuItem}>
                  Detail
                </MenuItem>
              </DetailButton>
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  const buttonSwitch = (title = "", className = "", onClick = () => {}) => {
    return (
      <Button
        variant="contained"
        disableElevation
        className={className}
        sx={{
          paddingY: "10px",
        }}
        onClick={() => setActiveTab(title)}
        // onClick={onClick}
      >
        {title}
      </Button>
    );
  };

  const onProduksiClick = () => {
    setShowDetail("Produksi");
    setActiveTab("Produksi");
  };

  const onDOCClick = () => {
    setShowDetail("DOC");
    setActiveTab("DOC");
  };

  return (
    <Container>
      <ModalFilterReport show={showModalFilter} setShow={setShowModalFilter} />
      <ModalUnduhReport
        show={showModalUnduhReport}
        setShow={setShowModalUnduhReport}
      />

      <Navbar>
        <>
          <Navbar.Brand>
            <h1 className="judul-header ms-2">Daftar Report</h1>
          </Navbar.Brand>
        </>
      </Navbar>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          {/* {buttonSwitch(
            "Produksi",
            showDetail === "Produksi"
              ? "btn-report-new-active"
              : "btn-report-new-non-active",
            onProduksiClick,
          )}
          {buttonSwitch(
            "DOC",
            showDetail === "DOC"
              ? "btn-report-new-active"
              : "btn-report-new-non-active",
            onDOCClick,
          )} */}
          {buttonSwitch(
            "Produksi",
            activeTab === "Produksi"
              ? "btn-report-new-active"
              : "btn-report-new-non-active",
          )}
          {buttonSwitch(
            "DOC",
            activeTab === "DOC"
              ? "btn-report-new-active"
              : "btn-report-new-non-active",
          )}
        </Col>
      </Row>
      {activeTab === "Produksi" ? (
        <>
          <Navbar>
            <>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end mb-3">
                {activeTab === "Produksi" && (
                  <>
                    <SearchBoxFarm
                      handleChange={handleSearchChange}
                      value={searchText}
                    />
                    <Button
                      color="secondary"
                      variant="outlined"
                      disableElevation
                      sx={{
                        borderRadius: 28,
                        paddingY: "10px",
                        textTransform: "capitalize",
                        color: "black",
                        fontWeight: "800",
                      }}
                      size="large"
                      className={`filters`}
                      onClick={(e) => setShowModalFilter(!showModalFilter)}>
                      {filters?.farm?.value ||
                      filters?.kandang?.value ||
                      filters?.period?.value ||
                      filters?.tanggal_mulai ||
                      filters?.tanggal_akhir ||
                      filters?.tanggal_mulai_pullet_in ||
                      filters?.tanggal_akhir_pullet_in ||
                      filters?.ip?.value ? (
                        <span className="dot"></span>
                      ) : (
                        ``
                      )}
                      Filter
                    </Button>
                    <div className="custom-devider" />
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      sx={{
                        borderRadius: 28,
                        paddingY: "10px",
                        textTransform: "capitalize",
                        fontWeight: "800",
                        marginLeft: "12px",
                        width: "inherit !important",
                      }}
                      size="large"
                      onClick={handleUnduhReportClick}>
                      Unduh Report
                    </Button>
                  </>
                )}
              </Navbar.Collapse>
            </>
          </Navbar>
          <TableContainer className="mb-4">
            <Table className="table-with-border">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{ fontWeight: "800", paddingY: "16px" }}>
                    NO
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                    PERIODE
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                    PULLET IN /
                    <br />
                    WOA
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                    TANGGAL REPORT
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                    NAMA FARM /
                    <br />
                    KANDANG
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={4}
                    sx={{ fontWeight: "800" }}>
                    TELUR
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                    JUMLAH KEMATIAN (EKOR)
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                    JUMLAH AFKIR (EKOR)
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                    FCR
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                    IP INDIKATOR
                  </TableCell>
                  <TableCell rowSpan={2} colSpan={2} sx={{ fontWeight: "800" }}>
                    RANGE STANDAR IP
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "800" }}>
                    NORMAL (BUTIR)
                  </TableCell>
                  <TableCell sx={{ fontWeight: "800" }}>BERAT (KG)</TableCell>
                  <TableCell sx={{ fontWeight: "800" }}>
                    ABNORMAL (BUTIR)
                  </TableCell>
                  <TableCell sx={{ fontWeight: "800" }}>BERAT (KG)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listReportPending ? (
                  <TableRow>
                    <TableCell colSpan={14} align="center">
                      Mengambil data report ...
                    </TableCell>
                  </TableRow>
                ) : (
                  reportLists(listReportSuccess, listReportError)
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            totalData={totalData}
            pageData={pageData}
            handleClickPage={(page) => {
              if (page) {
                props.listReport({
                  length: pageData.lengthItem,
                  page: page,
                  search: searchText === "" ? null : searchText,
                  id_farm: filters?.farm ? filters?.farm?.value : null,
                  id_cage: filters?.kandang ? filters?.kandang?.value : null,
                  id_period: filters?.period ? filters?.period?.value : null,
                  tanggal_mulai: filters?.tanggal_mulai,
                  tanggal_akhir: filters?.tanggal_akhir,
                  tanggal_mulai_pullet_in: filters?.tanggal_mulai_pullet_in,
                  tanggal_akhir_pullet_in: filters?.tanggal_akhir_pullet_in,
                  ip: filters?.ip?.value,
                });
              }
            }}
          />
          <div style={{ marginBottom: "210px" }}></div>{" "}
        </>
      ) : (
        <DOCReport activeTab={activeTab} showDoc={showDetail} />
      )}
    </Container>
  );
};

const mapStateToProps = ({ listReport, listIP, listDoc }) => {
  return {
    listReportSuccess: getListReport(listReport),
    listReportPending: getListReportPending(listReport),
    listReportError: getListReportError(listReport),
    pageData: getPageData(listReport),
    filters: getFilters(listReport),
    searchText: getSearchText(listReport),
    listGrowerSuccess: getDataStandartGrower(listIP),
    listGrowerPending: getDataStandartGrowerPending(listIP),
    listGrowerError: getDataStandartGrowerError(listIP),
    listDocSuccess: getListDoc(listDoc),
    searchTextDoc: getSearchTextDoc(listDoc),
  };
};

export default connect(mapStateToProps, {
  listReport,
  filterReport,
  searchReport,
  listStandarGrower,
  listDoc,
  searchDoc,
})(Index);
