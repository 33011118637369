import { actionSuccess, actionError, actionPending } from "../action_types";
import { instance as API } from "../API";
import { toastSuccess, toastError } from "../../../components/toast";

export const UPDATE_KANDANG_PAGE = `UPDATE_KANDANG_PAGE`;
export const LIST_KANDANG_PENDING = `LIST_KANDANG_PENDING`;
export const LIST_KANDANG_SUCCESS = `LIST_KANDANG_SUCCESS`;
export const LIST_KANDANG_ERROR = `LIST_KANDANG_ERROR`;
export const UPDATE_FILTER_KANDANG = `UPDATE_FILTER_KANDANG`;
export const UPDATE_SEARCH_KANDANG = `UPDATE_SEARCH_KANDANG`;
export const LIST_SURVEYOR_PENDING = `LIST_SURVEYOR_PENDING`;
export const LIST_SURVEYOR_SUCCESS = `LIST_SURVEYOR_SUCCESS`;
export const LIST_SURVEYOR_ERROR = `LIST_SURVEYOR_ERROR`;
export const LIST_AREA_PENDING = `LIST_AREA_PENDING`;
export const LIST_AREA_SUCCESS = `LIST_AREA_SUCCESS`;
export const LIST_AREA_ERROR = `LIST_AREA_ERROR`;
export const LIST_GROUP_PENDING = `LIST_GROUP_PENDING`;
export const LIST_GROUP_SUCCESS = `LIST_GROUP_SUCCESS`;
export const LIST_GROUP_ERROR = `LIST_GROUP_ERROR`;
export const EDIT_KANDANG_PENDING = `EDIT_KANDANG_PENDING`;
export const EDIT_KANDANG_SUCCESS = `EDIT_KANDANG_SUCCESS`;
export const EDIT_KANDANG_ERROR = `EDIT_KANDANG_ERROR`;
export const DELETE_KANDANG_PENDING = `DELETE_KANDANG_PENDING`;
export const DELETE_KANDANG_SUCCESS = `DELETE_KANDANG_SUCCESS`;
export const DELETE_KANDANG_ERROR = `DELETE_KANDANG_ERROR`;
export const DETAIL_KANDANG_PENDING = `DETAIL_KANDANG_PENDING`;
export const DETAIL_KANDANG_SUCCESS = `DETAIL_KANDANG_SUCCESS`;
export const DETAIL_KANDANG_ERROR = `DETAIL_KANDANG_ERROR`;

const KANDANG_URL = `/v1/cage`;
const DETAIL_KANDANG_URL = `/v1/cage/detail`;
const LIST_KEPKAN_URL = `/v1/user/surveyor`;
const LIST_AREA_URL = `/v1/area`;
const LIST_GROUP_URL = `/v1/group`;

export const listKandang =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_KANDANG_PENDING));
    API.get(KANDANG_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_KANDANG_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_KANDANG_PAGE, param.page));
        }
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_KANDANG_ERROR, error));
        toastError(error, "Gagal Mengambil Data Kandang, ");
      });
  };

export const filterKandang =
  (param = {}) =>
  (dispatch) => {
    const nama_farm = param.nama_farm ? param.nama_farm : {};
    const group_id = param.group_id ? param.group_id : {};
    const surveyor = param.surveyor ? param.surveyor : {};
    const status = param.status ? param.status : {};
    dispatch(
      actionSuccess(UPDATE_FILTER_KANDANG, {
        nama_farm,
        group_id,
        surveyor,
        status,
      }),
    );
  };

export const listSurveyor =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_SURVEYOR_PENDING));
    API.get(LIST_KEPKAN_URL, { params: param })
      .then((res) => {
        const listSurveyor = [];
        res.data.data.map((v) => {
          listSurveyor.push({
            value: v.id,
            label: v.full_name,
          });
        });
        dispatch(actionSuccess(LIST_SURVEYOR_SUCCESS, listSurveyor));
      })
      .catch((error) => {
        dispatch(actionError(LIST_SURVEYOR_ERROR, error));
      });
  };

export const listArea =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_AREA_PENDING));
    API.get(LIST_AREA_URL, { params: param })
      .then((res) => {
        const listArea = [];
        res.data.data.map((v) => {
          listArea.push({
            value: v.area_id,
            label: v.area_name,
          });
        });
        dispatch(actionSuccess(LIST_AREA_SUCCESS, listArea));
      })
      .catch((error) => {
        dispatch(actionError(LIST_AREA_ERROR, error));
      });
  };

export const listGroup =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_GROUP_PENDING));
    API.get(LIST_GROUP_URL, { params: param })
      .then((res) => {
        const listGroup = [];
        res.data.data.map((v) => {
          listGroup.push({
            value: v.group_name,
            label: v.group_name,
          });
        });
        dispatch(actionSuccess(LIST_GROUP_SUCCESS, listGroup));
      })
      .catch((error) => {
        dispatch(actionError(LIST_GROUP_ERROR, error));
      });
  };

export const searchKandang =
  (param = "") =>
  (dispatch) => {
    dispatch(actionSuccess(UPDATE_SEARCH_KANDANG, param));
  };

export const editKandang =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(EDIT_KANDANG_PENDING));
    API.put(KANDANG_URL, param)
      .then((res) => {
        dispatch(actionSuccess(EDIT_KANDANG_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(EDIT_KANDANG_ERROR, error));
        toastError(error?.response?.data?.message, "");
        callbackFailed();
      });
  };

export function detailKandang(kandangId = null) {
  return (dispatch) => {
    API.get(`${DETAIL_KANDANG_URL}?id=${kandangId}`, { params: {} })
      .then((res) => {
        dispatch(actionSuccess(DETAIL_KANDANG_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_KANDANG_ERROR, error));
        toastError(error?.response?.data?.message, "");
      });
  };
}

export function deleteKandang(kandangId = null) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_KANDANG_PENDING));
    API.delete(`${KANDANG_URL}?id=${kandangId}`, { params: {} })
      .then((res) => {
        dispatch(actionSuccess(DELETE_KANDANG_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DELETE_KANDANG_ERROR, error));
        toastError(error?.response?.data?.message, "");
      });
  };
}
