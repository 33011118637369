import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import { numberWithCommas } from "../../utils/constants";

function PageDetailFarm(props) {
  const history = useHistory();
  const { detailFarmSuccess, detailFarmPending, detailFarmError } = props;

  return (
    <>
      {detailFarmPending ? (
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{ height: "50%" }}>
          Mengambil data farm...
        </div>
      ) : detailFarmError ? (
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{ height: "50%" }}>
          Gagal mengambil data farm...
        </div>
      ) : (
        <div className="mb-5">
          <ListGroup className="cek" style={{ borderRadius: "12px" }}>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">ID Farm</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.public_id
                      ? detailFarmSuccess?.public_id
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Nama Farm</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.nama_farm
                      ? detailFarmSuccess?.nama_farm
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Nama Group</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.nama_group
                      ? detailFarmSuccess?.nama_group
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Area</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.nama_area
                      ? detailFarmSuccess?.nama_area
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Alamat</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.alamat
                      ? detailFarmSuccess?.alamat
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Latitude</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.latitude
                      ? detailFarmSuccess?.latitude
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Longitude</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.longitude
                      ? detailFarmSuccess.longitude
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Surveyor</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.surveyor_fullname
                      ? detailFarmSuccess?.surveyor_fullname
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">No. Telepon</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.nomor_telepon
                      ? detailFarmSuccess?.nomor_telepon
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Jenis Ayam</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? "-"
                      : detailFarmSuccess.jenis_ayam}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Jumlah Kandang</div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.jumlah_kandang
                      ? detailFarmSuccess?.jumlah_kandang
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">
                    Kapasitas Kandang (ekor)
                  </div>
                  <div className="col-md-8">
                    {detailFarmSuccess == null
                      ? ""
                      : detailFarmSuccess?.kapasitas_kandang
                      ? numberWithCommas(
                          String(
                            detailFarmSuccess?.kapasitas_kandang + " ekor",
                          ),
                        )
                      : "-"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
          <div className="d-flex flex-row-reverse">
            {/* <Button
              variant="contained"
              disableElevation
              className="btn-edit-farm"
              onClick={() => {
                history.push(`/farmmanagement/edit/${detailFarmSuccess.public_id}`);
              }}
            >
              Edit
            </Button> */}

            <Button
              variant="outlined"
              disableElevation
              className="btn-kembali-farm"
              onClick={(e) => history.goBack()}>
              Kembali
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(PageDetailFarm);
