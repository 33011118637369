import { actionSuccess, actionError, actionPending } from "../action_types";
import API from "../API";
import { toastError } from "../../../components/toast";
import moment from "moment";

// DEFINE ACTION
export const GRAPH_SUCCESS = `GRAPH_SUCCESS`;
export const GRAPH_PENDING = `GRAPH_PENDING`;
export const GRAPH_ERROR = `GRAPH_ERROR`;
export const UPDATE_FILTER_GRAPH = `UPDATE_FILTER_GRAPH`;
export const CHART_CUMMULATIVE_MORTALITY_SUCCESS = `CHART_CUMMULATIVE_MORTALITY_SUCCESS`;
export const CHART_CUMMULATIVE_MORTALITY_PENDING = `CHART_CUMMULATIVE_MORTALITY_PENDING`;
export const CHART_CUMMULATIVE_MORTALITY_ERROR = `CHART_CUMMULATIVE_MORTALITY_ERROR`;
export const CHART_CUMMULATIVE_MORTALITY_FILTER = `CHART_CUMMULATIVE_MORTALITY_FILTER`;
export const LIST_FARMDB_PENDING = `LIST_FARMDB_PENDING`;
export const LIST_FARMDB_SUCCESS = `LIST_FARMDB_SUCCESS`;
export const LIST_FARMDB_ERROR = `LIST_FARMDB_ERROR`;
export const UPDATE_FARM_FILTER = `UPDATE_FARM_FILTER`;
export const DASHBOARD_DATA_PENDING = `DASHBOARD_DATA_PENDING`;
export const DASHBOARD_DATA_SUCCESS = `DASHBOARD_DATA_SUCCESS`;
export const DASHBOARD_DATA_ERROR = `DASHBOARD_DATA_ERROR`;
export const TK_CHART_DATA_PENDING = `TK_CHART_DATA_PENDING`;
export const TK_CHART_DATA_SUCCESS = `TK_CHART_DATA_SUCCESS`;
export const TK_CHART_DATA_ERROR = `TK_CHART_DATA_ERROR`;
export const TK_CHART_FILTER_UPDATE = `TK_CHART_FILTER_UPDATE`;
export const BA_CHART_DATA_PENDING = `BA_CHART_DATA_PENDING`;
export const BA_CHART_DATA_SUCCESS = `BA_CHART_DATA_SUCCESS`;
export const BA_CHART_DATA_ERROR = `BA_CHART_DATA_ERROR`;
export const BA_CHART_FILTER_UPDATE = `BA_CHART_FILTER_UPDATE`;
export const SK_DATA_PENDING = `SK_DATA_PENDING`;
export const SK_DATA_SUCCESS = `SK_DATA_SUCCESS`;
export const SK_DATA_ERROR = `SK_DATA_ERROR`;
export const TF_DATA_PENDING = `TF_DATA_PENDING`;
export const TF_DATA_SUCCESS = `TF_DATA_SUCCESS`;
export const TF_DATA_ERROR = `TF_DATA_ERROR`;
export const SP_DATA_PENDING = `SP_DATA_PENDING`;
export const SP_DATA_SUCCESS = `SP_DATA_SUCCESS`;
export const SP_DATA_ERROR = `SP_DATA_ERROR`;

export const DASHBOARD_SYNC_PENDING = `DASHBOARD_SYNC_PENDING`;
export const DASHBOARD_SYNC_SUCCESS = `DASHBOARD_SYNC_SUCCESS`;
export const DASHBOARD_SYNC_ERROR = `DASHBOARD_SYNC_ERROR`;

// DEFINE ENDPOINT
const GRAPH_URL = `/v1/graph`;
const CUMMULATIVE_MORTALITY = `/v1/graph/cumulative_mortality`;
const LIST_FARM_URL = `/v1/master_data/farm`;
const DASHBOARD_DATA_URL = `/v1/master_data/dashboard/`;
const TK_CHART_DATA_URL = `/v1/master_data/dashboard/tingkat-kematian-chart`;
const BA_CHART_DATA_URL = `/v1/master_data/dashboard/berat-ayam-chart`;
const SK_DATA_URL = `/v1/master_data/dashboard/status-kandang`;
const TF_DATA_URL = `/v1/master_data/dashboard/top-five`;
const DASHBOARD_SYNC_URL = `/v1/master_data/dashboard/sync`;
const SP_DATA_URL = `/v1/master_data/dashboard/pakan`;

export const listGraph =
  (param, callbackSuccess, callbackFailed) => (dispatch) => {
    dispatch(actionPending(GRAPH_PENDING));
    API.post(GRAPH_URL, param)
      .then((res) => res.data)
      .then((d) => {
        dispatch(actionSuccess(GRAPH_SUCCESS, d.data));
        if (callbackSuccess) callbackSuccess(d.data);
      })
      .catch((error) => {
        dispatch(actionError(GRAPH_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const chartCummulativeMortality =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(CHART_CUMMULATIVE_MORTALITY_PENDING));
    API.get(CUMMULATIVE_MORTALITY, { params: param })
      .then((res) => {
        return res.data;
      })
      .then((d) => {
        dispatch(actionSuccess(CHART_CUMMULATIVE_MORTALITY_SUCCESS, d.data));
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(CHART_CUMMULATIVE_MORTALITY_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const filterGraph =
  (param = {}) =>
  (dispatch) => {
    const id_farm = param.id_farm ?? {};
    const id_cage = param.id_cage ?? {};
    const graph = [
      "feed_consumption",
      "cumulative_mortality",
      "water_consumption",
      "weekly_mortality",
      "body_weight",
      "difference",
      "uniformity",
      "weekly_gain"
    ];
    const start_date = param.start_date
      ? moment(param.start_date, 'DD/MM/YYYY')?.format("DD-MM-YYYY")
      : moment().subtract(1, 'months').format("DD-MM-YYYY");
    const end_date = param.end_date
      ? moment(param.end_date, 'DD/MM/YYYY')?.format("DD-MM-YYYY")
      : moment().format("DD-MM-YYYY");

    dispatch(
      actionSuccess(UPDATE_FILTER_GRAPH, {
        id_farm,
        id_cage,
        graph,
        start_date,
        end_date,
      }),
    );
  };

export const filterChartCM =
  (param = {}) =>
  (dispatch) => {
    const id_farm = param.id_farm ?? {};
    const id_cage = param.id_cage ?? {};
    // const graph = param.graph ?? {};
    const start_date = param.start_date
      ? moment(param.start_date)?.format("DD-MM-YYYY")
      : null;
    const end_date = param.end_date
      ? moment(param.end_date)?.format("DD-MM-YYYY")
      : null;
    dispatch(
      actionSuccess(CHART_CUMMULATIVE_MORTALITY_FILTER, {
        id_farm,
        id_cage,
        // graph,
        start_date,
        end_date,
      }),
    );
  };

export const topFiveData =
  (param, callbackSuccess, callbackFailed) => (dispatch) => {
    dispatch(actionPending(TF_DATA_PENDING));
    API.get(TF_DATA_URL, { params: param })
      .then((res) => res.data)
      .then((d) => {
        dispatch(actionSuccess(TF_DATA_SUCCESS, d.data));
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(TF_DATA_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const statusKandangData =
  (param, callbackSuccess, callbackFailed) => (dispatch) => {
    dispatch(actionPending(SK_DATA_PENDING));
    API.get(SK_DATA_URL, { params: param })
      .then((res) => res.data)
      .then((d) => {
        dispatch(actionSuccess(SK_DATA_SUCCESS, d.data));
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(BA_CHART_DATA_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const chartBeratAyam =
  (param, callbackSuccess, callbackFailed) => (dispatch) => {
    if (!param.bulan) param.bulan = new Date().getMonth() + 1;
    if (!param.tahun) param.tahun = new Date().getFullYear();
    dispatch(actionPending(BA_CHART_DATA_PENDING));
    API.post(BA_CHART_DATA_URL, param)
      .then((res) => res.data)
      .then((d) => {
        dispatch(actionSuccess(BA_CHART_DATA_SUCCESS, d.data));
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(BA_CHART_DATA_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const updateBAFilter =
  (
    param = {
      bulan: new Date().getMonth() + 1,
      tahun: new Date().getFullYear(),
    },
    callbackSuccess,
    callbackFailed,
  ) =>
  (dispatch) => {
    dispatch(actionSuccess(BA_CHART_FILTER_UPDATE, param));
  };

export const updateTKFilter =
  (
    param = new Date().getFullYear().toString(),
    callbackSuccess,
    callbackFailed,
  ) =>
  (dispatch) => {
    dispatch(actionSuccess(TK_CHART_FILTER_UPDATE, param));
  };

export const chartTingkatKematian =
  (param, callbackSuccess, callbackFailed) => (dispatch) => {
    dispatch(actionPending(TK_CHART_DATA_PENDING));
    if (!param.year) param.year = new Date().getFullYear().toString();
    API.post(TK_CHART_DATA_URL, param)
      .then((res) => res.data)
      .then((d) => {
        dispatch(actionSuccess(TK_CHART_DATA_SUCCESS, d.data));
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(TK_CHART_DATA_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const dashboardData =
  (farm, callbackSuccess, callbackFailed) => (dispatch) => {
    dispatch(actionPending(DASHBOARD_DATA_PENDING));
    API.get(DASHBOARD_DATA_URL, { params: { id: farm } })
      .then((res) => res.data)
      .then((d) => {
        dispatch(actionSuccess(DASHBOARD_DATA_SUCCESS, d.data));
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(DASHBOARD_DATA_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const listFarm =
  (param, callbackSuccess, callbackFailed) => (dispatch) => {
    dispatch(actionPending(LIST_FARMDB_PENDING));
    API.get(LIST_FARM_URL)
      .then((res) => res.data)
      .then((d) => {
        let data = [];
        if (d?.data.length > 0) {
          d.data.map((farm) => {
            data.push({ value: farm.id, label: farm.name });
          });
        }
        dispatch(actionSuccess(LIST_FARMDB_SUCCESS, data));
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(LIST_FARMDB_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const updateFarm = (param) => (dispatch) => {
  dispatch(actionSuccess(UPDATE_FARM_FILTER, param));
};

export const syncDashboard =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(DASHBOARD_SYNC_PENDING));
    API.get(DASHBOARD_SYNC_URL, { params: param })
      .then(() => {
        dispatch(actionSuccess(DASHBOARD_SYNC_SUCCESS));
      })
      .catch((error) => {
        dispatch(actionError(DASHBOARD_SYNC_ERROR, error));
        toastError(error);
      });
  };

export const sisaPakanData =
  (param, callbackSuccess, callbackFailed) => (dispatch) => {
    dispatch(actionPending(SP_DATA_PENDING));
    API.get(SP_DATA_URL, { params: param })
      .then((res) => res.data)
      .then((d) => {
        dispatch(actionSuccess(SP_DATA_SUCCESS, d.data));
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(SP_DATA_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };
