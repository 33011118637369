import React from "react";
import { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import SelectComponent from "../../components/commons/forms/select";

import { connect } from "react-redux";

import {
  listFarm,
  listKepKan,
  listGroup,
  listArea,
  filterFarm,
} from "../../store/actions/farm";
import {
  getListKepKan,
  getListGroup,
  getListArea,
  getFilters,
} from "../../store/reducers/farm";

const ModalFilterFarm = (props) => {
  const [filterFarmName, setFilterFarmName] = useState({});
  const [filterKepKan, setFilterKepKan] = useState({});
  const [filterGroupName, setFilterGroupName] = useState({});
  const [filterRegion, setFilterRegion] = useState({});

  const { listKepKanSuccess, listGroupSuccess, listAreaSuccess, filters } =
    props;

  useEffect(() => {
    props.listKepKan();
    props.listGroup();
    props.listArea();
  }, []);

  const submitForm = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterFarm({
        nama_farm: filterFarmName,
        surveyor: filterKepKan,
        group_id: filterGroupName,
        area_id: filterRegion,
      });
    }, 1000);
  };

  const resetButton = () => {
    props.setShow(false);
    props.filterFarm({
      nama_farm: null,
      surveyor: null,
      group_id: null,
      area_id: null,
    });
    setFilterFarmName("");
    setFilterKepKan("");
    setFilterGroupName("");
    setFilterRegion("");
  };

  const handleBatal = () => {
    setTimeout(() => {
      props.setShow(false);
      setFilterFarmName("");
      setFilterKepKan("");
      setFilterGroupName("");
      setFilterRegion("");
    }, 1000);
  };

  return (
    <Modal size={"md"} onHide={handleBatal} show={props.show} centered>
      <ModalHeader closeButton>Filter</ModalHeader>
      <ModalBody>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin difilter</div>
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={props?.optionNamaFarm}
            label={"Farm"}
            isAsync={true}
            asyncUrl={`/v1/farm`}
            customParseData={(item) => ({
              label: item?.nama_farm,
              value: item?.nama_farm,
            })}
            onChange={(e) => {
              setFilterFarmName(e);
            }}
            value={
              filterFarmName === null
                ? { value: "", label: "Semua Farm" }
                : filters.nama_farm.value && !filterFarmName.value
                ? {
                    value: filters.nama_farm.value,
                    label: filters.nama_farm.label,
                  }
                : filterFarmName.value
                ? { value: filterFarmName.value, label: filterFarmName.label }
                : { value: "", label: "Semua Farm" }
            }
          />
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={listGroupSuccess ? listGroupSuccess : {}}
            label={"Group"}
            isAsync={true}
            asyncUrl={`/v1/group`}
            customParseData={(item) => ({
              label: item?.group_name,
              value: item?.group_id,
            })}
            onChange={(e) => {
              setFilterGroupName(e);
            }}
            value={
              filterGroupName === null
                ? { value: "", label: "Semua Group" }
                : filters.group_id.value && !filterGroupName.value
                ? {
                    value: filters.group_id.value,
                    label: filters.group_id.label,
                  }
                : filterGroupName.value
                ? { value: filterGroupName.value, label: filterGroupName.label }
                : { value: "", label: "Semua Group" }
            }
          />
        </div>

        <div className="select-wrapper">
          <SelectComponent
            options={listAreaSuccess ?? {}}
            label={"Area"}
            isAsync={true}
            asyncUrl={`/v1/area`}
            customParseData={(item) => ({
              label: item?.area_name,
              value: [item?.area_id],
            })}
            onChange={(e) => {
              setFilterRegion(e);
            }}
            value={
              filterRegion === null
                ? { value: "", label: "Semua Area" }
                : filters.area_id.value && !filterRegion.value
                ? {
                    value: filters.area_id.value,
                    label: filters.area_id.label,
                  }
                : filterRegion.value
                ? { value: filterRegion.value, label: filterRegion.label }
                : { value: "", label: "Semua Area" }
            }
          />
        </div>

        <div className="select-wrapper">
          <SelectComponent
            options={listKepKanSuccess ? listKepKanSuccess : {}}
            label={"Surveyor"}
            isAsync={true}
            asyncUrl={`/v1/user/surveyor`}
            customParseData={(item) => ({
              label: item?.full_name,
              value: item?.user_id,
            })}
            onChange={(e) => {
              setFilterKepKan(e);
            }}
            value={
              filterKepKan === null
                ? { value: "", label: "Semua Surveyor" }
                : filters.surveyor.value && !filterKepKan.value
                ? {
                    value: filters.surveyor.value,
                    label: filters.surveyor.label,
                  }
                : filterKepKan.value
                ? { value: filterKepKan.value, label: filterKepKan.label }
                : { value: "", label: "Semua Surveyor" }
            }
          />
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: `space-between` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-2">
              <Button
                variant="outlined"
                disableElevation
                className="btn-btl-ubah-pass"
                style={{
                  right: "2px",
                }}
                onClick={resetButton}>
                Reset
              </Button>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={handleBatal}>
                  Batal
                </Button>

                <Button
                  variant="contained"
                  disableElevation
                  className="btn-simpan-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={(e) => submitForm()}>
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ listFarm, listKepKan, listGroup, listArea }) => {
  return {
    listKepKanSuccess: getListKepKan(listKepKan),
    listGroupSuccess: getListGroup(listGroup),
    listAreaSuccess: getListArea(listArea),
    filters: getFilters(listFarm),
  };
};

export default connect(mapStateToProps, {
  listKepKan,
  listFarm,
  listGroup,
  listArea,
  filterFarm,
})(ModalFilterFarm);
