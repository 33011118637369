import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import ListGroup from "react-bootstrap/ListGroup";
// import Button from "../../components/button"
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { detailUser, listUser } from "../../store/actions/user";
import {
  getDetailUser,
  getListUserError,
  getListUserPending,
} from "../../store/reducers/user";
import { Button } from "@mui/material";

const ModalDetailUser = (props) => {
  const handleEditShow = () => {
    props.setShow(false);
    props.edit(true);
  };

  useEffect(() => {}, [props.data]);

  return (
    <div>
      <Modal
        size={"md"}
        onHide={(e) => props.setShow(false)}
        show={props.show}
        centered>
        <ModalHeader closeButton>Detail User</ModalHeader>
        <ModalBody>
          <ListGroup className="rounded-3" style={{ width: "445px" }}>
            <ListGroup.Item
              style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-5" style={{ marginRight: "-3rem" }}>
                    <strong>Role</strong>
                  </div>
                  <div className="col-md-7" style={{ marginLeft: "2rem" }}>
                    <span>{props.data.job_position === "surveyor" ? "Kepala Kandang" : ""}</span>
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-5" style={{ marginRight: "-3rem" }}>
                    <strong>Nama</strong>
                  </div>
                  <div className="col-md-7" style={{ marginLeft: "2rem" }}>
                    <span>{props.data.full_name}</span>
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-5" style={{ marginRight: "-3rem" }}>
                    <strong>Nomor Telepon</strong>
                  </div>
                  <div className="col-md-6" style={{ marginLeft: "2rem" }}>
                    <span>{props.data.phone_number}</span>
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            {props.data.role == "management" ? (
              <ListGroup.Item
                style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-5" style={{ marginRight: "-3rem" }}>
                      <strong>Email</strong>
                    </div>
                    <div className="col-md-7" style={{ marginLeft: "2rem" }}>
                      <span style={{ wordBreak: "break-all" }}>
                        {props.data.email}
                      </span>
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            ) : (
              <></>
            )}

            <ListGroup.Item
              style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-5" style={{ marginRight: "-3rem" }}>
                    <strong>No.Pelanggan</strong>
                  </div>
                  <div className="col-md-7" style={{ marginLeft: "2rem" }}>
                    <span>{props.data.username}</span>
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup className="pt-5" style={{ width: "445px" }}>
            <ListGroup.Item
              style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-5" style={{ marginRight: "-3rem" }}>
                    <strong>Status</strong>
                  </div>
                  <div className="col-md-7" style={{ marginLeft: "2rem" }}>
                    <span>{props.data.status === "active" ? "Aktif" : "Non Aktif"}</span>
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            disableElevation
            className="btn-btl-modal-detail"
            sx={{
              paddingY: "10px",
            }}
            onClick={(e) => props.setShow(false)}>
            Batal
          </Button>

          <Button
            variant="contained"
            disableElevation
            className="btn-edit-modal-detail"
            sx={{
              paddingY: "10px",
            }}
            onClick={handleEditShow}>
            Edit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listUser }) {
  return {
    // detailUserSucess: getDetailUser(listUser),
    // detailUserPending: getListUserPending(listUser),
    // detailUserError: getListUserError(listUser),
  };
}

export default connect(mapStateToProps, { listUser, detailUser })(
  ModalDetailUser,
);
