import { actionSuccess, actionError, actionPending } from "../action_types";
import { instance as API } from "../API";
import { toastSuccess, toastError } from "../../../components/toast";
import axios from "axios";
import { history } from "../../../utils";
import { createBrowserHistory } from "history";

export const UPDATE_FARM_PAGE = `UPDATE_FARM_PAGE`;
export const LIST_FARM_PENDING = `LIST_FARM_PENDING`;
export const LIST_FARM_SUCCESS = `LIST_FARM_SUCCESS`;
export const LIST_FARM_ERROR = `LIST_FARM_ERROR`;
export const UPDATE_FILTER_FARM = `UPDATE_FILTER_FARM`;
export const UPDATE_SEARCH_FARM = `UPDATE_SEARCH_FARM`;
export const LIST_KEPKAN_PENDING = `LIST_KEPKAN_PENDING`;
export const LIST_KEPKAN_SUCCESS = `LIST_KEPKAN_SUCCESS`;
export const LIST_KEPKAN_ERROR = `LIST_KEPKAN_ERROR`;
export const LIST_AREA_PENDING = `LIST_AREA_PENDING`;
export const LIST_AREA_SUCCESS = `LIST_AREA_SUCCESS`;
export const LIST_AREA_ERROR = `LIST_AREA_ERROR`;
export const LIST_GROUP_PENDING = `LIST_GROUP_PENDING`;
export const LIST_GROUP_SUCCESS = `LIST_GROUP_SUCCESS`;
export const LIST_GROUP_ERROR = `LIST_GROUP_ERROR`;
export const TAMBAH_FARM_PENDING = `TAMBAH_FARM_PENDING`;
export const TAMBAH_FARM_SUCCESS = `TAMBAH_FARM_SUCCESS`;
export const TAMBAH_FARM_ERROR = `TAMBAH_FARM_ERROR`;
export const EDIT_FARM_PENDING = `EDIT_FARM_PENDING`;
export const EDIT_FARM_SUCCESS = `EDIT_FARM_SUCCESS`;
export const EDIT_FARM_ERROR = `EDIT_FARM_ERROR`;
export const DELETE_FARM_PENDING = `DELETE_FARM_PENDING`;
export const DELETE_FARM_SUCCESS = `DELETE_FARM_SUCCESS`;
export const DELETE_FARM_ERROR = `DELETE_FARM_ERROR`;
export const DETAIL_FARM_PENDING = `DETAIL_FARM_PENDING`;
export const DETAIL_FARM_SUCCESS = `DETAIL_FARM_SUCCESS`;
export const DETAIL_FARM_ERROR = `DETAIL_FARM_ERROR`;

const LIST_FARM_URL = `/v1/farm`;
const TAMBAH_FARM_URL = `/v2/master_data/farm/`;
const EDIT_FARM_URL = `/v1/farm`;
const DETAIL_FARM_URL = `/v1/farm/detail`;
const DELETE_FARM_URL = `/v1/farm/`;
const LIST_KEPKAN_URL = `/v1/user/surveyor`;
const LIST_AREA_URL = `/v1/area`;
const LIST_GROUP_URL = `/v1/group`;

export const listFarm =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_FARM_PENDING));
    API.get(LIST_FARM_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_FARM_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_FARM_PAGE, param.page));
        }
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_FARM_ERROR, error));
        toastError(error, "Gagal Mengambil Data Farm, ");
      });
  };

export const filterFarm =
  (param = {}) =>
  (dispatch) => {
    const nama_farm = param.nama_farm ? param.nama_farm : {};
    const group_id = param.group_id ? param.group_id : {};
    const surveyor = param.surveyor ? param.surveyor : {};
    const area_id = param.area_id ? param.area_id : {};
    dispatch(
      actionSuccess(UPDATE_FILTER_FARM, {
        nama_farm,
        group_id,
        surveyor,
        area_id,
      }),
    );
  };

export const listKepKan =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_KEPKAN_PENDING));
    API.get(LIST_KEPKAN_URL, { params: param })
      .then((res) => {
        const listKepKan = [];
        res.data.data.map((v) => {
          listKepKan.push({
            value: v.id,
            label: v.full_name,
          });
        });
        dispatch(actionSuccess(LIST_KEPKAN_SUCCESS, listKepKan));
      })
      .catch((error) => {
        dispatch(actionError(LIST_KEPKAN_ERROR, error));
      });
  };

export const listArea =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_AREA_PENDING));
    API.get(LIST_AREA_URL, { params: param })
      .then((res) => {
        const listArea = [];
        res.data.data.map((v) => {
          listArea.push({
            value: v.area_id,
            label: v.area_name,
          });
        });
        dispatch(actionSuccess(LIST_AREA_SUCCESS, listArea));
      })
      .catch((error) => {
        dispatch(actionError(LIST_AREA_ERROR, error));
      });
  };

export const listGroup =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_GROUP_PENDING));
    API.get(LIST_GROUP_URL, { params: param })
      .then((res) => {
        const listGroup = [];
        res.data.data.map((v) => {
          listGroup.push({
            value: v.group_id,
            label: v.group_name,
          });
        });
        dispatch(actionSuccess(LIST_GROUP_SUCCESS, listGroup));
      })
      .catch((error) => {
        dispatch(actionError(LIST_GROUP_ERROR, error));
      });
  };

export const searchFarm =
  (param = "") =>
  (dispatch) => {
    dispatch(actionSuccess(UPDATE_SEARCH_FARM, param));
  };

export const tambahFarm =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(TAMBAH_FARM_PENDING));
    API.post(TAMBAH_FARM_URL, param)
      .then((res) => {
        dispatch(actionSuccess(TAMBAH_FARM_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(TAMBAH_FARM_ERROR, error));
        toastError(error, "");
        callbackFailed();
      });
  };

export const editFarm =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(EDIT_FARM_PENDING));
    API.put(EDIT_FARM_URL, param)
      .then((res) => {
        dispatch(actionSuccess(EDIT_FARM_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(EDIT_FARM_ERROR, error));
        toastError(error?.response?.data?.message, "");
        callbackFailed();
      });
  };

// export function detailFarm(id = null) {
//   const param = {
//     id: id,
//   };
//   return (dispatch) => {
//     dispatch(actionPending(DETAIL_FARM_PENDING));
//     API.get(DETAIL_FARM_URL, { params: param })
//       .then((res) => {
//         dispatch(actionSuccess(DETAIL_FARM_SUCCESS, res.data.data));
//         return res.data.data
//       })
//       .catch((error) => {
//         dispatch(actionError(DETAIL_FARM_ERROR, error));
//         toastError(error, "");
//       });
//   };
// }

// export function deleteFarm(id = null) {
//   const param = {
//     id: id,
//   };
//   return (dispatch) => {
//     dispatch(actionPending(DELETE_FARM_PENDING));
//     API2.delete(DELETE_FARM_URL, { params: param })
//       .then((res) => {
//         dispatch(actionSuccess(DELETE_FARM_SUCCESS, res.data.data));
//         toastSuccess(res.data.message);
//       })
//       .catch((error) => {
//         dispatch(actionError(DELETE_FARM_ERROR, error));
//         toastError(error, "");
//       });
//   };
// }

export function detailFarm(farmId = null) {
  return (dispatch) => {
    API.get(`${DETAIL_FARM_URL}?id=${farmId}`, { params: {} })
      .then((res) => {
        dispatch(actionSuccess(DETAIL_FARM_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_FARM_ERROR, error));
        toastError(error?.response?.data?.message, "");
      });
  };
}

export function deleteFarm(farmId = null) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_FARM_PENDING));
    API.delete(`${DELETE_FARM_URL}?id=${farmId}`, { params: {} })
      .then((res) => {
        dispatch(actionSuccess(DELETE_FARM_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DELETE_FARM_ERROR, error));
        toastError(error?.response?.data?.message, "");
      });
  };
}
