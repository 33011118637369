import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PrivateRoute from "./components/commons/privateRoute";
import Dashboard from "./views/dashboard";
import UserManagement from "./views/usermanagement";
import FarmManagement from "./views/farmmanagement";
import FeedManagement from "./views/feedmanagement";
import Login from "./views/authentication";
import SidebarDetailFarm from "./views/farmmanagement/SidebarDetailFarm";
import PageEditFarm from "./views/farmmanagement/PageEditFarm";
import DetailKandang from "./views/farmmanagement/kandang/HeaderDetailKandang";
import EditKandang from "./views/farmmanagement/kandang/EditKandang";
import KandangManagement from "./views/kandangManagement";
import Report from "./views/report";
import DetailReport from "./views/report/DetailReport";
import { role } from "./utils";
import SidebarDetailDoc from "./views/report/docmanagement/SidebarDetailDoc";
import ReportDocMingguan from "./views/report/docmanagement/ReportDocMingguan";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#1C37BE",
    },
    secondary: {
      main: "#E5E5F0",
    },
    danger: {
      main: "#D10D0D",
    },
  },
  typography: {
    fontFamily: ["PlusJakartaSans-Regular", '"Open Sans"', "sans-serif"].join(
      ",",
    ),
  },
});

// simple forbidden page
const Forbidden = () => {
  return (
    <div>
      <h1>403 Error</h1>
      <h1>Forbidden</h1>
      <h4>Your role have not access for this feature</h4>
      <h4>Role kamu tidak punya akses untuk fitur ini</h4>
    </div>
  );
};

function App() {
  return (
    <>
      <ThemeProvider theme={materialTheme}>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path={"/access-denied"} component={Forbidden} />
          <PrivateRoute
            exact
            path="/dashboard"
            roles={[role.admin]}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/usermanagement"
            roles={[role.admin]}
            component={UserManagement}
          />
          <PrivateRoute
            exact
            path="/farmmanagement"
            roles={[role.admin]}
            component={FarmManagement}
          />
          <PrivateRoute
            exact
            path="/feedmanagement"
            roles={[role.admin]}
            component={FeedManagement}
          />
          <PrivateRoute
            path="/farmmanagement/:id"
            roles={[role.admin]}
            exact
            component={SidebarDetailFarm}
          />
          <PrivateRoute
            path="/farmmanagement/edit/:id"
            roles={[role.admin]}
            exact
            component={PageEditFarm}
          />
          <PrivateRoute
            path="/farmmanagement/:idFarm/kandang/:id"
            roles={[role.admin]}
            exact
            component={DetailKandang}
          />
          <PrivateRoute
            path="/farmmanagement/:idFarm/edit-kandang/:id"
            roles={[role.admin]}
            exact
            component={EditKandang}
          />
          <PrivateRoute
            path="/kandang-management"
            roles={[role.admin]}
            exact
            component={KandangManagement}
          />
          <PrivateRoute
            path="/report"
            roles={[role.admin]}
            exact
            component={Report}
          />
          <PrivateRoute
            path="/report/:id"
            roles={[role.admin]}
            exact
            component={DetailReport}
          />
          <PrivateRoute
            path="/docmingguan/:id"
            roles={[role.admin]}
            exact
            component={SidebarDetailDoc}
          />
        </Switch>
      </ThemeProvider>
    </>
  );
}

export default App;
