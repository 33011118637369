import React from "react";

const Input = ({ type, placeholder, disabled, value, onChange, style, className, label, leftIcon, rightIcon, error, defaultValue, rule, onKeyPress, onKeyDown, maxLength, minLength }) => {
  return (
    <div className={`input-wrapper ${error ? "error" : ""}`}>
      {label && <span style={{ fontWeight: "800" }}>{label}</span>}
      <div className="input-box-wrapper">
        {leftIcon ? leftIcon : ``}
        <input
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          rule={rule}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={className}
          value={value}
          disabled={disabled}
          style={{ ...style, fontSize: 14 }}
          onKeyPress={onKeyPress}
          minlength={minLength}
          maxLength={maxLength}
        />
        {rightIcon ? rightIcon : ``}
      </div>
      <span className="error-msg">{error}</span>
    </div>
  );
};

export default Input;
