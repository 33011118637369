import Chart from "chart.js/auto";
import React, { useState, useRef, useEffect } from "react";
import _uniqueId from "lodash/uniqueId";
// import Loader from "../commons/Loader";

const BarChart = ({
  height = 250,
  labels = ["Jan", "Feb", "March"],
  scaleTitleX = "",
  scaleTitleY = "",
  options = {
    yTickDisplay: true,
  },
  stepSize = 2,
  datasets = [
    {
      label: "Sales",
      data: [86, 67, 91],
      fill: false,
    },
  ],
  pending_chart,
}) => {
  const chartRef = useRef(null);

  const [id] = useState(_uniqueId("barchart-"));
  useEffect(() => {
    const chart = buildChart();

    chart.update();

    return () => {
      chart.destroy();
    };
  }, [labels, datasets, scaleTitleX, scaleTitleY, height]);

  const buildChart = () => {
    const myChartRef = chartRef.current.getContext("2d");
    myChartRef.clearRect(0, 0, chartRef.current.width, chartRef.current.height);

    const updatedDatasets = datasets.map((elem) => {
      const updatedElem = { ...elem, maxBarThickness: 40 };
      if (elem.fill) {
        let bgStartColor =
          "borderColor" in elem
            ? elem.borderColor.replace(/[\d\.]+\)$/g, ".6)")
            : "rgba(0, 166, 207, .6)";
        let bgStopColor =
          "borderColor" in elem
            ? elem.borderColor.replace(/[\d\.]+\)$/g, "0)")
            : "rgba(0, 166, 207, 0)";

        let bgGradient = myChartRef.createLinearGradient(0, 0, 0, height);
        bgGradient.addColorStop(0, bgStartColor);
        bgGradient.addColorStop(1, bgStopColor);

        updatedElem.backgroundColor = bgGradient;
      }

      if (updatedElem.borderDash) {
        updatedElem.borderDashOffset = 0;
      }

      return updatedElem;
    });

    const mergedData = updatedDatasets[0]?.data?.concat(updatedDatasets[1]?.data);
    const maxValue = Math.max(...mergedData);
    const minValue = Math.min(...mergedData?.filter(value => value > 0));
    const range = maxValue - minValue;

    // Determine the scale type based on the range
    const scaleType = (range > 1000 * minValue) ? 'logarithmic' : 'linear'; 

    const newChart = new Chart(myChartRef, {
      type: "bar",
      data: {
        //Bring in data
        labels: labels,
        datasets: updatedDatasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                return context?.length ? `${context[0]?.label} Weeks` : "";
              },
              label: function (context) {
                let label = context.dataset.label || "";
                let label2 = context?.dataset?.label2 || "";

                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label += context?.formattedValue?.indexOf('.') !== -1
                    ? parseFloat(context.formattedValue.replace(',', '')).toFixed(2)
                    : context.parsed.y
                }

                if (context.dataset.labelPrepend) {
                  label += context.dataset.labelPrepend;
                }

                if (label2) {
                  label2 += ": ";
                }
                if (context.raw.x !== null && context.raw.x !== undefined) {
                  label2 += context.raw.x;
                }
                return [label, label2];
              },
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              padding: 15,
            },
            title: {
              display: true,
              text: scaleTitleX,
              color: "black",
              font: {
                family: "PlusJakartaSans-Regular",
                size: 14,
                weight: "700",
              },
            },
          },
          y: {
            type: "linear",
            title: {
              display: true,
              text: scaleTitleY,
              color: "black",
              font: {
                family: "PlusJakartaSans-Regular",
                size: 14,
                weight: "700",
              },
            },
            grid: {
              display: true,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              padding: 15,
              // callback: function(value, index, values) {
              //   if (scaleType === 'logarithmic') {
              //     const logValue = Math.log10(value);
              //     if (Number.isInteger(logValue)) {
              //       return value;
              //     }
              //     return null;
              //   } else {
              //     return value;
              //   }
              // }
            }
          },
        },
      },
    });

    return newChart
  };

  return (
    <>
      <div style={{ height: height + "px", position: "relative" }}>
        {/* {pending_chart && <Loader loading={pending_chart} />} */}
        <canvas id={id} ref={chartRef} />
      </div>
    </>
  );
};

export default BarChart;
