import React from "react";
import { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import SelectComponent from "../../components/commons/forms/select";

import { connect } from "react-redux";

import { listKandang, filterKandang } from "../../store/actions/kandang";
import { listGroup, listKepKan } from "../../store/actions/farm";
import { getListGroup, getListKepKan } from "../../store/reducers/farm";
import { 
  getPageData, 
  getFilters, 
  getSearchText 
} from "../../store/reducers/kandang";

const ModalFilterKandang = (props) => {
  const [filterFarmName, setFilterFarmName] = useState({});
  const [filterKepKan, setFilterKepKan] = useState({});
  const [filterGroupName, setFilterGroupName] = useState({});
  const [filterStatus, setFilterStatus] = useState({});

  const { 
    listSurveyorSuccess, 
    listGroupSuccess, 
    filters, 
  } = props;

  const statusOptions = [
    { value: "aktif", label: "Aktif" },
    { value: "tidakaktif", label: "Tidak Aktif" },
    { value: "panen", label: "Panen" },
  ];

  useEffect(() => {
    props.listKepKan();
    props.listGroup();
  }, []);

  const submitForm = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterKandang({ 
        nama_farm: filterFarmName, 
        surveyor: filterKepKan, 
        group_id: filterGroupName, 
        status: filterStatus
      });
    }, 1000);
  };

  const resetButton = () => {
    props.setShow(false);
    props.filterKandang({ 
      nama_farm: null, 
      surveyor: null, 
      group_id: null, 
      status: null 
    });
    setFilterFarmName("");
    setFilterKepKan("");
    setFilterGroupName("");
    setFilterStatus("");
  };

  const handleBatal = () => {
    setTimeout(() => {
      props.setShow(false);
      setFilterFarmName("");
      setFilterKepKan("");
      setFilterGroupName("");
      setFilterStatus("");
    }, 1000);
  };

  return (
    <Modal size={"md"} onHide={handleBatal} show={props.show} centered>
      <ModalHeader closeButton>Filter</ModalHeader>
      <ModalBody>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin difilter</div>
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={props?.optionNamaFarm}
            label={"Farm"}
            isAsync={true}
            asyncUrl={`/v1/farm`}
            customParseData={(item) => ({
              label: item?.nama_farm,
              value: item?.nama_farm
            })}
            onChange={(e) => {
              setFilterFarmName(e);
            }}
            value={
              filterFarmName === null
                ? { value: "", label: "Semua Farm" }
                : filters.nama_farm.value && !filterFarmName.value
                ? { value: filters.nama_farm.value, label: filters.nama_farm.label }
                : filterFarmName.value
                ? { value: filterFarmName.value, label: filterFarmName.label }
                : { value: "", label: "Semua Farm" }
            }
          />
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={listGroupSuccess ? listGroupSuccess : {}}
            label={"Group"}
            isAsync={true}
            asyncUrl={`/v1/group`}
            customParseData={(item) => ({
              label: item?.group_name,
              value: item?.group_id
            })}
            onChange={(e) => {
              setFilterGroupName(e);
            }}
            value={
              filterGroupName === null
                ? { value: "", label: "Semua Group" }
                : filters.group_id.value && !filterGroupName.value
                ? { value: filters.group_id.value, label: filters.group_id.label }
                : filterGroupName.value
                ? { value: filterGroupName.value, label: filterGroupName.label }
                : { value: "", label: "Semua Group" }
            }
          />
        </div>

        <div className="select-wrapper">
        <SelectComponent
            options={statusOptions}
            label={"Status Kandang"}
            onChange={(e) => {
              setFilterStatus(e);
            }}
            value={
              filterStatus === null
                ? { value: "", label: "Semua status kandang" }
                : filters.status?.value && !filterStatus?.value
                ? { value: filters?.status?.value, label: filters?.status?.label }
                : filterStatus?.value
                ? { value: filterStatus?.value, label: filterStatus?.label }
                : { value: "", label: "Semua status kandang" }
            }
          />
        </div>

        <div className="select-wrapper">
          <SelectComponent
            options={listSurveyorSuccess ? listSurveyorSuccess : {}}
            label={"Surveyor"}
            isAsync={true}
            asyncUrl={`/v1/user/surveyor`}
            customParseData={(item) => ({
              label: item?.full_name,
              value: item?.user_id
            })}
            onChange={(e) => {
              setFilterKepKan(e);
            }}
            value={
              filterKepKan === null
                ? { value: "", label: "Semua Surveyor" }
                : filters.surveyor.value && !filterKepKan.value
                ? { value: filters.surveyor.value, label: filters.surveyor.label }
                : filterKepKan.value
                ? { value: filterKepKan.value, label: filterKepKan.label }
                : { value: "", label: "Semua Surveyor" }
            }
          />
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: `space-between` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-2">
              <Button
                variant="outlined"
                disableElevation
                className="btn-btl-ubah-pass"
                style={{
                  right: "2px",
                }}
                onClick={resetButton}
              >
                Reset
              </Button>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={handleBatal}
                >
                  Batal
                </Button>

                <Button
                  variant="contained"
                  disableElevation
                  className="btn-simpan-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={(e) => submitForm()}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ listKandang, listKepKan, listGroup }) => {
  return {
    listSurveyorSuccess: getListKepKan(listKepKan),
    listGroupSuccess: getListGroup(listGroup),
    pageData: getPageData(listKandang),
    filters: getFilters(listKandang),
    searchText: getSearchText(listKandang),
  };
};

export default connect(mapStateToProps, { listKepKan, listKandang, listGroup, filterKandang })(ModalFilterKandang);
