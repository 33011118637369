import { actionSuccess, actionError, actionPending } from "../action_types";
import { download, instance as API } from "../API";
import { toastSuccess, toastError } from "../../../components/toast";
import moment from "moment";

export const UPDATE_DOC_PAGE = `UPDATE_DOC_PAGE`;
export const UPDATE_FILTER_DOC = `UPDATE_FILTER_DOC`;
export const UPDATE_SEARCH_DOC = `UPDATE_SEARCH_DOC`;
export const LIST_DOC_PENDING = `LIST_DOC_PENDING`;
export const LIST_DOC_SUCCESS = `LIST_DOC_SUCCESS`;
export const LIST_DOC_ERROR = `LIST_DOC_ERROR`;
export const TAMBAH_DOC_PENDING = `TAMBAH_DOC_PENDING`;
export const TAMBAH_DOC_SUCCESS = `TAMBAH_DOC_SUCCESS`;
export const TAMBAH_DOC_ERROR = `TAMBAH_DOC_ERROR`;
export const EDIT_DOC_PENDING = `EDIT_DOC_PENDING`;
export const EDIT_DOC_SUCCESS = `EDIT_DOC_SUCCESS`;
export const EDIT_DOC_ERROR = `EDIT_DOC_ERROR`;
export const DELETE_DOC_PENDING = `DELETE_DOC_PENDING`;
export const DELETE_DOC_SUCCESS = `DELETE_DOC_SUCCESS`;
export const DELETE_DOC_ERROR = `DELETE_DOC_ERROR`;
export const DETAIL_DOC_PENDING = `DETAIL_DOC_PENDING`;
export const DETAIL_DOC_SUCCESS = `DETAIL_DOC_SUCCESS`;
export const DETAIL_DOC_ERROR = `DETAIL_DOC_ERROR`;
export const DOWNLOAD_DOC_PENDING = `DOWNLOAD_DOC_PENDING`;
export const DOWNLOAD_DOC_SUCCESS = `DOWNLOAD_DOC_SUCCESS`;
export const DOWNLOAD_DOC_ERROR = `DOWNLOAD_DOC_ERROR`;
export const DETAIL_DOC_MINGGUAN_PENDING = `DETAIL_DOC_MINGGUAN_PENDING`;
export const DETAIL_DOC_MINGGUAN_SUCCESS = `DETAIL_DOC_MINGGUAN_SUCCESS`;
export const DETAIL_DOC_MINGGUAN_ERROR = `DETAIL_DOC_MINGGUAN_ERROR`;
export const UPDATE_DOC_MINGGUAN_PAGE = `UPDATE_DOC_MINGGUAN_PAGE`;
export const UPDATE_FILTER_MINGGUAN_DOC = `UPDATE_FILTER_MINGGUAN_DOC`;
export const UPDATE_SEARCH_MINGGUAN_DOC = `UPDATE_SEARCH_MINGGUAN_DOC`;
export const LIST_DOC_MINGGUAN_PENDING = `LIST_DOC_MINGGUAN_PENDING`;
export const LIST_DOC_MINGGUAN_SUCCESS = `LIST_DOC_MINGGUAN_SUCCESS`;
export const LIST_DOC_MINGGUAN_ERROR = `LIST_DOC_MINGGUAN_ERROR`;
export const DOWNLOAD_DOC_MINGGUAN_PENDING = `DOWNLOAD_DOC_MINGGUAN_PENDING`;
export const DOWNLOAD_DOC_MINGGUAN_SUCCESS = `DOWNLOAD_DOC_MINGGUAN_SUCCESS`;
export const DOWNLOAD_DOC_MINGGUAN_ERROR = `DOWNLOAD_DOC_MINGGUAN_ERROR`;

const DOC_URL = `/v1/doc_survey`;
const DOC_DETAIL_URL = `/v1/doc_survey/detail`;
const DOWNLOAD_DOC_URL = `/v1/doc_survey/download`;
const DOC_DETAIL_MINGGUAN_URL = `v1/doc_report/detail`;
const DOC_REPORT_URL = `/v1/doc_report`;
const DOWNLOAD_DOC_MINGGUAN_URL = `/v1/doc_report/download`;

export const listDoc =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_DOC_PENDING));
    API.get(DOC_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_DOC_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_DOC_PAGE, param.page));
        }
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_DOC_ERROR, error));
        toastError(error);
      });
  };

export const listDocMingguan =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_DOC_MINGGUAN_PENDING));
    API.get(DOC_REPORT_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_DOC_MINGGUAN_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_DOC_MINGGUAN_PAGE, param.page));
        }
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_DOC_MINGGUAN_ERROR, error));
        toastError(error);
      });
  };

export const tambahDoc =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(TAMBAH_DOC_PENDING));
    API.post(DOC_URL, param)
      .then((res) => {
        dispatch(actionSuccess(TAMBAH_DOC_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(TAMBAH_DOC_ERROR, error));
        toastError(error);
        callbackFailed();
      });
  };

export const editDoc =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(EDIT_DOC_PENDING));
    API.put(DOC_URL, param)
      .then((res) => {
        dispatch(actionSuccess(EDIT_DOC_SUCCESS));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(EDIT_DOC_ERROR, error));
        toastError(error);
        callbackFailed();
      });
  };

export const deleteDoc =
  (id = null, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    const param = { id: id };
    dispatch(actionPending(DELETE_DOC_PENDING));
    API.delete(DOC_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(DELETE_DOC_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(DELETE_DOC_ERROR, error));
        toastError(error);
        callbackFailed();
      });
  };

export const filterDoc =
  (param = {}) =>
  (dispatch) => {
    const id_farm = param.id_farm ? param.id_farm : {};
    const id_cage = param.id_cage ? param.id_cage : {};
    const surveyor = param.surveyor ? param.surveyor : {};
    const tanggal_monitoring = param.tanggal_monitoring
      ? param.tanggal_monitoring
      : null;
    dispatch(
      actionSuccess(UPDATE_FILTER_DOC, {
        id_cage,
        id_farm,
        surveyor,
        tanggal_monitoring,
      }),
    );
  };

export const searchDoc =
  (param = "") =>
  (dispatch) => {
    dispatch(actionSuccess(UPDATE_SEARCH_DOC, param));
  };

export function detailDoc(id = null) {
  return (dispatch) => {
    API.get(`${DOC_DETAIL_URL}?id=${id}`, { params: {} })
      .then((res) => {
        dispatch(actionSuccess(DETAIL_DOC_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_DOC_ERROR, error));
        toastError(error?.response?.data?.message, "");
      });
  };
}

export const downloadReportDoc =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(DOWNLOAD_DOC_PENDING));
    download
      .get(DOWNLOAD_DOC_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(DOWNLOAD_DOC_SUCCESS));
        const href = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `DOC Survey.xlsx`);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        dispatch(actionError(DOWNLOAD_DOC_ERROR, error));
        toastError("Gagal mengunduh report");
      });
  };

export function detailMingguanDoc(id = null) {
  return (dispatch) => {
    API.get(`${DOC_DETAIL_MINGGUAN_URL}?id=${id}`, { params: {} })
      .then((res) => {
        dispatch(actionSuccess(DETAIL_DOC_MINGGUAN_SUCCESS, res.data));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_DOC_MINGGUAN_ERROR, error));
        toastError(error?.response?.data?.message, "");
      });
  };
}

export const filterDocMingguan =
  (param = {}) =>
  (dispatch) => {
    // const id_farm = param.id_farm ? param.id_farm : {};
    // const id_cage = param.id_cage ? param.id_cage : {};
    const id_doc_survey = param.id_doc_survey ? param.id_doc_survey : {};
    const minggu = param.minggu ? param.minggu : {};
    dispatch(
      actionSuccess(UPDATE_FILTER_MINGGUAN_DOC, {
        // id_farm,
        // id_cage,
        id_doc_survey,
        minggu,
      }),
    );
  };

export const downloadReportDocMingguan =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(DOWNLOAD_DOC_MINGGUAN_PENDING));
    download
      .get(DOWNLOAD_DOC_MINGGUAN_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(DOWNLOAD_DOC_MINGGUAN_SUCCESS));
        const href = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `DOC Report Mingguan.xlsx`);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        dispatch(actionError(DOWNLOAD_DOC_MINGGUAN_ERROR, error));
        toastError("Gagal mengunduh report");
      });
  };
