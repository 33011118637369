import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import SelectComponent from "../../components/commons/forms/select";
import DateRangePicker from "../../components/commons/forms/datepicker/DateRangePicker";

import { listFarm } from "../../store/actions/farm";
import { getListFarm } from "../../store/reducers/farm";
import { listKandang } from "../../store/actions/kandang";
import { getListKandang } from "../../store/reducers/kandang";
import { listReport, listPeriod, filterReport } from "../../store/actions/report";
import { getFilters, getPageData, getListPeriod } from "../../store/reducers/report";
import { defaultDateFormat, formatDate } from "../../utils";

export const ModalFilterReport = (props) => {
  const [listFarm, setListFarm] = useState(null);
  const [listKandang, setListKandang] = useState(null);
  const [listPeriod, setListPeriod] = useState(null);
  const [farm, setFarm] = useState({});
  const [kandang, setKandang] = useState({});
  const [period, setPeriod] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterTanggal, setFilterTanggal] = useState("");
  const [startDatePulletIn, setStartDatePulletIn] = useState(null);
  const [endDatePulletIn, setEndDatePulletIn] = useState(null);
  const [filterPulletIn, setFilterPulletIn] = useState("");
  const [filterIp, setFilterIp] = useState({});
  const [errorTanggal, setErrorTanggal] = useState("");
  const [errorPulletIn, setErrorPulletIn] = useState("");
  const { 
    filters, 
    pageData, 
    listFarmSuccess, 
    listKandangSuccess, 
    listPeriodSuccess 
  } = props;
  

  const indikatorOptions = [
    { label: "Merah", value: "Merah" },
    { label: "Kuning", value: "Kuning" },
    { label: "Hijau", value: "Hijau" },
  ];

  useEffect(() => {
    props.listFarm();
  }, []);

  useEffect(() => {
    if (listFarmSuccess) {
      setListFarm(
        listFarmSuccess?.map((item) => ({
          label: item?.nama_farm,
          value: item?.id
        }))
      )
    }
  }, [listFarmSuccess]);

  useEffect(() => {
    if (farm && farm?.value) {
      props.listKandang({
        nama_farm: farm?.label
      });
    }
  }, [farm]);

  useEffect(() => {
    if (listKandangSuccess) {
      setListKandang(
        listKandangSuccess?.map((item) => ({
          label: item?.barcode,
          value: item?.id
        }))
      )
    }
  }, [listKandangSuccess]);

  useEffect(() => {
    props.listPeriod({
      barcode: kandang?.value ? kandang?.label : null
    });
  }, [kandang]);

  useEffect(() => {
    if (listPeriodSuccess) setListPeriod(listPeriodSuccess);
  }, [listPeriodSuccess]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setFilterTanggal(formatDate(start).concat(" - ", formatDate(end)));
      setErrorTanggal("");
    } else if (start) {
      setFilterTanggal(formatDate(start).concat(" - "));
    } else {
      setFilterTanggal("");
      setErrorTanggal("");
    }
  };

  const handleDatePulletInChange = (dates) => {
    const [start, end] = dates;
    setStartDatePulletIn(start);
    setEndDatePulletIn(end);
    if (start && end) {
      setFilterPulletIn(formatDate(start).concat(" - ", formatDate(end)));
      setErrorPulletIn("");
    } else if (start) {
      setFilterPulletIn(formatDate(start).concat(" - "));
    } else {
      setFilterPulletIn("");
      setErrorPulletIn("");
    }
  };


  const submitForm = () => {
    if (startDate && !endDate) {
      setErrorTanggal("Tanggal akhir harus diisi");
      return;
    }
    if (startDatePulletIn && !endDatePulletIn) {
      setErrorPulletIn("Tanggal akhir pullet in harus diisi");
      return;
    }
    setTimeout(() => {
      props.filterReport({ 
        farm: farm,
        kandang: kandang,
        period: period,
        tanggal_mulai: startDate, 
        tanggal_akhir: endDate, 
        tanggal_mulai_pullet_in: startDatePulletIn, 
        tanggal_akhir_pullet_in: endDatePulletIn, 
        ip: filterIp 
      });
      props.setShow(false);
      setErrorTanggal("");
      setErrorPulletIn("");
    }, 100);
  };

  const handleClose = () => {
    props.setShow(false);
    setPeriod({});
    setFarm({});
    setKandang({});
    setFilterTanggal("");
    setFilterPulletIn("");
    setFilterIp({});
    setStartDate(null);
    setEndDate(null);
    setErrorTanggal("");
    setStartDatePulletIn(null);
    setEndDatePulletIn(null);
    setErrorPulletIn("");
  };

  const handleReset = () => {
    props.filterReport({ 
      farm: {},
      kandang: {},
      period: {},
      tanggal_mulai: null, 
      tanggal_akhir: null, 
      tanggal_mulai_pullet_in: null,
      tanggal_akhir_pullet_in: null, 
      ip: {}
    });
    handleClose();
  };


  return (
    <Modal size={"md"} onHide={handleClose} show={props.show} centered>
      <ModalHeader closeButton>Filter</ModalHeader>
      <ModalBody>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin difilter</div>
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={listFarm}
            label={"Farm"}
            isAsync={true}
            asyncUrl={`/v1/farm`}
            customParseData={(item) => ({
              label: item?.nama_farm,
              value: item?.id
            })}
            onChange={(e) => {
              setFarm(e);
            }}
            value={
              farm === null
                ? { value: "", label: "Semua Farm" }
                : filters?.farm?.value && !farm?.value
                ? { value: filters?.farm?.value, label: filters?.farm?.label }
                : farm?.value
                ? { value: farm?.value, label: farm?.label }
                : { value: "", label: "Semua Farm" }
            }
          />
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={listKandang}
            label={"Kandang"}
            isAsync={true}
            asyncUrl={`/v1/cage`}
            customParseData={(item) => ({
              label: item?.barcode,
              value: item?.id
            })}
            onChange={(e) => {
              setKandang(e);
            }}
            value={
              kandang === null
                ? { value: "", label: "Semua Kandang" }
                : filters?.kandang?.value && !kandang?.value
                ? { value: filters?.kandang?.value, label: filters?.kandang?.label }
                : kandang?.value
                ? { value: kandang?.value, label: kandang?.label }
                : { value: "", label: "Semua Kandang" }
            }
          />
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={listPeriod}
            label={"Periode"}
            isAsync={true}
            asyncUrl={`/v1/period`}
            customParseData={(item) => ({
              value: item.id,
              label: `Periode ${
                moment(item?.tanggal_mulai)?.format("DD-MM-YYYY")
              } ${item?.tanggal_selesai 
                ? `- ${moment(item?.tanggal_selesai)?.format("DD-MM-YYYY")}` 
                : ''
              }`
            })}
            onChange={(e) => {
              setPeriod(e);
            }}
            value={
              period === null
                ? { value: "", label: "Semua Periode" }
                : filters?.period?.value && !period?.value
                ? { value: filters?.period?.value, label: filters?.period?.label }
                : period?.value
                ? { value: period?.value, label: period?.label }
                : { value: "", label: "Semua Periode" }
            }
          />
        </div>
        <div className="select-wrapper">
          <DateRangePicker
            label={"Tanggal Report"}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            placeholder={"Masukkan Tanggal Report"}
            error={errorTanggal}
            value={filterTanggal}
          />
        </div>
        <div className="select-wrapper">
          <DateRangePicker
            label={"Tanggal Pullet In"}
            onChange={handleDatePulletInChange}
            startDate={startDatePulletIn}
            endDate={endDatePulletIn}
            selectsRange={true}
            placeholder={"Masukkan Tanggal Pullet In"}
            error={errorPulletIn}
            value={filterPulletIn}
          />
        </div>
        {/* <div className="select-wrapper">
          <SelectComponent
            options={indikatorOptions}
            label={"IP Indikator"}
            onChange={(e) => {
              setFilterIp(e);
            }}
            value={
              filterIp === null
                ? { value: "", label: "Semua IP Indikator" }
                : filters?.ip?.value && !filterIp?.value
                ? { value: filters?.ip?.value, label: filters?.ip?.label }
                : filterIp?.value
                ? { value: filterIp?.value, label: filterIp?.label }
                : { value: "", label: "Semua IP Indikator" }
            }
          />
        </div> */}
      </ModalBody>
      <ModalFooter style={{ justifyContent: `space-between` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-2">
              <Button
                variant="outlined"
                disableElevation
                className="btn-btl-ubah-pass"
                style={{
                  right: "2px",
                }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={handleClose}
                >
                  Batal
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  className="btn-simpan-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={() => submitForm()}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ listFarm, listKandang, listPeriod, listReport }) => {
  return {
    filters: getFilters(listReport),
    pageData: getPageData(listReport),
    listFarmSuccess: getListFarm(listFarm),
    listKandangSuccess: getListKandang(listKandang),
    listPeriodSuccess: getListPeriod(listPeriod),
  };
};

const mapDispatchToProps = { listFarm, listKandang, listPeriod, listReport, filterReport };

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterReport);
