import React, { useEffect, useState } from "react";
import { ListGroup, Container } from "react-bootstrap";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import SelectComponent from "../../components/commons/forms/select";
import Input from "../../components/commons/forms/authentication/input";
import TextArea from "../../components/commons/forms/textarea";

import { 
  listFarm, 
  listKepKan,
  listGroup,
  listArea, 
  editFarm, 
  detailFarm 
} from "../../store/actions/farm";
import { 
  getListKepKan, 
  getListGroup,
  getListArea,
  getFilters, 
  getPageData, 
  getSearchText, 
  getDetailFarmSuccess, 
  getDetailFarmPending, 
  getDetailFarmError 
} from "../../store/reducers/farm";

function PageEditFarm(props) {
  const history = useHistory();
  const exceptThisSymbols = ["e", "E", "+"];
  const [idFarm, setIdFarm] = useState("");
  const [groupName, setGroupName] = useState("");
  const [surveyor, setSurveyor] = useState("");
  const [areaName, setAreaName] = useState("");
  const [groupNameLabel, setGroupNameLabel] = useState("");
  const [surveyorLabel, setSurveyorLabel] = useState("");
  const [areaNameLabel, setAreaNameLabel] = useState("");
  const [jenisAyam, setJenisAyam] = useState();
  const [jumlahKandang, setJumlahKandang] = useState();
  const [kapasitasKandang, setKapasitasKandang] = useState();
  const [name, setName] = useState("");
  const [alamat, setAlamat] = useState("");
  const [handphone, setHandphone] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [errorIdFarm, setErrorIdFarm] = useState("");
  const [errorGroupName, setErrorGroupName] = useState("");
  const [errorSurveyor, setErrorSurveyor] = useState("");
  const [errorAreaName, setErrorAreaName] = useState("");
  const [errorJenisAyam, setErrorJenisAyam] = useState();
  const [errorJumlahKandang, setErrorJumlahKandang] = useState();
  const [errorKapasitasKandang, setErrorKapasitasKandang] = useState();
  const [errorName, setErrorName] = useState("");
  const [errorAlamat, setErrorAlamat] = useState("");
  const [errorHandphone, setErrorHandphone] = useState("");
  const [errorLatitude, setErrorLatitude] = useState("");
  const [errorLongitude, setErrorLongitude] = useState("");
  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const { 
    pageData, 
    filters, 
    searchText, 
    listKepKanSuccess, 
    listAreaSuccess,
    listGroupSuccess,
    detailFarmSuccess, 
    detailFarmPending, 
    detailFarmError, 
  } = props;

  useEffect(() => {
    props.listKepKan();
    props.listGroup();
    props.listArea();
  }, []);

  useEffect(() => {
    props.detailFarm(props.match.params.id);
  }, []);

  useEffect(() => {
    if (detailFarmSuccess) {
      setDefaultState(detailFarmSuccess);
    }
  }, [detailFarmSuccess]);
  
  const setDefaultState = (data) => {
    setIdFarm(data.public_id);
    setGroupName(data.group_id);
    setGroupNameLabel(data.nama_group);
    setSurveyor(data.surveyor);
    setSurveyorLabel(data.surveyor_fullname);
    setAreaName(data.area_id);
    setAreaNameLabel(data.nama_area);
    setJenisAyam(data.jenis_ayam);
    setJumlahKandang(data.jumlah_kandang);
    setKapasitasKandang(data.kapasitas_kandang);
    setName(data.nama_farm);
    setAlamat(data.alamat);
    setHandphone(data.nomor_telepon);
    setLatitude(data.latitude);
    setLongitude(data.longitude);
    
    setErrorIdFarm("");
    setErrorGroupName("");
    setErrorSurveyor("");
    setErrorAreaName("");
    setErrorJenisAyam("");
    setErrorJumlahKandang("");
    setErrorKapasitasKandang("");
    setErrorName("");
    setErrorAlamat("");
    setErrorHandphone("");
    setErrorLatitude("");
    setErrorLongitude("");
  };

  const handleInput = (e, set, error, customRule = false, setLabel = false) => {
    let value;

    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
    }

    const rule = customRule ? customRule : e?.target?.getAttribute(`rule`);
    if (!value) {
      error(`${rule} wajib diisi`);
    } else if (
      e?.target?.getAttribute &&
      e?.target?.getAttribute(`rule`) === 'Nomor telepon' &&
      value?.length < 11
    ) {
      error(`${rule} wajib diisi`);
    } else {
      error(``);
    }
    set(value);
    if (setLabel) setLabel(e.label);
  };

  const handleError = (data) => {
    data.public_id ? setErrorIdFarm("") : setErrorIdFarm("ID Form wajib diisi");
    data.group_id ? setErrorGroupName("") : setErrorGroupName("Nama Group wajib diisi");
    data.surveyor ? setErrorSurveyor("") : setErrorSurveyor("Surveyor wajib diisi");
    data.area_id ? setErrorAreaName("") : setErrorAreaName("Area wajib diisi");
    data.nama_farm ? setErrorName("") : setErrorName("Nama Farm wajib diisi");
    data.alamat ? setErrorAlamat("") : setErrorAlamat("Alamat wajib diisi");
    data.nomor_telepon ? setErrorHandphone("") : setErrorHandphone("No. Telepon wajib diisi");
    data.latitude ? setErrorLatitude("") : setErrorLatitude("Latitude wajib diisi");
    data.longitude ? setErrorLongitude("") : setErrorLongitude("Longitude wajib diisi");
  };

  const handleButtonBatal = (e) => {
    history.goBack()
  };

  const submitForm = () => {
    setDisableBtnSubmit(true);
    const data = {
      "public_id": idFarm,
      "public_id_old": detailFarmSuccess?.public_id,
      "surveyor": surveyor,
      "group_id": groupName,
      "area_id": areaName,
      "nama_farm": name,
      "latitude": latitude,
      "longitude": longitude,
      "alamat": alamat,
      "nomor_telepon": handphone
    };

    handleError(data);

    for (var key in data) {
      if (data[key] == "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    props.editFarm(data, successEdit, failedEdit);
  };

  const successEdit = () => {
    setTimeout(() => {
      props.listFarm({
        length: pageData.lengthItem,
        page: pageData.page,
        search: searchText === "" ? null : searchText,
        nama_farm: filters.nama_farm.value,
        surveyor: filters.surveyor.value,
        group_id: filters.group_id.value,
        area_id: filters.area_id.value,
      });
      setDisableBtnSubmit(false);
      history.push({ pathname: "/farmmanagement/" });
    }, 1000);
  };

  const failedEdit = () => {
    setDisableBtnSubmit(false);
  };


  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <ArrowBack 
            sx={{ cursor: "pointer" }} 
            onClick={handleButtonBatal} 
          />
          <span
            className="text-extra-bold"
            style={{
              fontSize: "24px",
              fontFamily: "PlusJakartaSans-Bold",
              marginLeft: "26px",
            }}
          >
            Edit Kandang
          </span>
        </div>
      </div>
      <div className="my-5">
        <ListGroup className="cek" style={{ borderRadius: "12px" }}>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  ID Farm
                </div>
                <div className="col-md-8">
                  <Input 
                    placeholder={"Masukkan ID farm"} 
                    onChange={(e) => handleInput(e, setIdFarm, setErrorIdFarm)} 
                    value={idFarm || ""} 
                    rule={"Id Farm"} 
                    error={errorIdFarm}
                  />
                </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Nama Farm
                </div>
                <div className="col-md-8">
                  <Input 
                    placeholder={"Masukkan nama farm"} 
                    onChange={(e) => handleInput(e, setName, setErrorName)} 
                    value={name || ""} 
                    rule={"Nama Farm"} 
                    error={errorName}
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Nama Group
                </div>
                <div className="col-md-8">
                  <SelectComponent
                    options={listGroupSuccess ?? {}}
                    value={groupName 
                      ? { value: groupName, label: groupNameLabel } 
                      : { value: "", label: "Masukkan nama group"
                    }}
                    asyncUrl={`/v1/group`}
                    customParseData={(item) => ({
                      label: item?.group_name,
                      value: item?.group_id
                    })}
                    onChange={(e) => handleInput(e, setGroupName, setErrorGroupName, "Nama Group", setGroupNameLabel)}
                    isError={errorGroupName !== ``}
                    error={errorGroupName}
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Area
                </div>
                <div className="col-md-8">
                  <SelectComponent
                    options={listAreaSuccess ?? {}}
                    value={areaName 
                      ? { value: areaName, label: areaNameLabel } 
                      : { value: "", label: "Masukkan area"
                    }}
                    asyncUrl={`/v1/area`}
                    customParseData={(item) => ({
                      label: item?.area_name,
                      value: item?.area_id
                    })}
                    onChange={(e) => handleInput(e, setAreaName, setErrorAreaName, "Area", setAreaNameLabel)}
                    isError={errorAreaName !== ``}
                    error={errorAreaName}
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Alamat
                </div>
                <div className="col-md-8">
                  <TextArea
                    placeholder={"Masukkan alamat"}
                    onChange={(e) => handleInput(e, setAlamat, setErrorAlamat, "Alamat")}
                    error={errorAlamat}
                    isError={errorAlamat !== ``}
                    value={alamat || ""}
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Latitude
                </div>
                <div className="col-md-8">
                  <Input 
                    type="number"
                    placeholder={"Masukkan latitude"} 
                    onChange={(e) => handleInput(e, setLatitude, setErrorLatitude)} 
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    value={latitude || ""} 
                    rule={"Nama Latitude"} 
                    error={errorLatitude} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Longitude
                </div>
                <div className="col-md-8">
                  <Input 
                    type="number"
                    placeholder={"Masukkan longitude"} 
                    onChange={(e) => handleInput(e, setLongitude, setErrorLongitude)} 
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    value={longitude || ""} 
                    rule={"Nama Longitude"} 
                    error={errorLongitude} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Surveyor
                </div>
                <div className="col-md-8">
                  <SelectComponent
                    options={listKepKanSuccess ?? {}}
                    value={surveyor 
                      ? { value: surveyor, label: surveyorLabel } 
                      : { value: "", label: "Masukkan surveyor"
                    }}
                    isAsync={true}
                    asyncUrl={`/v1/user/surveyor`}
                    customParseData={(item) => ({
                      label: item?.full_name,
                      value: item?.user_id
                    })}
                    onChange={(e) => handleInput(e, setSurveyor, setErrorSurveyor, "Surveyor", setSurveyorLabel)}
                    isError={errorSurveyor !== ``}
                    error={errorSurveyor}
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  No. Telepon
                </div>
                <div className="col-md-8">
                  <Input
                    placeholder={"Masukkan nomor telepon"}
                    onChange={(e) => handleInput(e, setHandphone, setErrorHandphone)}
                    value={handphone || ""}
                    rule={`Nomor telepon`}
                    error={errorHandphone}
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    minLength={11}
                    maxLength={13}
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Jenis Ayam
                </div>
                <div className="col-md-8">
                  <Input 
                    disabled
                    placeholder={"Masukkan jenis ayam"} 
                    value={jenisAyam || ""} 
                    rule={"Jenis Ayam"} 
                    error={errorJenisAyam} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Jumlah Kandang
                </div>
                <div className="col-md-8">
                  <Input 
                    disabled 
                    placeholder={"Masukkan jumlah kandang"} 
                    value={jumlahKandang || ""} 
                    rule={"Jumlah Kandang"} 
                    error={errorJumlahKandang} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Kapasitas Kandang (ekor)
                </div>
                <div className="col-md-8">
                  <Input 
                    disabled
                    placeholder={"Masukkan kapasitas kandang"} 
                    value={kapasitasKandang || ""} 
                    rule={"Kapasitas Kandang"} 
                    error={errorKapasitasKandang} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
        <div className="d-flex flex-row-reverse mt-5">
          <Button
            variant="contained"
            disableElevation
            className="btn-simpan-ubah-pass"
            sx={{ paddingY: "10px" }}
            onClick={(e) => submitForm()}
            disabled={disableBtnSubmit}
          >
            Simpan
          </Button>
          <Button
            variant="outlined"
            disableElevation
            className="btn-btl-ubah-pass"
            sx={{ paddingY: "10px" }}
            onClick={handleButtonBatal}
          >
            Batal
          </Button>
        </div>
      </div>
    </Container>
  );
}

function mapStateToProps({ listFarm, listKepKan, listGroup, listArea, detailFarm }) {
  return {
    detailFarmSuccess: getDetailFarmSuccess(detailFarm),
    detailFarmPending: getDetailFarmPending(detailFarm),
    detailFarmError: getDetailFarmError(detailFarm),
    listKepKanSuccess: getListKepKan(listKepKan),
    listGroupSuccess: getListGroup(listGroup),
    listAreaSuccess: getListArea(listArea),
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
}

export default connect(mapStateToProps, { listFarm, detailFarm, editFarm, listKepKan, listGroup, listArea })(withRouter(PageEditFarm));
