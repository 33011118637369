import {
    LIST_ROLE_PENDING ,
    LIST_ROLE_SUCCESS ,
    LIST_ROLE_ERROR,

} from '../../actions/role'

const initialState = {
    pending: false,
    error: null,
};

export const listRole = (state = {...initialState , listRole: null} , action) => {
    switch(action.type){
        case LIST_ROLE_PENDING:
            return {
                ...state ,
                pending: true,
            }
        case LIST_ROLE_SUCCESS:
            return {
                ...state,
                pending: false,
                listRole: action.data
            }
        case LIST_ROLE_ERROR:
            return {
                ...state ,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}



export const getListRole        = state => state.listRole
export const getListRolePending = state => state.pending
export const getListRoleError   = state => state.error