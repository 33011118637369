import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  Button,
} from "@mui/material";
import { Navbar } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import ModalFilterDoc from "./ModalFilterDoc";
import ModalUnduhReportDoc from "./ModalUnduhReportDoc";
import SearchBoxFarm from "../../../components/button/buttonSearch";
import Pagination from "../../../components/commons/table/pagination";
import DetailButton from "../../../components/commons/table/buttonAction";
import { listDoc, filterDoc, searchDoc } from "../../../store/actions/doc";
import {
  getListDoc,
  getListDocPending,
  getListDocError,
  getPageData,
  getFilters,
  getSearchTextDoc,
} from "../../../store/reducers/doc";

const Index = (props) => {
  const {
    pageData,
    listDocSuccess,
    listDocPending,
    listDocError,
    filters,
    searchText,
    activeTab,
    showDoc,
  } = props;

  const history = useHistory();
  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
  const [showDetail, setShowDetail] = useState("Produksi");
  const [showModalUnduhReport, setShowModalUnduhReport] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [totalData, setTotalData] = useState(0);

  useEffect(() => {
    props.listDoc({
      page: pageData.page,
      length: pageData.lengthItem,
      search: searchText === "" ? null : searchText,
      id_cage: filters?.id_cage ? filters?.id_cage.value : null,
      id_farm: filters?.id_farm ? filters?.id_farm.value : null,
      surveyor: filters?.surveyor ? filters?.surveyor.value : null,
      tanggal_monitoring:
        filters?.tanggal_monitoring === "" ? null : filters?.tanggal_monitoring,
    });
  }, [filters]);

  useEffect(() => {
    if (listDocSuccess) setTotalData(listDocSuccess?.length);
  }, [listDocSuccess]);

  const handleSearchChange = _.debounce((e) => {
    props.searchDoc(e.target.value);
    if (e?.target?.value !== "" && e?.target?.value?.length < 2) return;
    props.listDoc({
      length: pageData.lengthItem,
      page: 1,
      search: e.target.value === "" ? null : e.target.value,
      id_cage: filters?.id_cage ? filters?.id_cage.value : null,
      id_farm: filters?.id_farm ? filters?.id_farm.value : null,
      surveyor: filters?.surveyor ? filters?.surveyor.value : null,
      tanggal_monitoring:
        filters?.tanggal_monitoring === "" ? null : filters?.tanggal_monitoring,
    });
  }, 500);

  const handleDetailButtonClick = (id) => {
    history.push({
      pathname: `/docmingguan/${id}`,
      state: {
        activeTab: activeTab,
        showDoc: showDoc,
      },
    });
  };

  const handleUnduhReportClick = () => {
    setShowModalUnduhReport(true);
  };

  const reportLists = (data, error) => {
    if (!data || error) {
      return (
        <TableRow>
          <TableCell colSpan={14} align="center">
            Gagal Mengambil Data Report Doc
          </TableCell>
        </TableRow>
      );
    }
    return data?.length < 1 ? (
      <TableRow>
        <TableCell colSpan={14} align="center">
          Data Report tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data?.map((row, i) => {
        return (
          <TableRow key={i}>
            <TableCell align="center">{i + 1}</TableCell>
            <TableCell align="center">
              {row?.tanggal ? moment(row?.tanggal)?.format("DD/MM/YYYY") : "-"}
            </TableCell>
            <TableCell>{row?.farm?.surveyor_fullname ?? ""}</TableCell>
            <TableCell>{row?.farm?.nama_farm ?? ""}</TableCell>
            <TableCell>{row?.cage?.barcode ?? ""}</TableCell>
            <TableCell style={{ textTransform: "capitalize" }}>
              {row?.asal_pullet ?? "-"}
            </TableCell>
            <TableCell>{row?.total ?? 0}</TableCell>
            <TableCell align="center">
              <DetailButton>
                <MenuItem
                  test={row.id}
                  onClick={(e) => handleDetailButtonClick(row?.id)}
                  sx={styleMenuItem}>
                  Detail
                </MenuItem>
              </DetailButton>
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  return (
    <>
      <ModalFilterDoc show={showModalFilter} setShow={setShowModalFilter} />
      <ModalUnduhReportDoc
        show={showModalUnduhReport}
        setShow={setShowModalUnduhReport}
      />
      <Navbar>
        <>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end mb-3">
            {showDetail === "Produksi" && (
              <>
                <SearchBoxFarm
                  handleChange={handleSearchChange}
                  value={searchText}
                />
                <Button
                  color="secondary"
                  variant="outlined"
                  disableElevation
                  sx={{
                    borderRadius: 28,
                    paddingY: "10px",
                    textTransform: "capitalize",
                    color: "black",
                    fontWeight: "800",
                  }}
                  size="large"
                  className={`filters`}
                  onClick={(e) => setShowModalFilter(!showModalFilter)}>
                  {filters?.id_cage?.value ||
                  filters?.id_farm?.value ||
                  filters?.surveyor.value ||
                  filters?.tanggal_monitoring ? (
                    <span className="dot"></span>
                  ) : (
                    ``
                  )}
                  Filter
                </Button>
                <div className="custom-devider" />
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: 28,
                    paddingY: "10px",
                    textTransform: "capitalize",
                    fontWeight: "800",
                    marginLeft: "12px",
                    width: "inherit !important",
                  }}
                  size="large"
                  onClick={handleUnduhReportClick}>
                  Unduh Report
                </Button>
              </>
            )}
          </Navbar.Collapse>
        </>
      </Navbar>
      <TableContainer className="mb-4">
        <Table className="table-with-border">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                rowSpan={2}
                sx={{ fontWeight: "800", paddingY: "16px" }}>
                NO
              </TableCell>
              <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                TANGGAL MONITORING
              </TableCell>
              <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                SURVEYOR
              </TableCell>
              <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                NAMA FARM
              </TableCell>
              <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                KANDANG
              </TableCell>
              <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                ASAL HATCHERY
              </TableCell>
              <TableCell rowSpan={2} sx={{ fontWeight: "800" }}>
                JUMLAH (EKOR)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listDocPending ? (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  Mengambil data report ...
                </TableCell>
              </TableRow>
            ) : (
              reportLists(listDocSuccess, listDocError)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        totalData={totalData}
        pageData={pageData}
        handleClickPage={(page) => {
          if (page) {
            props.listDoc({
              page: page,
              length: pageData.lengthItem,
              search: searchText === "" ? null : searchText,
              id_cage: filters?.id_cage ? filters?.id_cage.value : null,
              id_farm: filters?.id_farm ? filters?.id_farm.value : null,
              surveyor: filters?.surveyor ? filters?.surveyor.value : null,
              tanggal_monitoring: filters?.tanggal_monitoring
                ? filters?.tanggal_monitoring
                : null,
            });
          }
        }}
      />
      <div style={{ marginBottom: "210px" }}></div>
    </>
  );
};

const mapStateToProps = ({ listDoc }) => {
  return {
    listDocSuccess: getListDoc(listDoc),
    listDocPending: getListDocPending(listDoc),
    listDocError: getListDocError(listDoc),
    pageData: getPageData(listDoc),
    filters: getFilters(listDoc),
    searchText: getSearchTextDoc(listDoc),
  };
};

export default connect(mapStateToProps, {
  listDoc,
  filterDoc,
  searchDoc,
})(Index);
