import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { Modal } from "react-bootstrap";
import { InfoRounded } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import DateRangePicker from "../../../components/commons/forms/datepicker/DateRangePicker";
import SelectComponent from "../../../components/commons/forms/select";
import { toastError } from "../../../components/toast";

import { listFarm } from "../../../store/actions/farm";
import { getListFarm } from "../../../store/reducers/farm";
import { listKandang, listSurveyor } from "../../../store/actions/kandang";
import {
  getListKandang,
  getListSurveyor,
} from "../../../store/reducers/kandang";
import { downloadReport, listPeriod } from "../../../store/actions/report";
import { downloadReportDoc, listDoc } from "../../../store/actions/doc";
import {
  getDownloadDocError,
  getDownloadDocPending,
  getListDoc,
} from "../../../store/reducers/doc";
import {
  getDownloadReportPending,
  getDownloadReportError,
  getListPeriod,
} from "../../../store/reducers/report";

export const ModalUnduhReportDoc = (props) => {
  const {
    listFarmSuccess,
    listKandangSuccess,
    downloadDocPending,
    listDocSuccess,
  } = props;

  const [listFarm, setListFarm] = useState(null);
  const [listKandang, setListKandang] = useState(null);
  const [listPeriod, setListPeriod] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDatePulletIn, setStartDatePulletIn] = useState(null);
  const [endDatePulletIn, setEndDatePulletIn] = useState(null);
  const [farm, setFarm] = useState(null);
  const [kandang, setKandang] = useState(null);
  const [period, setPeriod] = useState({});
  const [errorTanggal, setErrorTanggal] = useState("");
  const [errorPulletIn, setErrorPulletIn] = useState("");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    props.listFarm();
    props.listSurveyor();
  }, []);

  useEffect(() => {
    if (listFarmSuccess) {
      setListFarm(
        listFarmSuccess?.map((item) => ({
          label: item?.nama_farm,
          value: item?.id,
        })),
      );
    }
  }, [listFarmSuccess]);

  useEffect(() => {
    if (farm && farm?.value) {
      props.listKandang({
        nama_farm: farm?.label,
      });
    }
  }, [farm]);

  useEffect(() => {
    if (listKandangSuccess) {
      setListKandang(
        listKandangSuccess?.map((item) => ({
          label: item?.barcode,
          value: item?.id,
        })),
      );
    }
  }, [listKandangSuccess]);

  useEffect(() => {
    props.listDoc({
      id_cage: kandang?.value ? kandang?.label : null,
    });
  }, [kandang]);


  useEffect(() => {
    if (listDocSuccess) setListPeriod(listDocSuccess);
  }, [listDocSuccess]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setErrorTanggal("");
  };

  const handleUnduhButton = () => {
    if (startDate && !endDate) {
      setErrorTanggal("Tanggal akhir harus diisi");
      return;
    }

    if (
      !period &&
      !startDate &&
      !endDate &&
      !startDatePulletIn &&
      !endDatePulletIn
    ) {
      toastError("Range tanggal harus diisi");
      return;
    }

    const param = {
      tanggal_mulai: startDate ? moment(startDate)?.format("DD-MM-YYYY") : null,
      tanggal_akhir: endDate ? moment(endDate)?.format("DD-MM-YYYY") : null,
      id_farm: farm ? farm?.value : null,
      id_cage: kandang ? kandang?.value : null,
    };

    props.downloadReportDoc(param);
  };

  const handleClose = () => {
    setStartDate(null);
    setEndDate(null);
    setErrorTanggal("");
    setStartDatePulletIn(null);
    setEndDatePulletIn(null);
    setErrorPulletIn("");
    setFarm(null);
    setKandang(null);
    setPeriod(null);
    setDisabled(false);
    props.setShow(false);
  };

  useEffect(() => {
    if (downloadDocPending) setDisabled(true);
    if (!downloadDocPending) {
      handleClose();
    }
  }, [downloadDocPending]);

  return (
    <Modal size={"md"} show={props.show} onHide={handleClose} centered>
      <Modal.Header closeButton>Unduh Report</Modal.Header>
      <Modal.Body>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin diunduh</div>
        </div>
        <div className="select-wrapper">
          <DateRangePicker
            label={"Tanggal Monitoring"}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            placeholder={"Masukkan Tanggal Monitoring"}
            error={errorTanggal}
          />
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={listFarm}
            label={"Farm"}
            isAsync={true}
            asyncUrl={`/v1/farm`}
            customParseData={(item) => ({
              label: item?.nama_farm,
              value: item?.id,
            })}
            onChange={(e) => {
              setFarm(e);
            }}
            value={
              farm && farm?.value
                ? { value: farm.value, label: farm.label }
                : { value: "", label: "Semua Farm" }
            }
          />
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={listKandang}
            label={"Kandang"}
            isAsync={true}
            asyncUrl={`/v1/cage`}
            customParseData={(item) => ({
              label: item?.barcode,
              value: item?.id,
            })}
            onChange={(e) => {
              setKandang(e);
            }}
            value={
              kandang && kandang?.value
                ? { value: kandang.value, label: kandang.label }
                : { value: "", label: "Semua Kandang" }
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          disableElevation
          className="btn-btl-ubah-pass"
          style={{
            right: "2px",
          }}
          onClick={handleClose}>
          Batal
        </Button>
        <Button
          variant="contained"
          disableElevation
          className="btn-simpan-ubah-pass"
          style={{
            right: "2px",
          }}
          onClick={handleUnduhButton}
          disabled={disabled}>
          {disabled ? (
            <CircularProgress size={18} color="inherit" disableShrink />
          ) : (
            "Unduh"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({
  listSurveyor,
  listFarm,
  listKandang,
  listPeriod,
  listDoc,
  downloadReport,
  downloadReportDoc,
}) => {
  return {
    listFarmSuccess: getListFarm(listFarm),
    listKandangSuccess: getListKandang(listKandang),
    listPeriodSuccess: getListPeriod(listPeriod),
    downloadReportPending: getDownloadReportPending(downloadReport),
    downloadReportError: getDownloadReportError(downloadReport),
    downloadDocError: getDownloadDocError(downloadReportDoc),
    downloadDocPending: getDownloadDocPending(downloadReportDoc),
    listDocSuccess: getListDoc(listDoc),
    listKepkanSuccess: getListSurveyor(listSurveyor),
  };
};

const mapDispatchToProps = {
  downloadReport,
  downloadReportDoc,
  listDoc,
  listFarm,
  listPeriod,
  listKandang,
  listSurveyor,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalUnduhReportDoc);
