import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
import { Container, Navbar } from "react-bootstrap";
import _ from "lodash";

import ModalFilterKandang from "./ModalFilterKandang";
import SearchBox from "../../components/button/buttonSearch";
import ModalDeleteKandang from "../../components/commons/modal/confirmationModal";
import Page from "../../components/commons/table/pagination";
import DetailButton from "../../components/commons/table/buttonAction";

import { listKandang, deleteKandang, searchKandang } from "../../store/actions/kandang";
import { listFarm } from "../../store/actions/farm";
import { getListFarm } from "../../store/reducers/farm";
import {
  getListKandang,
  getListKandangPending,
  getListKandangError,
  getPageData,
  getFilters,
  getSearchText,
} from "../../store/reducers/kandang";

const Index = (props) => {
  const {
    pageData,
    listKandangSuccess,
    listKandangPending,
    listKandangError,
    listFarmSuccess,
    filters,
    searchText,
  } = props;

  const history = useHistory();
  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  const [showModalDeleteKandang, setShowModalDeleteKandang] = useState(false);
  const [showModalFilterKandang, setShowModalFilterKandang] = useState(false);
  const [editData, setEditData] = useState(null);

  let optionNamaFarm = listFarmSuccess?.map((item) => ({ ...item }));

  const handleEditButtonClick = (id) => {
    history.push(`/kandang-management/edit/${id}`);
  };

  const handleDetailButtonClick = (id) => {
    history.push(`/kandang-management/detail/${id}`);
  };

  const handleDeleteButtonClick = (data) => {
    setEditData(data);
    setShowModalDeleteKandang(true);
  };

  const handleDelete = () => {
    const id = editData?.barcode;
    props.deleteKandang(id);
    setTimeout(() => {
      setShowModalDeleteKandang(false);
      props.listKandang({
        length: pageData.lengthItem,
        page: pageData.page,
        search: searchText === "" ? null : searchText,
        surveyor: filters.surveyor.value,
        nama_farm: filters.nama_farm.value,
        group_id: filters.group_id.value,
        status: filters.status.value,
      });
    }, 1000);
  };

  const handleRefresh = () => {
    props.listKandang({
      length: pageData.lengthItem,
      page: pageData.page,
      search: searchText === "" ? null : searchText,
      surveyor: filters.surveyor.value,
      nama_farm: filters.nama_farm.value,
      group_id: filters.group_id.value,
      status: filters.status.value,
    });
  }

  


  useEffect(() => {
    handleRefresh();
    props.listFarm();
  }, [filters]);

  const handleSearchChange = _.debounce((e) => {
    props.searchKandang(e.target.value);
    if (e?.target?.value !== "" && e?.target?.value?.length < 2) return;
    props.listKandang({
      length: pageData.lengthItem,
      page: 1,
      search: e.target.value === "" ? null : e.target.value,
      nama_farm: filters.nama_farm.value,
      surveyor: filters.surveyor.value,
      group_id: filters.group_id.value,
      status: filters.status.value,
    });
  }, 500);

  const kandangLists = (data, error) => {
    if (!data || error) {
      return (
        <TableRow>
          <TableCell colSpan={8} align="center">
            Gagal Mengambil Data Kandang
          </TableCell>
        </TableRow>
      );
    }
    return data?.length < 1 ? (  
      <TableRow>
        <TableCell colSpan={8} align="center">
          Data Kandang tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data?.map((row, i) => (
        <TableRow key={i}>
          <TableCell align="center">{i + 1}</TableCell>
          <TableCell>
            {row?.barcode ?? "-"}
          </TableCell>
          <TableCell align="center">
            {row?.kapasitas_kandang ?? "-"}
          </TableCell>
          <TableCell>
            {row?.farm?.nama_farm
              ? row?.farm?.nama_farm?.length > 20
                ? row?.farm?.nama_farm?.slice(0, 20) + "..."
                : row?.farm?.nama_farm
              : "-"}
          </TableCell>
          <TableCell>
            {row?.farm?.nama_group
              ? row?.farm?.nama_group?.length > 20
                ? row?.farm?.nama_group?.slice(0, 20) + "..."
                : row?.farm?.nama_group
              : "-"}
          </TableCell>
          <TableCell>
            {row?.farm?.surveyor_fullname
              ? row?.farm?.surveyor_fullname?.length > 20
                ? row?.farm?.surveyor_fullname?.slice(0, 20) + "..."
                : row?.farm?.surveyor_fullname
              : "-"}
          </TableCell>
          <TableCell>
            {row?.farm?.jenis_ayam
              ? row?.farm?.jenis_ayam?.length > 20
                ? row?.farm?.jenis_ayam?.slice(0, 20) + "..."
                : row?.farm?.jenis_ayam
              : "-"}
          </TableCell>
          <TableCell>
            <div
              style={{ width: "100%" }}
              className={
                row.status == "aktif"
                  ? "btn-status btn-sm bg-warning rounded"
                  : row.status == "panen"
                  ? "btn-status btn-sm bg-success rounded"
                  : "btn-status btn-sm bg-secondary rounded"
              }
            >
              {row?.status === 'tidakaktif'
                ? 'Tidak Aktif'
                : row.status[0].toUpperCase() + row.status.substring(1)}
            </div>
          </TableCell>
          <TableCell align="center">
            <DetailButton>
              <MenuItem
                test={row.id}
                onClick={(e) => handleDetailButtonClick(row.barcode)}
                sx={styleMenuItem}
              >
                Detail
              </MenuItem>
              <MenuItem
                test={row.id}
                onClick={(e) => handleEditButtonClick(row.barcode)}
                sx={styleMenuItem}
              >
                Edit
              </MenuItem>
              <MenuItem
                test={row.id}
                onClick={(e) => handleDeleteButtonClick(row)}
                sx={styleMenuItem}
              >
                Hapus
              </MenuItem>
            </DetailButton>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Container>
      {editData ? (
        <>
          <ModalDeleteKandang
            show={showModalDeleteKandang}
            toggle={() => setShowModalDeleteKandang(false)}
            handleSubmit={handleDelete}
          />
        </>
      ) : (
        ""
      )}

      <ModalFilterKandang
        show={showModalFilterKandang}
        setShow={setShowModalFilterKandang}
        optionNamaFarm={
          optionNamaFarm?.map((item) => ({
            label: item?.nama_farm,
            value: item?.nama_farm
          }))
        }
      />

      <Navbar>
        <>
          <Navbar.Brand>
            <h1 className="judul-header">Kandang Management</h1>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <SearchBox
              handleChange={handleSearchChange}
              value={searchText}
            />
            <div className="custom-devider" />
            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                color: "black",
                fontWeight: "800",
              }}
              size="large"
              className={`filters`}
              onClick={(e) => setShowModalFilterKandang(!showModalFilterKandang)}
            >
              {filters.nama_farm.value || 
                filters.surveyor.value || 
                filters.group_id.value || 
                filters.status.value 
              ? (
                <span className="dot"></span>
              ) : (
                ``
              )}
              Filter
            </Button>
          </Navbar.Collapse>
        </>
      </Navbar>
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "800", paddingY: "16px" }}
            >
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>BARCODE</TableCell>
            <TableCell align="center" sx={{ fontWeight: "800" }}>
              KAP. KANDANG (EKOR)
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA FARM</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA GROUP</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>SURVEYOR</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>JENIS AYAM</TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listKandangPending ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                Mengambil data kandang ...
              </TableCell>
            </TableRow>
          ) : (
            kandangLists(listKandangSuccess, listKandangError)
          )}
        </TableBody>
      </Table>
      <Page
        pageData={pageData}
        handleClickPage={(page) => {
          if (page) {
            props.listKandang({
              length: pageData.lengthItem,
              page: page,
              search: searchText === "" ? null : searchText,
              surveyor: filters.surveyor.value,
              nama_farm: filters.nama_farm.value,
              group_id: filters.group_id.value,
              status: filters.status.value,
            });
          }
        }} 
      />
      <div style={{ marginBottom: "210px" }}></div>
    </Container>
  );
};


const mapStateToProps = ({ listFarm, listKandang }) => {
  return {
    listFarmSuccess: getListFarm(listFarm),
    listKandangSuccess: getListKandang(listKandang),
    listKandangPending: getListKandangPending(listKandang),
    listKandangError: getListKandangError(listKandang),
    pageData: getPageData(listKandang),
    filters: getFilters(listKandang),
    searchText: getSearchText(listKandang),
  };
};

export default connect(mapStateToProps, { listFarm, listKandang, deleteKandang, searchKandang })(Index);
