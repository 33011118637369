import {
  UPDATE_FARM_PAGE,
  LIST_FARM_PENDING,
  LIST_FARM_SUCCESS,
  LIST_FARM_ERROR,
  UPDATE_FILTER_FARM,
  UPDATE_SEARCH_FARM,
  LIST_KEPKAN_PENDING,
  LIST_KEPKAN_SUCCESS,
  LIST_KEPKAN_ERROR,
  LIST_AREA_PENDING,
  LIST_AREA_SUCCESS,
  LIST_AREA_ERROR,
  LIST_GROUP_PENDING,
  LIST_GROUP_SUCCESS,
  LIST_GROUP_ERROR,
  TAMBAH_FARM_PENDING,
  TAMBAH_FARM_SUCCESS,
  TAMBAH_FARM_ERROR,
  EDIT_FARM_PENDING,
  EDIT_FARM_SUCCESS,
  EDIT_FARM_ERROR,
  DELETE_FARM_PENDING,
  DELETE_FARM_SUCCESS,
  DELETE_FARM_ERROR,
  DETAIL_FARM_PENDING,
  DETAIL_FARM_SUCCESS,
  DETAIL_FARM_ERROR,
} from "../../actions/farm";

const initialState = {
  listFarms: [],
  detailFarm: {},
  pending: false,
  error: null,
  pageData: {
    total: null,
    lengthItem: 10,
    page: 1,
  },
  filters: {
    nama_farm: {},
    surveyor: {},
    group_id: {},
    area_id: {},
  },
  search_text: "",
};

export const listFarm = (state = initialState, action) => {
  switch (action.type) {
    case LIST_FARM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_FARM_SUCCESS:
      return {
        ...state,
        pending: false,
        listFarms: action.data.data,
        pageData: {
          ...state.pageData,
          total: action.data.total,
        },
        error: false,
      };
    case LIST_FARM_ERROR:
      return {
        ...state,
        pending: false,
        pageData: {
          ...state.pageData,
          total: 0,
        },
        error: action.error,
      };
    case UPDATE_FARM_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          page: action.data,
        },
      };
    case UPDATE_FILTER_FARM:
      return {
        ...state,
        filters: {
          nama_farm: action.data.nama_farm,
          surveyor: action.data.surveyor,
          group_id: action.data.group_id,
          area_id: action.data.area_id,
        },
      };
    case UPDATE_SEARCH_FARM:
      return {
        ...state,
        search_text: action.data,
      };
    default:
      return state;
  }
};

export const listKepKan = (
  state = { ...initialState, listKepKan: null },
  action,
) => {
  switch (action.type) {
    case LIST_KEPKAN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_KEPKAN_SUCCESS:
      return {
        ...state,
        pending: false,
        listKepKan: action.data,
        error: false,
      };
    case LIST_KEPKAN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const listArea = (
  state = { ...initialState, listArea: null },
  action,
) => {
  switch (action.type) {
    case LIST_AREA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_AREA_SUCCESS:
      return {
        ...state,
        pending: false,
        listArea: action.data,
        error: false,
      };
    case LIST_AREA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const listGroup = (
  state = { ...initialState, listGroup: null },
  action,
) => {
  switch (action.type) {
    case LIST_GROUP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        listGroup: action.data,
        error: false,
      };
    case LIST_GROUP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const tambahFarm = (state = initialState, action) => {
  switch (action.type) {
    case TAMBAH_FARM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case TAMBAH_FARM_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case TAMBAH_FARM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const editFarm = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_FARM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_FARM_SUCCESS:
      return {
        ...state,
        pending: false,
        error: false,
      };
    case EDIT_FARM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const detailFarm = (state = initialState, action) => {
  switch (action.type) {
    case DETAIL_FARM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_FARM_SUCCESS:
      return {
        ...state,
        pending: false,
        detailFarm: action.data,
        error: false,
      };
    case DETAIL_FARM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export function deleteFarm(state = initialState, action) {
  switch (action.type) {
    case DELETE_FARM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_FARM_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteFarm: action.data,
        error: false,
      };
    case DELETE_FARM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListFarm = (state) => state.listFarms;
export const getPageData = (state) => state.pageData;
export const getFilters = (state) => state.filters;
export const getSearchText = (state) => state.search_text;
export const getListFarmPending = (state) => state.pending;
export const getListFarmError = (state) => state.error;
export const getListKepKan = (state) => state.listKepKan;
export const getListKepKanError = (state) => state.error;
export const getListKepKanPending = (state) => state.pending;
export const getListArea = (state) => state.listArea;
export const getListAreaError = (state) => state.error;
export const getListAreaPending = (state) => state.pending;
export const getListGroup = (state) => state.listGroup;
export const getListGroupError = (state) => state.error;
export const getListGroupPending = (state) => state.pending;
export const getAddFarmPending = (state) => state.pending;
export const getAddFarmError = (state) => state.error;
export const getEditFarmSuccess = (state) => state.editFarm;
export const getEditFarmPending = (state) => state.pending;
export const getEditFarmError = (state) => state.error;
export const getDetailFarmSuccess = (state) => state.detailFarm;
export const getDetailFarmPending = (state) => state.pending;
export const getDetailFarmError = (state) => state.error;
export const getDeleteFarmPending = (state) => state.pending;
export const getDeleteFarmError = (state) => state.error;
