import DatePicker from "react-datepicker";

const CustomDatePicker = ({
  type,
  placeholder,
  disabled,
  value,
  onChange,
  style,
  className,
  label,
  leftIcon,
  rightIcon,
  error,
  defaultValue,
  rule,
  onKeyPress,
  maxLength,
  selected,
  dateFormat,
  timeFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeCaption,
  readOnly,
  minDate,
  maxDate = new Date(),
  format,
  groupClass = "",
  wrapperClass = "",
  wrapperStyle,
  formStyle,
}) => {
  return (
    <div className={`${groupClass}`} style={{ formStyle }}>
      {label && <label className="form-label">{label}</label>}
      <div className={`input-wrapper ${wrapperClass}`} style={wrapperStyle}>
        {leftIcon && (
          <span className="material-icons-round left-icon-input">
            {leftIcon}
          </span>
        )}
        <DatePicker
          className={`form-control ${className || ""} ${
            rightIcon ? "end-icon" : ""
          } ${error ? "is-invalid" : ""}`}
          type={type}
          placeholderText={placeholder}
          defaultValue={defaultValue}
          rule={rule}
          onChange={onChange}
          timeFormat={timeFormat}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeCaption={timeCaption}
          minDate={minDate}
          maxDate={maxDate}
          selected={selected}
          value={value}
          disabled={disabled}
          style={style}
          onKeyPress={onKeyPress}
          maxLength={maxLength}
          readOnly={readOnly}
          dateFormat={format ? format : "dd-MM-yyyy"}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          popperModifiers={[
            {
              name: "arrow",
              options: { padding: ({ popper }) => popper.width / 2 },
            },
          ]}
        />
        {error ? <span className="error-msg error-msg-show">{error}</span> : ``}
        {rightIcon && selected && (
          <span className="material-icons-round right-icon-input">
            {rightIcon}
          </span>
        )}
      </div>
    </div>
  );
};

export default CustomDatePicker;
