import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
} from "@mui/material";
import { Container } from "react-bootstrap";
import Page from "../../../components/commons/table/pagination";
import DetailButton from "../../../components/commons/table/buttonAction";
import ModalDeleteKandang from "../../../components/commons/modal/confirmationModal";

import {
  deleteKandang,
  listKandang,
  filterKandang,
  searchKandang,
} from "../../../store/actions/kandang";
import {
  getFilters,
  getPageData,
  getSearchText,
  getListKandang,
  getListKandangPending,
  getListKandangError,
} from "../../../store/reducers/kandang";

const Index = (props) => {
  const history = useHistory();
  const [showModalDeleteKandang, setShowModalDeleteKandang] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [barcodeKandang, setBarcodeKandang] = useState(false);
  const {
    pageData,
    listKandangSuccess,
    listKandangPending,
    listKandangError,
    filters,
    searchText,
    filterNamaFarm,
    showTab,
    publicIdFarm,
  } = props;
  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  useEffect(() => {
    props.listKandang({
      length: pageData.lengthItem,
      page: pageData.page,
      search: searchText === "" ? null : searchText,
      nama_farm: filterNamaFarm,
      surveyor: filters.surveyor.value,
      status: filters.status.value,
    });
  }, [filters]);

  useEffect(() => {
    if (listKandangSuccess) setTotalData(listKandangSuccess?.length);
  }, [listKandangSuccess]);

  const handleResetState = () => {
    props.searchKandang(null);
    props.filterKandang({
      nama_farm: filterNamaFarm,
      status: null,
      surveyor: null,
    });
    props.listKandang({
      length: 10,
      page: 1,
      search: null,
      nama_farm: filterNamaFarm,
    });
  };

  useEffect(() => {
    return () => handleResetState();
  }, []);

  const handleEditButtonClick = (id) => {
    history.push(`/farmmanagement/${props.publicIdFarm}/edit-kandang/${id}`);
  };

  const handleDetailButtonClick = (id) => {
    history.push({
      pathname: `/farmmanagement/${props.idFarm}/kandang/${id}`,
      state: {
        activeTab: showTab,
        publicIdFarm: publicIdFarm,
      },
    });
  };

  const handleDeleteButtonClick = (id) => {
    setBarcodeKandang(id);
    setShowModalDeleteKandang(true);
  };

  const handleDelete = () => {
    props.deleteKandang(barcodeKandang);
    setTimeout(() => {
      setShowModalDeleteKandang(false);
      history.go(0);
    }, 1000);
  };

  const farmLists = (data, error) => {
    if (!data || error) {
      return (
        <TableRow>
          <TableCell colSpan={8} align="center">
            Gagal Mengambil Data Kandang
          </TableCell>
        </TableRow>
      );
    }
    return data?.length < 1 ? (
      <TableRow>
        <TableCell colSpan={8} align="center">
          Data Kandang tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data?.map((row, i) => (
        <TableRow key={i}>
          <TableCell align="center">{i + 1}</TableCell>
          <TableCell>{row?.barcode}</TableCell>
          <TableCell align="center">
            {row?.kapasitas_kandang ? row?.kapasitas_kandang : "-"}
          </TableCell>
          <TableCell>
            {row?.farm?.nama_farm
              ? row?.farm?.nama_farm?.length > 20
                ? row?.farm?.nama_farm?.slice(0, 20) + "..."
                : row?.farm?.nama_farm
              : "-"}
          </TableCell>
          <TableCell>
            {row?.farm?.nama_group
              ? row?.farm?.nama_group?.length > 20
                ? row?.farm?.nama_group?.slice(0, 20) + "..."
                : row?.farm?.nama_group
              : "-"}
          </TableCell>
          <TableCell>
            {row?.farm?.surveyor_fullname
              ? row?.farm?.surveyor_fullname?.length > 20
                ? row?.farm?.surveyor_fullname?.slice(0, 20) + "..."
                : row?.farm?.surveyor_fullname
              : "-"}
          </TableCell>
          <TableCell>
            {row?.farm?.jenis_ayam
              ? row?.farm?.jenis_ayam?.length > 20
                ? row?.farm?.jenis_ayam?.slice(0, 20) + "..."
                : row?.farm?.jenis_ayam
              : "-"}
          </TableCell>
          <TableCell>
            <div
              style={{ width: "100%" }}
              className={
                row.status === "Aktif" || row.status === "aktif"
                  ? "btn-status btn-sm bg-warning rounded"
                  : row.status === "panen"
                  ? "btn-status btn-sm bg-success rounded"
                  : "btn-status btn-sm bg-danger rounded"
              }>
              {row?.status === "tidakaktif"
                ? "Tidak Aktif"
                : row.status[0].toUpperCase() + row.status.substring(1)}
            </div>
          </TableCell>
          <TableCell align="center">
            <DetailButton>
              <MenuItem
                test={row.id}
                onClick={(e) => {
                  handleDetailButtonClick(row.barcode);
                }}
                sx={styleMenuItem}>
                Detail
              </MenuItem>
              {/* <MenuItem
                test={row.id}
                onClick={(e) => handleEditButtonClick(row.barcode)}
                sx={styleMenuItem}
              >
                Edit
              </MenuItem>
              <MenuItem
                test={row.id}
                onClick={(e) => handleDeleteButtonClick(row.barcode)}
                sx={styleMenuItem}
              >
                Hapus
              </MenuItem> */}
            </DetailButton>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Container>
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "800", paddingY: "16px" }}>
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>BARCODE</TableCell>
            <TableCell align="center" sx={{ fontWeight: "800" }}>
              KAP. KANDANG (EKOR)
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA FARM</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA GROUP</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>SURVEYOR</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>JENIS AYAM</TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>
              STATUS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listKandangPending ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Mengambil data kandang ...
              </TableCell>
            </TableRow>
          ) : (
            farmLists(listKandangSuccess, listKandangError)
          )}
        </TableBody>
      </Table>
      <Page
        totalData={totalData}
        pageData={pageData}
        handleClickPage={(page) => {
          if (page) {
            props.listKandang({
              length: pageData.lengthItem,
              page: page,
              search: searchText === "" ? null : searchText,
              nama_farm: filterNamaFarm,
              surveyor: filters.surveyor.value,
              status: filters.status.value,
            });
          }
        }}
      />
      <div style={{ marginBottom: "210px" }}></div>
      <ModalDeleteKandang
        show={showModalDeleteKandang}
        toggle={() => setShowModalDeleteKandang(false)}
        handleSubmit={handleDelete}
      />
    </Container>
  );
};

function mapStateToProps({ listKandang }) {
  return {
    listKandangSuccess: getListKandang(listKandang),
    listKandangPending: getListKandangPending(listKandang),
    listKandangError: getListKandangError(listKandang),
    pageData: getPageData(listKandang),
    filters: getFilters(listKandang),
    searchText: getSearchText(listKandang),
  };
}

export default connect(mapStateToProps, {
  listKandang,
  filterKandang,
  deleteKandang,
  searchKandang,
})(Index);
