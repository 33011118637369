import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Warning1 from '../../../../assets/img/Warning.png';
import { Button } from '@mui/material';

const Index = ({
  show,
  toggle,
  handleSubmit
}) => {

  return (
    <Modal size={"md"} onHide={toggle} show={show} centered>
      <ModalHeader closeButton>Konfirmasi</ModalHeader>
      <ModalBody>
        <div className='container-fluid'>
          <div className='row justify-content-around'>
            <div className='col-md-1 icon-warning'>
                <img src={Warning1} alt="icon warning" />
            </div>
            <div className='col-md-11 text-icon-warning'>
              <span className='text-bold'>
                Apakah Anda yakin ingin menghapus data ini?
              </span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button 
          variant="outlined" 
          disableElevation 
          className="btn-btl-delete"
          sx={{ paddingY: "10px" }}
          onClick={toggle}
        >
            Batal
        </Button>

        <Button 
          variant="contained" 
          disableElevation 
          className="btn-delete"
          sx={{ paddingY: "10px" }}
          onClick={handleSubmit}
        >
          Ya,Hapus
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default (Index);