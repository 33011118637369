import axios from "axios";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";

let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${user?.token || ""}` },
});

export const instance2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL2,
  headers: { Authorization: `Bearer ${user?.token || ""}` },
});

export const download = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "blob",
  headers: { Authorization: `Bearer ${user?.token || ""}` },
});

export const backend = process.env.REACT_APP_API_URL;

export const cancelToken = axios.CancelToken;

export default instance;
