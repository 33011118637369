import {
  UPDATE_REPORT_PAGE,
  LIST_REPORT_PENDING,
  LIST_REPORT_SUCCESS,
  LIST_REPORT_ERROR,
  UPDATE_FILTER_REPORT,
  UPDATE_SEARCH_REPORT,
  DETAIL_REPORT_PENDING,
  DETAIL_REPORT_SUCCESS,
  DETAIL_REPORT_ERROR,
  DOWNLOAD_REPORT_PENDING,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
  LIST_PERIOD_PENDING,
  LIST_PERIOD_SUCCESS,
  LIST_PERIOD_ERROR,
  LIST_GROWER_PENDING,
  LIST_GROWER_SUCCESS,
  LIST_GROWER_ERROR,
} from "../../actions/report";

const initialState = {
  listReport: [],
  listPeriod: [],
  detailReport: {},
  pending: false,
  error: null,
  pageData: {
    total: null,
    lengthItem: 10,
    page: 1,
  },
  filters: {
    farm: {},
    kandang: {},
    period: {},
    tanggal_mulai: null,
    tanggal_akhir: null,
    tanggal_mulai_pullet_in: null,
    tanggal_akhir_pullet_in: null,
    ip: {},
  },
  search: "",
};

export const listReport = (state = initialState, action) => {
  switch (action.type) {
    case LIST_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        listReport: action.data.data,
        pageData: {
          ...state.pageData,
          total: action.data.total,
        },
        error: false,
      };
    case LIST_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        pageData: {
          ...state.pageData,
          total: 0,
        },
        error: action.error,
      };
    case UPDATE_REPORT_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          page: action.data,
        },
      };
    case UPDATE_FILTER_REPORT:
      return {
        ...state,
        filters: {
          period: action.data.period,
          farm: action.data.farm,
          kandang: action.data.kandang,
          tanggal_mulai: action.data.tanggal_mulai,
          tanggal_akhir: action.data.tanggal_akhir,
          tanggal_mulai_pullet_in: action.data.tanggal_mulai_pullet_in,
          tanggal_akhir_pullet_in: action.data.tanggal_akhir_pullet_in,
          ip: action.data.ip,
        },
      };
    case UPDATE_SEARCH_REPORT:
      return {
        ...state,
        search: action.data,
      };
    default:
      return state;
  }
};

export const detailReport = (state = initialState, action) => {
  switch (action.type) {
    case DETAIL_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        detailReport: action.data,
        error: false,
      };
    case DETAIL_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const downloadReport = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const listPeriod = (
  state = { ...initialState, listPeriod: null },
  action,
) => {
  switch (action.type) {
    case LIST_PERIOD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_PERIOD_SUCCESS:
      return {
        ...state,
        pending: false,
        listPeriod: action.data,
        error: false,
      };
    case LIST_PERIOD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const listIP = (state = { ...initialState, listIP: null }, action) => {
  switch (action.type) {
    case LIST_GROWER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_GROWER_SUCCESS:
      return {
        ...state,
        pending: false,
        listIP: action.data,
      };
    case LIST_GROWER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getListPeriod = (state) => state.listPeriod;
export const getListReport = (state) => state.listReport;
export const getPageData = (state) => state.pageData;
export const getFilters = (state) => state.filters;
export const getSearchText = (state) => state.search;
export const getListReportPending = (state) => state.pending;
export const getListReportError = (state) => state.error;
export const getDetailReportSuccess = (state) => state.detailReport;
export const getDetailReportPending = (state) => state.pending;
export const getDetailReportError = (state) => state.error;
export const getDownloadReportPending = (state) => state.pending;
export const getDownloadReportError = (state) => state.error;
export const getDataStandartGrower = (state) => state.listIP;
export const getDataStandartGrowerPending = (state) => state.pending;
export const getDataStandartGrowerError = (state) => state.error;
