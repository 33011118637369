import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  Button,
} from "@mui/material";
import { Container, Navbar } from "react-bootstrap";
import _ from "lodash";
import ModalFilterFarm from "./ModalFilterFarm";
import SearchBoxFarm from "../../components/button/buttonSearch";
import ModalDeleteFarm from "../../components/commons/modal/confirmationModal";
import PageFarm from "../../components/commons/table/pagination";
import DetailButton from "../../components/commons/table/buttonAction";

import {
  listFarm,
  filterFarm,
  deleteFarm,
  searchFarm,
} from "../../store/actions/farm";
import {
  getListFarm,
  getListFarmPending,
  getListFarmError,
  getPageData,
  getFilters,
  getSearchText,
} from "../../store/reducers/farm";

const Index = (props) => {
  const {
    pageData,
    listFarmSuccess,
    listFarmPending,
    listFarmError,
    filters,
    searchText,
  } = props;

  const history = useHistory();
  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  const [showModalDeleteFarm, setShowModalDeleteFarm] = useState(false);
  const [showModalFilterFarm, setShowModalFilterFarm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [totalData, setTotalData] = useState(0);

  let optionNamaFarm = listFarmSuccess?.map((item) => ({ ...item }));

  useEffect(() => {
    props.listFarm({
      length: pageData.lengthItem,
      page: pageData.page,
      search: searchText === "" ? null : searchText,
      nama_farm: filters.nama_farm.value,
      surveyor: filters.surveyor.value,
      group_id: filters.group_id.value,
      area_id: filters?.area_id?.value ? [filters?.area_id?.value] : null,
    });
  }, [filters]);

  useEffect(() => {
    if (listFarmSuccess) setTotalData(listFarmSuccess?.length);
  }, [listFarmSuccess]);

  const handleResetState = () => {
    props.searchFarm(null);
    props.filterFarm({
      nama_farm: null,
      surveyor: null,
      group_id: null,
      area_id: null,
    });
    props.listFarm({
      length: 10,
      page: 1,
    });
  };

  useEffect(() => {
    return () => handleResetState();
  }, []);

  const handleSearchChange = _.debounce((e) => {
    props.searchFarm(e.target.value);
    if (e?.target?.value !== "" && e?.target?.value?.length < 2) return;
    props.listFarm({
      length: pageData.lengthItem,
      page: 1,
      search: e.target.value === "" ? null : e.target.value,
      nama_farm: filters.nama_farm.value,
      surveyor: filters.surveyor.value,
      group_id: filters.group_id.value,
      area_id: filters.area_id.value,
    });
  }, 500);

  const handleEditButtonClick = (id) => {
    history.push(`/farmmanagement/edit/${id}`);
  };

  const handleDetailButtonClick = (id) => {
    history.push(`/farmmanagement/${id}`);
  };

  const handleDeleteButtonClick = (data) => {
    setEditData(data);
    setShowModalDeleteFarm(true);
  };

  const handleDelete = () => {
    const id = editData?.public_id;
    props.deleteFarm(id);
    setTimeout(() => {
      setShowModalDeleteFarm(false);
      props.listFarm({
        length: pageData.lengthItem,
        page: pageData.page,
        search: searchText === "" ? null : searchText,
        surveyor: filters.surveyor.value,
        nama_farm: filters.nama_farm.value,
        group_id: filters.group_id.value,
        area_id: filters.area_id.value,
      });
    }, 1000);
  };

  const farmLists = (data, error) => {
    if (!data || error) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            Gagal Mengambil Data Farm
          </TableCell>
        </TableRow>
      );
    }
    return data?.length < 1 ? (
      <TableRow>
        <TableCell colSpan={7} align="center">
          Data Farm tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data?.map((row, i) => (
        <TableRow key={i}>
          <TableCell align="center">{i + 1}</TableCell>
          <TableCell>{row?.public_id ?? "-"}</TableCell>
          <TableCell>
            {row?.nama_farm
              ? row?.nama_farm?.length > 20
                ? row?.nama_farm?.slice(0, 20) + "..."
                : row?.nama_farm
              : "-"}
          </TableCell>
          <TableCell>
            {row?.nama_group
              ? row?.nama_group?.length > 20
                ? row?.nama_group?.slice(0, 20) + "..."
                : row?.nama_group
              : "-"}
          </TableCell>
          <TableCell>
            {row?.nama_area
              ? row?.nama_area?.length > 20
                ? row?.nama_area?.slice(0, 20) + "..."
                : row?.nama_area
              : "-"}
          </TableCell>
          <TableCell>
            {row?.surveyor_fullname
              ? row?.surveyor_fullname?.length > 20
                ? row?.surveyor_fullname?.slice(0, 20) + "..."
                : row?.surveyor_fullname
              : "-"}
          </TableCell>
          <TableCell>
            {row?.jenis_ayam
              ? row?.jenis_ayam?.length > 20
                ? row?.jenis_ayam?.slice(0, 20) + "..."
                : row?.jenis_ayam
              : "-"}
          </TableCell>
          <TableCell align="center">
            <DetailButton>
              <MenuItem
                test={row.id}
                onClick={(e) => handleDetailButtonClick(row.public_id)}
                sx={styleMenuItem}>
                Detail
              </MenuItem>
              {/* <MenuItem
                test={row.id}
                onClick={(e) => handleEditButtonClick(row.public_id)}
                sx={styleMenuItem}
              >
                Edit
              </MenuItem>
              <MenuItem
                test={row.id}
                onClick={(e) => handleDeleteButtonClick(row)}
                sx={styleMenuItem}
              >
                Hapus
              </MenuItem> */}
            </DetailButton>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Container>
      {editData ? (
        <>
          <ModalDeleteFarm
            show={showModalDeleteFarm}
            toggle={() => setShowModalDeleteFarm(false)}
            handleSubmit={handleDelete}
          />
        </>
      ) : (
        ""
      )}

      <ModalFilterFarm
        show={showModalFilterFarm}
        setShow={setShowModalFilterFarm}
        optionNamaFarm={optionNamaFarm?.map((item) => ({
          label: item?.nama_farm,
          value: item?.nama_farm,
        }))}
      />

      <Navbar>
        <>
          <Navbar.Brand>
            <h1 className="judul-header">Farm Management</h1>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <SearchBoxFarm
              handleChange={handleSearchChange}
              value={searchText}
            />
            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                color: "black",
                fontWeight: "800",
              }}
              size="large"
              className={`filters`}
              onClick={(e) => setShowModalFilterFarm(!showModalFilterFarm)}>
              {filters.nama_farm.value ||
              filters.surveyor.value ||
              filters.group_id.value ||
              filters.area_id.value ? (
                <span className="dot"></span>
              ) : (
                ``
              )}
              Filter
            </Button>
          </Navbar.Collapse>
        </>
      </Navbar>
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "800", paddingY: "16px" }}>
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>ID FARM</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA FARM</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA GROUP</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>AREA</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>SURVEYOR</TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>
              JENIS AYAM
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listFarmPending ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Mengambil data farm ...
              </TableCell>
            </TableRow>
          ) : (
            farmLists(listFarmSuccess, listFarmError)
          )}
        </TableBody>
      </Table>
      <PageFarm
        totalData={totalData}
        pageData={pageData}
        handleClickPage={(page) => {
          if (page) {
            props.listFarm({
              length: pageData.lengthItem,
              page: page,
              search: searchText === "" ? null : searchText,
              nama_farm: filters.nama_farm.value,
              surveyor: filters.surveyor.value,
              group_id: filters.group_id.value,
              area_id: filters.area_id.value,
            });
          }
        }}
      />
      <div style={{ marginBottom: "210px" }}></div>
    </Container>
  );
};

const mapStateToProps = ({ listFarm }) => {
  return {
    listFarmSuccess: getListFarm(listFarm),
    listFarmPending: getListFarmPending(listFarm),
    listFarmError: getListFarmError(listFarm),
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
};

export default connect(mapStateToProps, {
  listFarm,
  filterFarm,
  deleteFarm,
  searchFarm,
})(Index);
