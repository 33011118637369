import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser } from "../../../utils";
import Layout from "../../../components/layout";

const PrivateRoute = ({ component: Component, roles, path, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = getUser();
      if (!currentUser) {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        );
      }
      if (!roles && roles.indexOf(currentUser.role) < 0) {
        return <Redirect to={{ pathname: "/access-denied" }} />;
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default PrivateRoute;
