import Chart from "chart.js/auto";
import React, { useState, useRef, useEffect } from "react";
import _uniqueId from "lodash/uniqueId";
// import Loader from "../commons/Loader";

let chart;

const LineChart = ({
  height = 250,
  labels = ["Jan", "Feb", "March"],
  scaleTitleX = "",
  scaleTitleY = "",
  scaleTitleY1 = "",
  options = {
    yTickDisplay: true,
  },
  stepSize = 2,
  datasets = [
    {
      label: "Sales",
      data: [86, 67, 91],
      fill: false,
    },
  ],
  pending_chart,
}) => {
  const chartRef = useRef(null);

  const [id] = useState(_uniqueId("linechart-"));
  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    myChartRef.clearRect(0, 0, chartRef.current.width, chartRef.current.height);

    const updatedDatasets = datasets.map((elem) => {
      const updatedElem = {
        ...elem,
        borderWidth: elem.borderWidth !== null && elem.borderWidth !== undefined ? elem.borderWidth : 3,
        maxBarThickness: 40
      };

      if (elem.fill) {
        let bgStartColor =
          "borderColor" in elem
            ? elem.borderColor.replace(/[\d\.]+\)$/g, ".6)")
            : "rgba(0, 166, 207, .6)";
        let bgStopColor =
          "borderColor" in elem
            ? elem.borderColor.replace(/[\d\.]+\)$/g, "0)")
            : "rgba(0, 166, 207, 0)";

        let bgGradient = myChartRef.createLinearGradient(0, 0, 0, height);
        bgGradient.addColorStop(0, bgStartColor);
        bgGradient.addColorStop(1, bgStopColor);

        updatedElem.backgroundColor = bgGradient;
      }

      if (updatedElem.borderDash) {
        updatedElem.borderDashOffset = 0;
      }

      return updatedElem;
    });

    const rotateY1TitlePlugin = {
      id: "rotateY1Title",
      beforeDraw: (chart) => {
        const y1Scale = chart.scales.y1;
        if (scaleTitleY1) {
          const ctx = chart.ctx;
          const { top, bottom } = y1Scale;
          const x = chart.width - 20;
          const y = (top + bottom) / 2;
          ctx.save();
          ctx.translate(x, y);
          ctx.rotate(-Math.PI / 2);
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "bold 14px PlusJakartaSans-Regular";
          ctx.fillStyle = "black";
          ctx.fillText(scaleTitleY1, 0, 0);
          ctx.restore();
        }
      },
    };

    const newChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: labels,
        datasets: updatedDatasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                return context?.length ? `${context[0]?.label} Weeks` : "";
              },
              label: function (context) {
                let label = context.dataset.label || "";
                let label2 = context?.dataset?.label2 || "";
    
                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label += context?.formattedValue?.indexOf('.') !== -1
                    ? parseFloat(context.formattedValue.replace(',', '')).toFixed(2)
                    : context.parsed.y
                }

                if (context.dataset.labelPrepend) {
                  label += context.dataset.labelPrepend;
                }
    
                if (label2) {
                  label2 += ": ";
                }
                if (context.raw.x !== null && context.raw.x !== undefined) {
                  label2 += context.raw.x;
                }
                return [label, label2];
              },
            },
          },
          legend: {
            display: false,
          },
          rotateY1Title: rotateY1TitlePlugin,  // <-- Added plugin here
        },
        elements: {
          line: {
            tension: 0.4,
            borderWidth: 4,
          },
          point: {
            radius: 3,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              padding: 15,
            },
            title: {
              display: true,
              text: scaleTitleX,
              color: "black",
              font: {
                family: "PlusJakartaSans-Regular",
                size: 14,
                weight: "700",
              },
            },
          },
          y: {
            id: "y",
            position: "left",
            type: "linear",
            title: {
              display: true,
              text: scaleTitleY,
              color: "black",
              font: {
                family: "PlusJakartaSans-Regular",
                size: 14,
                weight: "700",
              },
            },
            grid: {
              display: true,
              drawBorder: false,
            },
            suggestedMin: 0,
            ticks: {
              padding: 15,
            },
          },
          y1: {
            display: scaleTitleY1 ? true : false,
            id: "y1",
            position: "right",
            type: "linear",
            title: { display: true, text: "" },
            ticks: {
              padding: 15,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
      },
      plugins: [rotateY1TitlePlugin],  // <-- Added plugin here
    });

    newChart.update();

    return () => {
      newChart.destroy();
    };
  }, [labels, datasets, scaleTitleX, scaleTitleY, scaleTitleY1, height]);

  return (
    <>
      <div style={{ height: height + "px", position: "relative" }}>
        {/* {pending_chart && <Loader loading={pending_chart} />} */}
        <canvas id={id} ref={chartRef} />
      </div>
    </>
  );
};

export default LineChart;
