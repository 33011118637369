import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import SelectComponent from "../../components/commons/forms/select";
import CustomDatePicker from "../../components/commons/forms/datepicker/CustomDatePicker";
import { formatDate } from "../../utils";
import { connect } from "react-redux";
import { listFarm } from "../../store/actions/farm";
import { listKandang } from "../../store/actions/kandang";
import { getListFarm } from "../../store/reducers/farm";
import { getListKandang } from "../../store/reducers/kandang";
import {
  listGraph,
  filterGraph
} from "../../store/actions/dashboard";
import {
  getFilterGraph
} from "../../store/reducers/dashboard";

const ModalFilterGrafik = (props) => {
  const { listFarmSuccess, listKandangSuccess, filters } = props;
  // const [graph, setGraph] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterFarmName, setFilterFarmName] = useState({});
  const [filterKandangName, setFilterKandangName] = useState({});
  // const [filterGraph, setFilterGraph] = useState(null);
  const [listDataFarm, setListDataFarm] = useState([]);
  const [listDataKandang, setListDataKandang] = useState([]);
  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const initialDate = {
    start: filters?.start_date && !filterStartDate
      ? new Date(moment(filters?.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"))
      : new Date(moment(filterStartDate, "DD-MM-YYYY").format("YYYY-MM-DD")),
    end: filters?.end_date && !filterEndDate
      ? new Date(moment(filters?.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"))
      : new Date(moment(filterEndDate, "DD-MM-YYYY").format("YYYY-MM-DD"))
  }

  const listGraph = [
    { label: "Cumulative Mortality", value: "cumulative_mortality" },
    { label: "Feed Consumption", value: "feed_consumption" },
  ];

  const handleStartDateChange = (date) => {
    setFilterStartDate(moment(date).format("DD/MM/YYYY"));

    const maxDate = moment(date, "DD-MM-YYYY").add(2, 'months').format("YYYY-MM-DD");
    const initialEndDate = moment(initialDate?.end).format("YYYY-MM-DD");
    const endDate = initialEndDate > maxDate ? maxDate : initialEndDate;
    setFilterEndDate(moment(endDate).format("DD/MM/YYYY"));
  };

  const handleEndDateChange = (date) => {
    setFilterEndDate(moment(date).format("DD/MM/YYYY"));
  };

  useEffect(() => {
    if (listFarmSuccess) {
      setListDataFarm(
        listFarmSuccess?.map((item) => ({
          label: item?.nama_farm,
          value: item?.id,
        })),
      );
    }
  }, [listFarmSuccess]);

  useEffect(() => {
    if (filterFarmName && filterFarmName?.value) {
      props.listKandang({
        nama_farm: filterFarmName?.label,
      });
    } else {
      props.listKandang();
    }
  }, [filterFarmName]);

  useEffect(() => {
    if (listKandangSuccess) {
      setListDataKandang(
        listKandangSuccess?.map((item) => ({
          label: item?.barcode,
          value: item?.id,
        })),
      );
    }
  }, [listKandangSuccess]);

  useEffect(() => {
    props.listFarm();
    props.listKandang();
  }, []);

  const submitFilter = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterGraph({
        ...filters,
        start_date: filterStartDate,
        end_date: filterEndDate,
        id_farm: filterFarmName,
        id_cage: filterKandangName,
      });
    }, 1000);
  };

  const resetButton = () => {
    props.setShow(false);
    // props.setResponse(null);
    props.filterGraph({
      ...filters,
      start_date: null,
      end_date: null,
      id_farm: null,
      id_cage: null,
    });
    setDefaultState();
  };

  const handleBatal = () => {
    setTimeout(() => {
      props.setShow(false);
      setDefaultState();
    }, 1000);
  };

  const setDefaultState = () => {
    setFilterFarmName("");
    setFilterKandangName("");
    // setGraph("");
    setFilterStartDate("");
    setFilterEndDate("");
  };

  return (
    <Modal size={"md"} onHide={handleBatal} show={props.show} centered>
      <ModalHeader closeButton>Filter</ModalHeader>
      <ModalBody>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin difilter</div>
        </div>
        <div className="select-wrapper">
          <CustomDatePicker
            label="Tanggal Awal"
            onChange={handleStartDateChange}
            placeholder={"Pilih Tanggal Awal"}
            groupClass="form-group"
            // error={errorTanggal}
            selected={initialDate?.start}
            value={
              filters?.start_date && !filterStartDate
                ? moment(filters?.start_date, "DD-MM-YYYY").format("DD/MM/YYYY")
                : filterStartDate
            }
          />
        </div>
        <div className="select-wrapper">
          <CustomDatePicker
            label="Tanggal Akhir"
            onChange={handleEndDateChange}
            placeholder={"Pilih Tanggal Akhir"}
            groupClass="form-group"
            // error={errorTanggal}
            minDate={initialDate?.start}
            maxDate={new Date(moment(initialDate?.start)?.add(2, 'months'))}
            selected={initialDate?.end}
            value={
              filters?.end_date && !filterEndDate
                ? moment(filters?.end_date, "DD-MM-YYYY").format("DD/MM/YYYY")
                : filterEndDate
            }
          />
        </div>
        {/* <div className="select-wrapper">
          <SelectComponent
            options={listGraph}
            label={"Graph"}
            customParseData={(item) => ({
              label: item?.label,
              value: item?.value,
            })}
            onChange={(e) => {
              setFilterGraph(e);
              setGraph(e);
            }}
            value={
              graph === null
                ? { value: "", label: "Semua Graph" }
                : filterGraph?.value && !graph.value
                ? {
                    value: filterGraph?.value,
                    label: filterGraph?.label,
                  }
                : graph.value
                ? { value: graph.value, label: graph.label }
                : { value: "", label: "Semua Graph" }
            }
          />
        </div> */}
        <div className="select-wrapper">
          <SelectComponent
            options={listDataFarm}
            label={"Farm"}
            isAsync={true}
            asyncUrl={`/v1/farm`}
            customParseData={(item) => ({
              label: item?.nama_farm,
              value: item?.id,
            })}
            onChange={(e) => {
              setFilterFarmName(e);
            }}
            value={
              filterFarmName === null
                ? { value: "", label: "Semua Farm" }
                : filters?.id_farm?.value && !filterFarmName?.value
                  ? {
                      value: filters?.id_farm?.value,
                      label: filters?.id_farm?.label,
                    }
                  : filterFarmName?.value
                  ? { value: filterFarmName?.value, label: filterFarmName?.label }
                  : { value: "", label: "Semua Farm" }
            }
          />
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={listDataKandang}
            label={"Kandang"}
            isAsync={true}
            asyncUrl={`/v1/cage`}
            customParseData={(item) => ({
              label: item?.barcode,
              value: item?.id,
            })}
            onChange={(e) => {
              setFilterKandangName(e);
            }}
            value={
              filterKandangName === null
                ? { value: "", label: "Semua Kandang" }
                : filters?.id_cage?.value && !filterKandangName?.value
                  ? {
                      value: filters?.id_cage?.value,
                      label: filters?.id_cage?.label,
                    }
                  : filterKandangName?.value
                  ? {
                      value: filterKandangName?.value,
                      label: filterKandangName?.label,
                    }
                  : { value: "", label: "Semua Kandang" }
            }
          />
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: `space-between` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-2">
              <Button
                variant="outlined"
                disableElevation
                className="btn-btl-ubah-pass"
                style={{
                  right: "2px",
                }}
                onClick={resetButton}>
                Reset
              </Button>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={handleBatal}>
                  Batal
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  className="btn-simpan-ubah-pass"
                  onClick={(e) => submitFilter()}
                  disabled={disableBtnSubmit}
                  style={{ right: "2px" }}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({
  listFarm,
  listKandang,
  listGraph
}) => {
  return {
    listFarmSuccess: getListFarm(listFarm),
    listKandangSuccess: getListKandang(listKandang),
    filters: getFilterGraph(listGraph),
  };
};

export default connect(mapStateToProps, {
  listKandang,
  listFarm,
  listGraph,
  filterGraph
})(ModalFilterGrafik);
