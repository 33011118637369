import React, { useEffect, useState } from "react";
import { ListGroup, Container } from "react-bootstrap";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import SelectComponent from "../../../components/commons/forms/select";
import Input from "../../../components/commons/forms/authentication/input";

import { 
  listKandang, 
  detailKandang, 
  editKandang 
} from "../../../store/actions/kandang";
import { 
  getFilters, 
  getPageData, 
  getSearchText, 
  getDetailKandangSuccess, 
  getDetailKandangPending, 
  getDetailKandangError 
} from "../../../store/reducers/kandang";

function EditKandang(props) {
  const history = useHistory();
  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [kapasitasKandang, setKapasitasKandang] = useState();
  const [name, setName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [surveyor, setSurveyor] = useState("");
  const [jenisAyam, setJenisAyam] = useState();
  const [statusKandang, setStatusKandang] = useState("");
  const [statusKandangLabel, setStatusKandangLabel] = useState("");

  const [errorBarcode, setErrorBarcode] = useState("");
  const [errorKapasitasKandang, setErrorKapasitasKandang] = useState("");
  const [errorStatusKandang, setErrorStatusKandang] = useState("");

  const statusOptions = [
    { value: "aktif", label: "Aktif" },
    { value: "tidakaktif", label: "Tidak Aktif" },
    { value: "panen", label: "Panen" },
  ];

  const { 
    pageData, 
    filters, 
    searchText, 
    detailKandangSuccess, 
  } = props;

  useEffect(() => {
    props.detailKandang(props.match.params.id);
  }, []);

  useEffect(() => {
    if (detailKandangSuccess) {
      setDefaultState(detailKandangSuccess);
    }
  }, [detailKandangSuccess]);
  
  const setDefaultState = (data) => {
    setBarcode(data.barcode);
    setKapasitasKandang(data.kapasitas_kandang);
    setName(data.nama_farm);
    setGroupName(data.nama_group);
    setSurveyor(data.surveyor);
    setJenisAyam(data.jenis_ayam);
    setStatusKandang(data.status);
    setStatusKandangLabel(
      data.status === "aktif"
        ? "Aktif"
        : data?.status === "panen"
        ? "Panen"
        : "Tidak Aktif"
    );
    
    setErrorBarcode("");
    setErrorKapasitasKandang("");
    setErrorStatusKandang("");
  };

  const handleInput = (e, set, error, customRule = false, setLabel = false) => {
    let value;

    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
    }

    const rule = customRule ? customRule : e.target.getAttribute(`rule`);
    if (!value) {
      error(`${rule} wajib diisi`);
    } else {
      error(``);
    }
    set(value);
    if (setLabel) setLabel(e.label);
  };

  const handleError = (data) => {
    data.kapasitas_kandang 
      ? setErrorKapasitasKandang("") 
      : setErrorKapasitasKandang("Kapasitas kandang wajib diisi");
    data.status
      ? setErrorStatusKandang("") 
      : setErrorStatusKandang("Status kandang wajib diisi");
  };

  const handleButtonBatal = (e) => {
    history.goBack()
  };

  const submitForm = () => {
    setDisableBtnSubmit(true);
    const data = {
      barcode,
      kapasitas_kandang : parseInt(kapasitasKandang),
      status: statusKandang
    };

    handleError(data);

    for (var key in data) {
      if (data[key] == "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    props.editKandang(data, successEdit, failedEdit);
  };

  const successEdit = () => {
    setTimeout(() => {
      props.listKandang({
        length: pageData.lengthItem,
        page: pageData.page,
        search_text: searchText === "" ? null : searchText,
        nama_farm: filters.nama_farm.value,
        surveyor: filters.surveyor.value,
        group_id: filters.group_id.value,
        status: filters.status.value,
      });
      setDisableBtnSubmit(false);
      history.goBack()
    }, 1000);
  };

  const failedEdit = () => {
    setDisableBtnSubmit(false);
  };


  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <ArrowBack 
            sx={{ cursor: "pointer" }} 
            onClick={handleButtonBatal} 
          />
          <span
            className="text-extra-bold"
            style={{
              fontSize: "24px",
              fontFamily: "PlusJakartaSans-Bold",
              marginLeft: "26px",
            }}
          >
            Edit Kandang
          </span>
        </div>
      </div>
      <div className="my-5">
        <ListGroup className="cek" style={{ borderRadius: "12px" }}>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Barcode
                </div>
                <div className="col-md-8">
                  <Input 
                    disabled
                    placeholder={"Masukkan Barcode"} 
                    value={barcode || ""} 
                    rule={"Barcode"} 
                    error={errorBarcode}
                  />
                </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Kapasitas Kandang (ekor)
                </div>
                <div className="col-md-8">
                  <Input 
                    type="number"
                    placeholder={"Masukkan kapasitas kandang"} 
                    value={kapasitasKandang || ""} 
                    rule={"Kapasitas Kandang"} 
                    error={errorKapasitasKandang} 
                    onChange={(e) => handleInput(e, setKapasitasKandang, setErrorKapasitasKandang)} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Nama Farm
                </div>
                <div className="col-md-8">
                  <Input 
                    disabled
                    placeholder={"Masukkan nama farm"} 
                    value={name || ""} 
                    rule={"Nama Farm"} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Nama Group
                </div>
                <div className="col-md-8">
                  <Input 
                    disabled
                    placeholder={"Masukkan nama group"} 
                    value={groupName || ""} 
                    rule={"Nama Group"} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Surveyor
                </div>
                <div className="col-md-8">
                  <Input 
                    disabled
                    placeholder={"Masukkan surveyor"} 
                    value={surveyor || ""} 
                    rule={"Nama Surveyor"} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Jenis Ayam
                </div>
                <div className="col-md-8">
                  <Input 
                    disabled
                    placeholder={"Masukkan jenis ayam"} 
                    value={jenisAyam || ""} 
                    rule={"Jenis Ayam"} 
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item style={{ padding: "1rem" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 text-extra-bold">
                  Status Kandang
                </div>
                <div className="col-md-8">
                  <SelectComponent
                    options={statusOptions ?? {}}
                    value={statusKandang 
                      ? { value: statusKandang, label: statusKandangLabel } 
                      : { value: "", label: "Masukkan status kandang"
                    }}
                    onChange={(e) => handleInput(e, setStatusKandang, setErrorStatusKandang, "Status Kandang", setStatusKandangLabel)}
                    isError={errorStatusKandang !== ``}
                    error={errorStatusKandang}
                  />
                  </div>
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
        <div className="d-flex flex-row-reverse mt-5">
          <Button
            variant="contained"
            disableElevation
            className="btn-simpan-ubah-pass"
            sx={{ paddingY: "10px" }}
            onClick={(e) => submitForm()}
            disabled={disableBtnSubmit}
          >
            Simpan
          </Button>
          <Button
            variant="outlined"
            disableElevation
            className="btn-btl-ubah-pass"
            sx={{ paddingY: "10px" }}
            onClick={handleButtonBatal}
          >
            Batal
          </Button>
        </div>
      </div>
    </Container>
  );
}

function mapStateToProps({ listKandang, detailKandang }) {
  return {
    detailKandangSuccess: getDetailKandangSuccess(detailKandang),
    detailKandangPending: getDetailKandangPending(detailKandang),
    detailKandangError: getDetailKandangError(detailKandang),
    pageData: getPageData(listKandang),
    filters: getFilters(listKandang),
    searchText: getSearchText(listKandang),
  };
}

export default connect(mapStateToProps, { listKandang, detailKandang, editKandang })(withRouter(EditKandang));
