import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import example from "./example";
import profile from "./profile";
import { listUser } from "./user";
import { listFeed } from "./feed";
import { listFarm, listKepKan, listGroup, listArea, detailFarm } from "./farm";
import {
  listKandang,
  listSurveyor,
  listGroup as listGroupKandang,
  detailKandang,
} from "./kandang";
import { listRole } from "./role";
import { loginUser, logoutUser } from "./auth";
import {
  listDoc,
  listDocMingguan,
  detailDoc,
  detailMingguanDoc,
  downloadReportDoc,
  downloadReportDocMingguan,
} from "./doc";
import {
  listReport,
  detailReport,
  downloadReport,
  listPeriod,
  listIP,
} from "./report";
import {
  listFarmDB,
  dashboardData,
  tingkatKematian,
  beratAyam,
  statusKandang,
  topFiveData,
  syncDashboard,
  sisaPakan,
  chartCummulativeMortality,
  listGraph
} from "./dashboard";

const rootReducer = combineReducers({
  form: formReducer,
  example,
  profile,
  listUser,
  listFeed,
  listFarm,
  detailFarm,
  listKepKan,
  listGroup,
  listArea,
  listKandang,
  detailKandang,
  listSurveyor,
  listGroupKandang,
  listRole,
  loginUser,
  logoutUser,
  listDoc,
  listReport,
  detailReport,
  listPeriod,
  listFarmDB,
  dashboardData,
  tingkatKematian,
  beratAyam,
  statusKandang,
  topFiveData,
  downloadReport,
  syncDashboard,
  sisaPakan,
  listIP,
  detailDoc,
  detailMingguanDoc,
  listDocMingguan,
  downloadReportDoc,
  downloadReportDocMingguan,
  chartCummulativeMortality,
  listGraph
});

export default rootReducer;
