import DatePicker from "react-datepicker";

const DateRangePicker = ({
  disabled,
  value,
  onChange,
  style,
  className,
  label,
  leftIcon,
  rightIcon,
  error,
  defaultValue,
  rule,
  maxLength,
  selected,
  selectsRange,
  startDate,
  endDate,
  placeholder,
  format,
  groupClass = "",
  wrapperClass = "",
  showYearPicker,
  showMonthYearPicker,
  wrapperStyle,
  isClearable = true,
  maxDate = new Date(),
  minDate,
  excludeDates,
}) => {
  return (
    <div className={`form-group ${groupClass}`}>
      {label && <label className="form-label">{label}</label>}
      <div className={`input-wrapper ${wrapperClass}`} style={wrapperStyle}>
        {leftIcon && (
          <span className="material-icons-round left-icon-input">
            {leftIcon}
          </span>
        )}
        <DatePicker
          className={`form-control ${className || ""} ${
            rightIcon ? "end-icon" : ""
          } ${error ? "is-invalid" : ""}`}
          disabled={disabled}
          value={value}
          onChange={onChange}
          style={style}
          label={label}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          error={error}
          defaultValue={defaultValue}
          rule={rule}
          maxLength={maxLength}
          selected={selected}
          selectsRange={selectsRange}
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate}
          minDate={minDate}
          isClearable={isClearable}
          placeholderText={placeholder}
          dateFormat={format ? format : "dd/MM/yyyy"}
          showYearPicker={showYearPicker}
          showMonthYearPicker={showMonthYearPicker}
          excludeDates={excludeDates}
        />
        {error ? <span className="error-msg error-msg-show">{error}</span> : ``}
        {rightIcon && (
          <span className="material-icons-round right-icon-input">
            {rightIcon}
          </span>
        )}
      </div>
    </div>
  );
};

export default DateRangePicker;
