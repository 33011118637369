import { actionSuccess, actionError, actionPending } from "../action_types";
import API from "../API";
import { toastSuccess, toastError } from "../../../components/toast";
import { history } from "../../../utils";
import { LOCAL_STORAGE_KEY } from "../../../utils/constants";
import { createBrowserHistory } from "history";

export const UPDATE_USER_PAGE = `UPDATE_USER_PAGE`;
export const LIST_USER_PENDING = `LIST_USER_PENDING`;
export const LIST_USER_SUCCESS = `LIST_USER_SUCCESS`;
export const LIST_USER_ERROR = `LIST_USER_ERROR`;
export const EDIT_USER_PENDING = `EDIT_USER_PENDING`;
export const EDIT_USER_SUCCESS = `EDIT_USER_SUCCESS`;
export const EDIT_USER_ERROR = `EDIT_USER_ERROR`;
export const DETAIL_USER_PENDING = `DETAIL_USER_PENDING`;
export const DETAIL_USER_SUCCESS = `DETAIL_USER_SUCCESS`;
export const DETAIL_USER_ERROR = `DETAIL_USER_ERROR`;
export const DELETE_USER_PENDING = `DELETE_USER_PENDING`;
export const DELETE_USER_SUCCESS = `DELETE_USER_SUCCESS`;
export const DELETE_USER_ERROR = `DELETE_USER_ERROR`;
export const TAMBAH_USER_PENDING = `TAMBAH_USER_PENDING`;
export const TAMBAH_USER_SUCCESS = `TAMBAH_USER_SUCCESS`;
export const TAMBAH_USER_ERROR = `TAMBAH_USER_ERROR`;
export const UPDATE_FILTER_USER = `UPDATE_FILTER_USER`;
export const UPDATE_SEARCH_USER = `UPDATE_SEARCH_USER`;
export const RESET_PASSWORD_PENDING = `RESET_PASSWORD_PENDING`;
export const RESET_PASSWORD_SUCCESS = `RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `RESET_PASSWORD_ERROR`;

const LIST_USER_URL = `/v1/user`;
const EDIT_USER_URL = `/v1/user`;
const DELETE_USER_URL = `/v1/user`;
const DETAIL_USER_URL = `/v1/user/detail`;
const TAMBAH_USER_URL = `/v1/user`;
const RESET_PASSWORD_URL = `/v1/user/reset_password`;

export const tambahUser =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(TAMBAH_USER_PENDING));
    API.post(TAMBAH_USER_URL, param)
      .then((res) => {
        dispatch(actionSuccess(TAMBAH_USER_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(TAMBAH_USER_ERROR, error));
        toastError(error);
        callbackFailed();
      });
  };

export const listUser =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_USER_PENDING));
    API.get(LIST_USER_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_USER_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_USER_PAGE, param.page));
        }
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_USER_ERROR, error));
        toastError(error);
      });
  };

export const filterUser =
  (param = {}) =>
  (dispatch) => {
    const status = param.status ? param.status : {};
    dispatch(actionSuccess(UPDATE_FILTER_USER, { status }));
  };

export const searchUser =
  (param = "") =>
  (dispatch) => {
    dispatch(actionSuccess(UPDATE_SEARCH_USER, param));
  };

export const editUser =
  (param = {}, successFunction, failedFunction) =>
  (dispatch) => {
    const history = createBrowserHistory({ forceRefresh: true });
    dispatch(actionPending(EDIT_USER_PENDING));
    API.put(EDIT_USER_URL, param)
      .then((res) => {
        // let ls = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        // if (ls.user.id === param.id) {
        //   ls.user = param;
        //   localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(ls));
        // }
        dispatch(actionSuccess(EDIT_USER_SUCCESS));
        toastSuccess(res.data.message, {
          onClose: (e) => history.push("/usermanagement"),
        });
        successFunction();
      })
      .catch((error) => {
        dispatch(actionError(EDIT_USER_ERROR, error));
        toastError(error);
        failedFunction();
      });
  };

export function detailUser(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_USER_PENDING));
    API.get(DETAIL_USER_URL, param)
      .then((res) => {
        dispatch(actionSuccess(DETAIL_USER_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_USER_ERROR, error));
        toastError(error);
      });
  };
}

export function deleteUser(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DELETE_USER_PENDING));
    API.delete(DELETE_USER_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(DELETE_USER_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_USER_ERROR, error));
        toastError(error);
      });
  };
}

export function resetPassword(id = null, callbackSuccess, callbackFailed) {
  const idString = id.toString();
  const param = { id: idString };
  return (dispatch) => {
    dispatch(actionPending(RESET_PASSWORD_PENDING));
    API.post(RESET_PASSWORD_URL, param)
      .then((res) => {
        dispatch(actionSuccess(RESET_PASSWORD_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(RESET_PASSWORD_ERROR, error));
        toastError(error);
        callbackFailed();
      });
  };
}

