import { useState, useEffect } from "react";
import { toastSuccess, toastError } from "../../toast";
import { connect, useDispatch } from "react-redux";
import account from "../../../assets/img/account.png";
import userAccount from "../../../assets/img/userAccount.png";
import user from "../../../assets/img/user.svg";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Input from "../../commons/forms/authentication/input";
import inputRules from "../../../utils/inputRules";
import Api from "../../../store/actions/API";
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, Button } from "@mui/material";
import { getLogoutUserError, getLogoutUserPending } from "../../../store/reducers/auth";
import { logoutUser } from "../../../store/actions/auth";
import { setChangedProfileOption } from "../../../store/actions/profile";
import { getUser } from "../../../utils";
import { unmountComponentAtNode } from "react-dom";
import { useRef } from "react";

const Index = (props) => {
  const { profileOption } = props;
  const history = useHistory();
  const profile = getUser();

  // variabel buat handle show/hide password
  const [oldVisible, setOldVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  // variabel untuk ubah password
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // variable handle error message
  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  // modal handle
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const ref = useRef(null);

  const handleInput = (e, set, error) => {
    const value = e.target.value;
    const rule = e.target.getAttribute(`rule`);
    if (!value) {
      error(`${rule} wajib diisi`);
    } else {
      const match = new RegExp(inputRules[rule]).test(value);
      if (!match) {
        error(`Minimal 8 karakter, terdiri dari angka, huruf kapital dan huruf kecil`);
      } else error(``);
    }
    set(value);
  };
  const handleConfirmInput = (e, set, error) => {
    const value = e.target.value;
    if (value == newPassword) {
      error(``);
    } else {
      error(`Password tidak sesuai`);
    }
    set(value);
  };

  const defaultStateChange = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");

    setErrorNewPassword("");
    setErrorOldPassword("");
    setErrorConfirmPassword("");
  };

  const handleBatalChange = () => {
    defaultStateChange();
    setChangePasswordModal(!changePasswordModal);
  };

  const handleError = (data) => {
    data.old_pwd ? setErrorOldPassword("") : setErrorOldPassword("Password Saat Ini wajib diisi");
    data.new_pwd ? setErrorNewPassword("") : setErrorNewPassword("Password Baru wajib diisi");
    data.konfirm_pwd ? setErrorConfirmPassword("") : setErrorConfirmPassword("Konfirmasi Password Baru wajib diisi");
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.setChangedProfileOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const changePassword = () => {
    setDisableBtnSubmit(true);
    const data = {
      old_pwd: oldPassword,
      new_pwd: newPassword,
      konfirm_pwd: confirmPassword,
    };
    handleError(data);

    for (var key in data) {
      if (data[key] == "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    if (data.old_pwd) {
      const match = new RegExp(inputRules["Password Saat Ini"]).test(data.old_pwd);
      if (!match) {
        toastError("Format Password tidak sesuai");
        setDisableBtnSubmit(false);
        return;
      }
    }

    if (data.new_pwd) {
      const match = new RegExp(inputRules["Password Baru"]).test(data.new_pwd);
      if (!match) {
        toastError("Format Password tidak sesuai");
        setDisableBtnSubmit(false);
        return;
      }
    }

    Api.put(`/v1/authentications/change-password`, data)
      .then((res) => {
        toastSuccess(res.data.message, {
          autoClose: 2000,
          hideProgressBar: false,
          onClose: (e) => props.logoutUser(),
        });
        setChangePasswordModal(!changePasswordModal);
        defaultStateChange();
        setDisableBtnSubmit(false);
      })
      .catch((err) => {
        toastError(err.response?.data.message, "", { autoClose: 5000, hideProgressBar: false });
        setDisableBtnSubmit(false);
      });
  };

  return (
    <ul className="profile-menu">
      <Modal size={"md"} show={changePasswordModal} onHide={(e) => setChangePasswordModal(!changePasswordModal)} centered>
        <ModalHeader closeButton onClick={handleBatalChange}>
          Ubah Password
        </ModalHeader>
        <ModalBody>
          <Input
            label={`Password Saat Ini`}
            type={oldVisible ? "text" : "password"}
            placeholder={`Masukkan Password Saat Ini`}
            defaultValue={oldPassword}
            rule={`Password Saat Ini`}
            onChange={(e) => handleInput(e, setOldPassword, setErrorOldPassword)}
            error={errorOldPassword}
            rightIcon={<IconButton onClick={(e) => setOldVisible(!oldVisible)}>{oldVisible ? <VisibilityOff /> : <Visibility />}</IconButton>}
          />

          <hr
            style={{
              height: "1px",
              margin: "35px 0",
              border: "none",
              opacity: "0.85",
              backgroundColor: "#E5E5F0",
            }}
          />

          <Input
            label={`Password Baru`}
            type={newVisible ? "text" : "password"}
            placeholder={`Masukkan Password Baru`}
            defaultValue={newPassword}
            rule={`Password Baru`}
            onChange={(e) => handleInput(e, setNewPassword, setErrorNewPassword)}
            error={errorNewPassword}
            rightIcon={<IconButton onClick={(e) => setNewVisible(!newVisible)}>{newVisible ? <VisibilityOff /> : <Visibility />}</IconButton>}
          />
          <Input
            label={`Konfirmasi Password Baru`}
            type={confirmVisible ? "text" : "password"}
            placeholder={`Konfirmasi Password Baru`}
            defaultValue={confirmPassword}
            rule={`Konfirmasi Password Baru`}
            onChange={(e) => handleConfirmInput(e, setConfirmPassword, setErrorConfirmPassword)}
            error={errorConfirmPassword}
            rightIcon={<IconButton onClick={(e) => setConfirmVisible(!confirmVisible)}>{confirmVisible ? <VisibilityOff /> : <Visibility />}</IconButton>}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            disableElevation
            className="btn-btl-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={handleBatalChange}
          >
            Batal
          </Button>

          <Button
            variant="contained"
            disableElevation
            className="btn-simpan-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={(e) => changePassword()}
            disabled={disableBtnSubmit}
          >
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
      <li className="profile-items" ref={ref}>
        <a className="profile-info-wrapper">
          <img
            src={user}
            className="profile-image rounded-circle border-1 border-white"
            // style={{width:"44px", height:"44px"}}
            alt="account"
            onClick={() => props.setChangedProfileOption(!profileOption)}
          />
          <div className="profile-info">
            <span className="profile-name">{profile?.name}</span>
            <br />
            <span className="profile-position" style={{ textTransform: "capitalize" }}>
              {profile?.role}
            </span>
          </div>
        </a>
        <div className={`profile-option ${profileOption ? "" : "element-hide"}`}>
          <div className="profile-info-option">
            <span>{profile?.name}</span>
            <span style={{ textTransform: "capitalize" }}>{profile?.role}</span>
          </div>
          <hr />
          <div className="profile-option-menu">
            <span
              onClick={(e) => {
                setChangePasswordModal(true);
                props.setChangedProfileOption(!profileOption);
              }}
            >
              Ubah Password
            </span>
            <span onClick={(e) => props.logoutUser()}>Keluar</span>
          </div>
        </div>
      </li>
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    profileOption: state.profile.profileOption,
    logoutUserPending: getLogoutUserPending(state.logoutUser),
    logoutUserError: getLogoutUserError(state.logoutUser),
  };
};

const mapDispatchToProps = (dispatch) => {
  // return {
  //   handleProfileOption: (data) => dispatch({type: `CHANGE_PROFILE_OPTION` , data: {profileOption: data}}),
  //   logoutUser,
  // };
};

export default connect(mapStateToProps, { logoutUser, setChangedProfileOption })(Index);
