import { actionSuccess, actionError, actionPending } from "../action_types";
import { download, instance as API } from "../API";
import { toastSuccess, toastError } from "../../../components/toast";
import moment from "moment";

export const UPDATE_REPORT_PAGE = `UPDATE_REPORT_PAGE`;
export const LIST_REPORT_PENDING = `LIST_REPORT_PENDING`;
export const LIST_REPORT_SUCCESS = `LIST_REPORT_SUCCESS`;
export const LIST_REPORT_ERROR = `LIST_REPORT_ERROR`;
export const UPDATE_FILTER_REPORT = `UPDATE_FILTER_REPORT`;
export const UPDATE_SEARCH_REPORT = `UPDATE_SEARCH_REPORT`;
export const DETAIL_REPORT_PENDING = `DETAIL_REPORT_PENDING`;
export const DETAIL_REPORT_SUCCESS = `DETAIL_REPORT_SUCCESS`;
export const DETAIL_REPORT_ERROR = `DETAIL_REPORT_ERROR`;
export const DOWNLOAD_REPORT_PENDING = `DOWNLOAD_REPORT_PENDING`;
export const DOWNLOAD_REPORT_SUCCESS = `DOWNLOAD_REPORT_SUCCESS`;
export const DOWNLOAD_REPORT_ERROR = `DOWNLOAD_REPORT_ERROR`;
export const LIST_PERIOD_PENDING = `LIST_PERIOD_PENDING`;
export const LIST_PERIOD_SUCCESS = `LIST_PERIOD_SUCCESS`;
export const LIST_PERIOD_ERROR = `LIST_PERIOD_ERROR`;
export const LIST_GROWER_PENDING = `LIST_GROWER_PENDING`;
export const LIST_GROWER_SUCCESS = `LIST_GROWER_SUCCESS`;
export const LIST_GROWER_ERROR = `LIST_GROWER_ERROR`;

const REPORT_URL = `/v1/report/layer`;
const DOWNLOAD_REPORT_URL = "/v1/report/layer/download";
const PERIOD_URL = `/v1/period`;
const STANDART_GROWER_URL = `/v1/report/standart_grower`;

export const listReport =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_REPORT_PENDING));
    API.get(REPORT_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_REPORT_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_REPORT_PAGE, param.page));
        }
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_REPORT_ERROR, error));
        toastError(error, "Gagal Mengambil Data Report, ");
      });
  };

export const filterReport =
  (param = {}) =>
  (dispatch) => {
    const farm = param.farm ?? {};
    const kandang = param.kandang ?? {};
    const period = param.period ?? {};
    const ip = param.ip ?? null;
    const tanggal_mulai = param.tanggal_mulai
      ? moment(param.tanggal_mulai)?.format("DD-MM-YYYY")
      : null;
    const tanggal_akhir = param.tanggal_akhir
      ? moment(param.tanggal_akhir)?.format("DD-MM-YYYY")
      : null;
    const tanggal_mulai_pullet_in = param.tanggal_mulai_pullet_in
      ? moment(param.tanggal_mulai_pullet_in)?.format("DD-MM-YYYY")
      : null;
    const tanggal_akhir_pullet_in = param.tanggal_akhir_pullet_in
      ? moment(param.tanggal_akhir_pullet_in)?.format("DD-MM-YYYY")
      : null;
    dispatch(
      actionSuccess(UPDATE_FILTER_REPORT, {
        farm,
        kandang,
        period,
        ip,
        tanggal_mulai,
        tanggal_akhir,
        tanggal_mulai_pullet_in,
        tanggal_akhir_pullet_in,
      }),
    );
  };

export const searchReport =
  (param = "") =>
  (dispatch) => {
    dispatch(actionSuccess(UPDATE_SEARCH_REPORT, param));
  };

export function detailReport(id = null) {
  return (dispatch) => {
    API.get(`${REPORT_URL}/detail?id=${id}`, { params: {} })
      .then((res) => {
        dispatch(actionSuccess(DETAIL_REPORT_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_REPORT_ERROR, error));
        toastError(error?.response?.data?.message, "");
      });
  };
}

export const downloadReport =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
    download
      .get(DOWNLOAD_REPORT_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS));
        const href = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Report Layer.xlsx`);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        dispatch(actionError(DOWNLOAD_REPORT_ERROR, error));
        toastError("Gagal mengunduh report");
      });
  };

export const listPeriod =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_PERIOD_PENDING));
    API.get(PERIOD_URL, { params: param })
      .then((res) => {
        const listPeriod = [];
        res.data.data.map((v) => {
          listPeriod.push({
            value: v.id,
            label: `Periode ${moment(v?.tanggal_mulai)?.format("DD-MM-YYYY")} ${
              v?.tanggal_selesai
                ? `- ${moment(v?.tanggal_selesai)?.format("DD-MM-YYYY")}`
                : ""
            }`,
          });
        });
        dispatch(actionSuccess(LIST_PERIOD_SUCCESS, listPeriod));
      })
      .catch((error) => {
        dispatch(actionError(LIST_PERIOD_ERROR, error));
      });
  };

export const listStandarGrower =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_GROWER_PENDING));
    API.get(STANDART_GROWER_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_GROWER_SUCCESS, res.data));
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_GROWER_ERROR, error));
        toastError(error, "Gagal Mengambil Data Standart Grower, ");
      });
  };
