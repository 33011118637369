import React from "react";
import { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Container, Navbar } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import ModalEditUser from "./ModalEditUser";
import ModalTambahUser from "./ModalTambahUser";
import ModalDetailUser from "./ModalDetailUser";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalFilter from "./ModalFilters";
import ModalResetPassword from "./ModalResetPassword";
import SearchBoxUser from "../../components/button/buttonSearch";
import DetailButton from "../../components/commons/table/buttonAction";
import {
  listUser,
  filterUser,
  deleteUser,
  searchUser,
} from "../../store/actions/user";
import PageUser from "../../components/commons/table/pagination";
import {
  getListUser,
  getListUserPending,
  getListUserError,
  getPageData,
  getFilters,
  getSearchText,
} from "../../store/reducers/user";
import { getUser } from "../../utils";
import _ from "lodash";

const Index = (props) => {
  const profile = getUser();
  const {
    pageData,
    listUserSuccess,
    listUserPending,
    listUserError,
    filters,
    searchText,
  } = props;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalTambah, setShowModalTambah] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [filterStatus, setFilterStatus] = useState({});
  const [editData, setEditData] = useState(null);
  const [totalData, setTotalData] = useState(0);

  const handleEditButtonClick = (data) => {
    setEditData(data);
    setShowModalEdit(true);
  };
  // const handleDetailButtonClick = () => {
  //   handleMoreButtonClose();
  //   setShowModalDetail(true);
  // };
  const handleDeleteButtonClick = (data) => {
    setEditData(data);
    setShowModalDelete(true);
  };
  const handleResetPasswordButtonClick = (data) => {
    setEditData(data);
    setShowModalResetPassword(true);
  };

  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  useEffect(() => {
    if (listUserSuccess) setTotalData(listUserSuccess?.length);
  }, [listUserSuccess]);

  useEffect(() => {
    dispatch(listUser());
  }, [dispatch]);

  useEffect(() => {}, [userData]);

  useEffect(() => {
    props.listUser({
      length: pageData.lengthItem,
      page: pageData.page,
      search: searchText === "" ? null : searchText,
      status: filters.status.value,
    });
  }, [filters]);

  useEffect(() => {
    props.listUser();
  }, []);


  const handleSearchChange = _.debounce((e) => {
    props.searchUser(e.target.value);
    if (e?.target?.value !== "" && e?.target?.value?.length < 2) return;
    props.listUser({
      length: pageData.lengthItem,
      page: 1,
      search: e.target.value === "" ? null : e.target.value,
      status: filters.status.value,
    });
  }, 500);

  const userLists = (data, error) => {
    return error ? (
      <TableRow>
        <TableCell colSpan={5} align="center">
          Gagal mengambil data user
        </TableCell>
      </TableRow>
    ) : data.length === 0 ? (
      <TableRow>
        <TableCell colSpan={5} align="center">
          Data User tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data.map((row, i) => (
        <TableRow key={i}>
          <TableCell align="center">{i + 1}</TableCell>
          <TableCell>{row.username}</TableCell>
          {row.full_name.length > 20 ? (
            <TableCell>{row.full_name.slice(0, 20) + "..."}</TableCell>
          ) : (
            <TableCell>{row.full_name}</TableCell>
          )}
          <TableCell>{row.phone_number}</TableCell>
          <TableCell>{!row.email ? "-" : row.email}</TableCell>
          {row.job_position ? (
            <TableCell>
              {row.job_position === "surveyor" ? "Kepala Kandang" : "Admin"}
            </TableCell>
          ) : (
            ""
          )}
          <TableCell>
            <div
              className={
                row.status === "active"
                  ? "btn btn-status btn-sm bg-success rounded"
                  : "btn btn-status btn-sm bg-warning rounded w-100"
              }>
              {row.status === "active" ? "Aktif" : "Non Aktif"}
            </div>
          </TableCell>
          <TableCell align="center">
            <DetailButton>
              <MenuItem
                test={row.id}
                onClick={(e) => handleEditButtonClick(row)}
                sx={styleMenuItem}>
                Edit
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem
                test={row.id}
                onClick={(e) => handleDeleteButtonClick(row)}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}>
                Hapus
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem
                test={row.id}
                onClick={(e) => handleResetPasswordButtonClick(row)}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}>
                Reset Password
              </MenuItem>
            </DetailButton>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Container>
      {editData ? (
        <>
          <ModalEditUser
            id={`edit-user-modal`}
            show={showModalEdit}
            setShow={setShowModalEdit}
            data={editData}
          />
          <ModalDetailUser
            show={showModalDetail}
            setShow={setShowModalDetail}
            data={editData}
            edit={setShowModalEdit}
          />
          <ModalDeleteUser
            show={showModalDelete}
            setShow={setShowModalDelete}
            data={editData}
          />
          <ModalResetPassword
            show={showModalResetPassword}
            setShow={setShowModalResetPassword}
            data={editData}
          />
        </>
      ) : (
        ""
      )}
      <ModalTambahUser show={showModalTambah} setShow={setShowModalTambah} />
      <ModalFilter show={showModalFilter} setShow={setShowModalFilter} />
      <Navbar>
        <Navbar.Brand>
          <h1 className="judul-header">User Management</h1>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <SearchBoxUser handleChange={handleSearchChange} value={searchText} />
          <Button
            color="secondary"
            variant="outlined"
            disableElevation
            sx={{
              borderRadius: 28,
              paddingY: "10px",
              textTransform: "capitalize",
              color: "black",
              fontWeight: "800",
            }}
            size="large"
            className={`filters`}
            onClick={(e) => setShowModalFilter(!showModalFilter)}>
            {filters.status.value ? <span className="dot"></span> : ``}
            Filter
          </Button>
          <div className="custom-devider" />
          <Button
            color="primary"
            variant="contained"
            disableElevation
            startIcon={<AddIcon />}
            sx={{
              borderRadius: 28,
              paddingY: "10px",
              textTransform: "capitalize",
              fontWeight: "800",
              borderRadius: "24px",
              width: "163px",
              height: "48px",
              "&:hover": {
                background: "#5269D9",
                boxShadow: "0px 4px 14px rgba(28, 55, 190, 0.75)",
                borderRadius: "24px",
              },
            }}
            onClick={() => setShowModalTambah(true)}>
            Tambah User
          </Button>
        </Navbar.Collapse>
      </Navbar>
      <Divider />
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "800", paddingY: "16px" }}>
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NO.PELANGGAN</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NO.HP</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>EMAIL</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>ROLE</TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>
              STATUS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listUserPending ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Mengambil data user ...
              </TableCell>
            </TableRow>
          ) : (
            userLists(listUserSuccess, listUserError)
          )}
        </TableBody>
      </Table>
      {/* <Page pageData={pageData} /> */}
      <PageUser
        totalData={totalData}
        pageData={pageData}
        handleClickPage={(page) => {
          if (page) {
            props.listUser({
              length: pageData.lengthItem,
              page: page,
              search: searchText === "" ? null : searchText,
              status: filters.status.value,
            });
          }
        }}
      />
      <div style={{ marginBottom: "210px" }}></div>
    </Container>
  );
};

const mapStateToProps = ({ listUser }) => {
  return {
    listUserSuccess: getListUser(listUser),
    listUserPending: getListUserPending(listUser),
    listUserError: getListUserError(listUser),
    pageData: getPageData(listUser),
    filters: getFilters(listUser),
    searchText: getSearchText(listUser),
  };
};

export default connect(mapStateToProps, {
  listUser,
  filterUser,
  deleteUser,
  searchUser,
})(Index);
