export const LOCAL_STORAGE_KEY = "data";
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const MonthArr = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

export const numberWithCommas = (x) => {
  return x
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const convertDate = (date) => {
  const exploded = date.toLocaleString("fr-FR", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
  });
  return exploded;
};

export const convertTime = (date) => {
  return `${date.getHours().toString().padStart(1)}:${date.getMinutes().toString().padStart(1)}`;
};

export const toPersen = (num) => {
  if (isNaN(parseFloat(num))) return 0;
  return Intl.NumberFormat("en-US").format(parseFloat(num).toFixed(2)).toString().replace(",", " ").replace(".", ",").replace(" ", ".");
};

export const mapAndSortData = (data, keys) => {
  if (!data || !data.labels) return [];

  let mappedData = data.labels.map((label, index) => {
    let mappedItem = { label: label };
    keys.forEach(key => {
      mappedItem[key] = data[key][index];
    });
    return mappedItem;
  });

  mappedData.sort((a, b) => Number(a.label) - Number(b.label));
  return mappedData;
};

export const statusOptions = [
  { value: "Kosong", label: "Kosong" },
  { value: "Persiapan Kandang", label: "Persiapan Kandang" },
  { value: "Cuci Kandang", label: "Cuci Kandang" },
  { value: "Chick In", label: "Chick In" },
  { value: "Pemeliharaan", label: "Pemeliharaan"},
  { value: "Panen", label: "Panen" },
];

export const pemanasOptions = [
  { value: "Batu Bara", label: "Batu Bara" },
  { value: "Elpiji", label: "Elpiji" },
];

export const jenisAyamOptions = [
  { value: "Broiler", label: "Broiler"},
  { value: "Layer", label: "Layer"},
];

export const indikatorGrafikBodyWeight = [
  {
    imageUrl: "../img/thickOldBlue.jpg",
    titleIndikator: "Diff. in Act. To Avg. BW (%)",
  },
  {
    imageUrl: "../img/lineRed.jpg",
    titleIndikator: "Body Weight Actual",
  },
  {
    imageUrl: "../img/lineDashedOrange.svg",
    titleIndikator: "Body Weight Standar",
  },
];

export const indikatorGrafikUniformity = [
  {
    imageUrl: "../img/lineDashedOrange.svg",
    titleIndikator: "Body Weight Standar",
  },
  {
    imageUrl: "../img/lineRed.jpg",
    titleIndikator: "Body Weight Actual",
  },
  {
    imageUrl: "../img/lineSkyBlue.jpg",
    titleIndikator: "Uniformity (%) Std",
  },
  {
    imageUrl: "../img/lineOldBlue.jpg",
    titleIndikator: "Uniformity (%) Actual",
  },
  {
    imageUrl: "../img/lineBlack.jpg",
    titleIndikator: "% CV",
  },
];

export const indikatorGrafikWeeklyMortality = [
  {
    imageUrl: "../img/thickLineGreen.jpg",
    titleIndikator: "Body Weight Actual",
  },
  {
    imageUrl: "../img/thickLineOrange.jpg",
    titleIndikator: "Body Weight Std",
  },
  {
    imageUrl: "../img/lineOldBlue.jpg",
    titleIndikator: "Weekly Mortality",
  },
];

export const indikatorGrafikFeedConsumption = [
  {
    imageUrl: "../img/lineRed.jpg",
    titleIndikator: "Berat Feed",
  },
  {
    imageUrl: "../img/lineOrange.jpg",
    titleIndikator: "Feed Intake",
  },
];

export const indikatorGrafikCumMortality = [
  {
    imageUrl: "../img/lineblue.jpg",
    titleIndikator: "Mort. Cum. Actual (%)",
  },
  {
    imageUrl: "../img/lineDashedYellow.jpg",
    titleIndikator: "Mort. Cum. Std (%)",
  },
];

export const indikatorGrafikWaterConsumption = [
  {
    imageUrl: "../img/lineOldBlue.jpg",
    titleIndikator: "Water Actual",
  },
  {
    imageUrl: "../img/lineSkyBlue.jpg",
    titleIndikator: "Water to Egg Ratio",
  },
];

export const indikatorGrafikWeightGain = [
  {
    imageUrl: "../img/thickLineGreen.jpg",
    titleIndikator: "Weekly Gain Actual",
  },
  {
    imageUrl: "../img/thickLineOrange.jpg",
    titleIndikator: "Weekly Gain Std",
  },
];

export const infoGraphDashboard = {
  CumulativeMortality: "Peternak dapat memantau kematian kumulatif ternak, membandingkannya dengan standar industri, dan membantu mengidentifikasi masalah kesehatan atau manajemen yang perlu segera ditangani",
  FeedConsumption: "Peternak dapat memantau jumlah pakan yang diberikan dan dikonsumsi, mengevaluasi efisiensi pemberian pakan, serta mengidentifikasi pola konsumsi dari waktu ke waktu",
  WaterConsumption: "Peternak dapat memantau konsumsi air oleh ayam, mengevaluasi efisiensi penggunaan air dalam produksi telur melalui water to egg ratio, dan mengidentifikasi pola konsumsi serta produksi telur dari waktu ke waktu",
  BodyWeightMortality: "Grafik ini membantu peternak memantau pertumbuhan ayam, mengidentifikasi tren mortalitas, dan menilai kesehatan serta performa kawanan secara keseluruhan",
  BodyWeight: "Peternak dapat melihat pertumbuhan ayam dibandingkan standar umur. Grafik ini membantu evaluasi kinerja, identifikasi masalah awal, optimasi pakan, dan perencanaan berdasarkan data historis",
  BodyWeightUniformity: "Peternak dapat memantau pertumbuhan dan keseragaman ayam, menilai kinerja, mengidentifikasi masalah dini, dan membantu optimasi pakan serta perencanaan jangka panjang",
  WeeklyGain: "Peternak dapat memantau pertumbuhan ayam mingguan, membandingkan dengan standar, dan mengidentifikasi masalah awal. Mengoptimalkan pakan dan manajemen untuk hasil yang lebih baik"
}