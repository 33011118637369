import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, ListGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ListItem from "../../components/commons/listGroup/listItem";
import { numberWithCommas } from "../../utils/constants";

import { detailReport, listStandarGrower } from "../../store/actions/report";
import {
  getDetailReportSuccess,
  getDetailReportPending,
  getDetailReportError,
  getDataStandartGrower,
  getDataStandartGrowerError,
  getDataStandartGrowerPending,
} from "../../store/reducers/report";

function DetailReport(props) {
  const {
    detailReportSuccess,
    detailReportPending,
    detailReportError,
    listGrowerSuccess,
  } = props;

  const history = useHistory();

  useEffect(() => {
    props.detailReport(props.match.params.id);
  }, []);

  const dispatch = useDispatch();
  const standarGrower = useSelector((state) => state.ip);

  useEffect(() => {
    props.listStandarGrower();
  }, []);

  useEffect(() => {
    dispatch(listStandarGrower());
  }, [dispatch]);

  useEffect(() => {}, [standarGrower]);

  const parseUmur = parseInt(detailReportSuccess.umur_mingguan);
  const min =
    listGrowerSuccess?.data[detailReportSuccess.umur_mingguan]?.body_weight;
  const max = listGrowerSuccess?.data[parseUmur + 1]?.body_weight;

  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="d-flex align-items-center justify-content-between mb-5">
        <div>
          <ArrowBack
            sx={{ cursor: "pointer" }}
            onClick={() => history.goBack()}
          />
          <span
            className="text-extra-bold"
            style={{
              fontSize: "24px",
              fontFamily: "PlusJakartaSans-Bold",
              marginLeft: "26px",
            }}>
            Detail Report
          </span>
        </div>
      </div>
      <div>
        {detailReportPending ? (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: "50%" }}>
            Mengambil data report...
          </div>
        ) : detailReportError ? (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: "50%" }}>
            Gagal mengambil data report...
          </div>
        ) : (
          <div className="mb-5">
            <ListGroup className="cek" style={{ borderRadius: "12px" }}>
              <ListItem
                label="Periode"
                value={
                  detailReportSuccess == null
                    ? ""
                    : moment(detailReportSuccess.period?.tanggal_mulai)?.format(
                        "D MMMM YYYY",
                      )
                }
              />
              <ListItem
                label="Tanggal Pullet In"
                value={
                  detailReportSuccess == null
                    ? ""
                    : moment(
                        detailReportSuccess.period?.tanggal_pullet_in,
                      )?.format("D MMMM YYYY")
                }
              />
              <ListItem
                label="Tanggal Report"
                value={
                  detailReportSuccess == null
                    ? ""
                    : moment(detailReportSuccess.tanggal)?.format("D MMMM YYYY")
                }
              />
              <ListItem
                label="Nama Farm"
                value={
                  detailReportSuccess == null
                    ? ""
                    : detailReportSuccess.nama_farm
                }
              />
              <ListItem
                label="Barcode Kandang"
                value={
                  detailReportSuccess == null ? "" : detailReportSuccess.barcode
                }
              />
              <ListItem
                label="Surveyor"
                value={
                  detailReportSuccess == null
                    ? ""
                    : detailReportSuccess.surveyor_fullname
                }
              />
              <ListItem
                label="Pullet Masuk"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.jumlah_masuk + " Ekor"),
                      )
                }
              />
              <ListItem
                label="Pullet Keluar"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.jumlah_keluar + " Ekor"),
                      )
                }
              />
              <ListItem
                label="Jumlah Kematian"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.jumlah_mati + " Ekor"),
                      )
                }
              />
              <ListItem
                label="Jumlah Afkir"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.jumlah_afkir + " Ekor"),
                      )
                }
              />
              <ListItem
                label="Nama Feed"
                value={
                  detailReportSuccess == null
                    ? ""
                    : detailReportSuccess.nama_pakan
                }
              />
              <ListItem
                label="Kode Produksi"
                value={
                  detailReportSuccess == null
                    ? ""
                    : detailReportSuccess.kode_produksi_pakan
                }
              />
              <ListItem
                label="Water"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(String(detailReportSuccess.water + " L"))
                }
              />
              <ListItem
                label="Berat Feed"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.berat_pakan + " Kg"),
                      )
                }
              />
              <ListItem
                label="Jumlah Telur Normal"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(
                          detailReportSuccess.jumlah_produksi_telur + " Butir",
                        ),
                      )
                }
              />
              <ListItem
                label="Berat Telur Normal"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(
                          detailReportSuccess.berat_produksi_telur + " Kg",
                        ),
                      )
                }
              />
              <ListItem
                label="Jumlah Telur Abnormal"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(
                          detailReportSuccess.jumlah_produksi_abnormal +
                            " Butir",
                        ),
                      )
                }
              />
              <ListItem
                label="Berat Telur Abnormal"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(
                          detailReportSuccess.berat_produksi_abnormal + " Kg",
                        ),
                      )
                }
              />
              <ListItem
                label="WOA (Week of Age)"
                value={
                  detailReportSuccess == null
                    ? ""
                    : detailReportSuccess.umur_mingguan + " Minggu"
                }
              />
              <ListItem
                label="Mortalitas"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.mortalitas + "%"),
                      )
                }
              />
              <ListItem
                label="Daya Hidup"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.daya_hidup + "%"),
                      )
                }
              />
              <ListItem
                label="Hen Day"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.hen_day + "%"),
                      )
                }
              />
              <ListItem
                label="Egg Weight"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.berat_telur + " Gram"),
                      )
                }
              />
              <ListItem
                label="Egg Mass"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.egg_mass + " Gram"),
                      )
                }
              />
              <ListItem
                label="Feed Intake"
                value={
                  detailReportSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailReportSuccess.feed_intake + " Gram"),
                      )
                }
              />
              <ListItem
                label="FCR"
                value={
                  detailReportSuccess == null ? "" : detailReportSuccess.fcr
                }
              />
              <ListItem
                label="Indikator IP"
                value={
                  <div className="d-flex">
                    <div
                      className={
                        detailReportSuccess.ip <= min - 0.1 * min
                          ? "btn-status btn-sm bg-danger rounded"
                          : detailReportSuccess.ip < min &&
                            detailReportSuccess.ip >= min - 0.1 * min
                          ? "btn-status btn-sm bg-warning rounded"
                          : detailReportSuccess.ip >= min &&
                            detailReportSuccess.ip <= max
                          ? "btn-status btn-sm bg-success rounded"
                          : "btn-status btn-sm bg-dark rounded"
                      }>
                      {detailReportSuccess?.ip ?? ""}
                    </div>
                    <div className="p-2">{`(${min}-${max - 1})`}</div>
                  </div>
                }
              />
              <ListItem
                label="Catatan"
                value={
                  detailReportSuccess == null
                    ? "-"
                    : detailReportSuccess.catatan
                }
              />
            </ListGroup>
            <div className="d-flex flex-row-reverse">
              <Button
                variant="outlined"
                disableElevation
                className="btn-kembali-farm"
                onClick={(e) => history.goBack()}>
                Kembali
              </Button>
            </div>
          </div>
        )}
      </div>
      <div></div>
    </Container>
  );
}

const mapStateToProps = ({ detailReport, listIP }) => {
  return {
    detailReportSuccess: getDetailReportSuccess(detailReport),
    detailReportPending: getDetailReportPending(detailReport),
    detailReportError: getDetailReportError(detailReport),
    listGrowerSuccess: getDataStandartGrower(listIP),
    listGrowerPending: getDataStandartGrowerPending(listIP),
    listGrowerError: getDataStandartGrowerError(listIP),
  };
};

export default connect(mapStateToProps, { detailReport, listStandarGrower })(
  DetailReport,
);
