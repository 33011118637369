import { FormCheck } from "react-bootstrap";

const Index = (props) => {
  const handleRadio = (e) => {
    props.setStatus(e.target.value);
  };

  return (
    <div className="form-group radio-wrapper">
      <div className="form-label">Status</div>
      <div className="radio-value">
        <div className="radio-item">
          <div>
            <input
              value={"active"}
              name={"status"}
              checked={props.status === "active"}
              type={"radio"}
              onChange={(e) => handleRadio(e)}
            />
          </div>
          <span>Aktif</span>
        </div>
        <div className="radio-item">
          <div>
            <input
              value={"inactive"}
              name={"status"}
              checked={props.status === "inactive"}
              type={"radio"}
              onChange={(e) => handleRadio(e)}
            />
          </div>
          <span>Nonaktif</span>
        </div>
      </div>
    </div>
  );
};

export default Index;
