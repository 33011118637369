import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import SelectComponent from "../../components/commons/forms/select";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getListRole,
  getListRoleError,
  getListRolePending,
} from "../../store/reducers/role";
import { listRole } from "../../store/actions/role";
import { listUser, filterUser } from "../../store/actions/user";

import { InfoRounded } from "@mui/icons-material";
import {
  getListUser,
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/user";

const ModalFilter = (props) => {
  const { listRoleSuccess, filters, pageData, searchText } = props;
  const statusOptions = [
    { value: `active`, label: "Aktif" },
    { value: `inactive`, label: "Nonaktif" },
  ];
  const [filterStatus, setFilterStatus] = useState({});

  // useEffect(() => {
  //   props.listRole();
  // }, []);

  const submitForm = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterUser({ status: filterStatus });
    }, 200);
  };

  const resetButton = () => {
    props.setShow(false);
    props.filterUser({ status: null });
    // setFilterRole({});
    setFilterStatus({});
  };

  const handleClose = () => {
    setTimeout(() => {
      props.setShow(false);
      // setFilterRole({});
      setFilterStatus({});
    }, 100);
  };


  return (
    <div>
      <Modal size={"md"} onHide={handleClose} show={props.show} centered>
        <ModalHeader closeButton>Filter</ModalHeader>
        <ModalBody>
          <div className="info">
            <InfoRounded color="inherit" fontSize="medium" />
            <div className="info-text">Pilih data yang ingin difilter</div>
          </div>
          {/* <div className="select-wrapper">
            <SelectComponent
              options={listRoleSuccess ? listRoleSuccess : {}}
              label={"Role"}
              isAsync={true}
              asyncUrl={"/v1/master_data/role"}
              onChange={(e) => {
                setFilterRole(e);
              }}
              value={
                filterRole === null
                  ? { value: "", label: "Semua Role" }
                  // : filters.role.value && !filterRole.value
                  : filters.role.value
                  ? { value: filters.role.value, label: filters.role.label }
                  : filterRole.value
                  ? { value: filterRole.value, label: filterRole.label }
                  : { value: "", label: "Semua Role" }
              }
            />
          </div> */}

          <div className="select-wrapper">
            <SelectComponent
              options={statusOptions}
              label={"Status"}
              isAsync={true}
              asyncUrl={"/v1/user"}
              onChange={(e) => {
                setFilterStatus(e);
              }}
              value={
                filterStatus === null
                  ? { value: "", label: "Semua Status" }
                  : filters.status.value && !filterStatus.value
                  ? { value: filters.status.value, label: filters.status.label }
                  : filterStatus.value
                  ? { value: filterStatus.value, label: filterStatus.label }
                  : { value: "", label: "Semua Status" }
              }
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: `space-between` }}>
          {/* <Button style={{ float: "left" }} color="light" title="Reset" onClick={resetButton} />
          <div>
            <Button color="light" title="Batal" onClick={handleClose} />
            <Button title="Terapkan" onClick={(e) => submitForm()} light />
          </div> */}
          <div className="container">
            <div className="row">
              <div className="col-md-4 ps-2">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={resetButton}>
                  Reset
                </Button>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-end">
                  <Button
                    variant="outlined"
                    disableElevation
                    className="btn-btl-ubah-pass"
                    style={{
                      right: "2px",
                    }}
                    onClick={handleClose}>
                    Batal
                  </Button>

                  <Button
                    variant="contained"
                    disableElevation
                    className="btn-simpan-ubah-pass"
                    style={{
                      right: "2px",
                    }}
                    onClick={() => submitForm()}>
                    Terapkan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listRole, listUser }) {
  return {
    listRolePending: getListRolePending(listRole),
    listRoleSuccess: getListRole(listRole),
    listRoleError: getListRoleError(listRole),
    filters: getFilters(listUser),
    pageData: getPageData(listUser),
    searchText: getSearchText(listUser),
  };
}

export default connect(mapStateToProps, { listRole, listUser, filterUser })(
  ModalFilter,
);
