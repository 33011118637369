import {
    UPDATE_KANDANG_PAGE,
    LIST_KANDANG_PENDING,
    LIST_KANDANG_SUCCESS,
    LIST_KANDANG_ERROR,
    UPDATE_FILTER_KANDANG,
    UPDATE_SEARCH_KANDANG,
    LIST_SURVEYOR_PENDING,
    LIST_SURVEYOR_SUCCESS,
    LIST_SURVEYOR_ERROR,
    LIST_AREA_PENDING,
    LIST_AREA_SUCCESS,
    LIST_AREA_ERROR,
    LIST_GROUP_PENDING,
    LIST_GROUP_SUCCESS,
    LIST_GROUP_ERROR,
    EDIT_KANDANG_PENDING,
    EDIT_KANDANG_SUCCESS,
    EDIT_KANDANG_ERROR,
    DELETE_KANDANG_PENDING,
    DELETE_KANDANG_SUCCESS,
    DELETE_KANDANG_ERROR,
    DETAIL_KANDANG_PENDING,
    DETAIL_KANDANG_SUCCESS,
    DETAIL_KANDANG_ERROR

} from "../../actions/kandang";

const initialState = {
    listKandang: [],
    detailKandang:{},
    pending: false,
    error: null,
    pageData: {
      total: null,
      lengthItem: 10,
      page: 1,
    },
    filters: {
      nama_farm: {},
      surveyor: {},
      group_id: {},
      status: {}
    },
    search: ''
}

export const listKandang = (state = initialState, action) => {
    switch (action.type) {
      case LIST_KANDANG_PENDING:
        return {
          ...state,
          pending: true,
        };
      case LIST_KANDANG_SUCCESS:
        return {
          ...state,
          pending: false,
          listKandang: action.data.data,
          pageData: {
            ...state.pageData,
            total: action.data.total
          },
          error: false
        };
      case LIST_KANDANG_ERROR:
        return {
          ...state,
          pending: false,
          pageData: {
            ...state.pageData,
            total: 0
          },
          error: action.error
        }
      case UPDATE_KANDANG_PAGE:
        return {
          ...state,
          pageData:{
            ...state.pageData,
            page: action.data
          }
        }
      case UPDATE_FILTER_KANDANG:
        return {
          ...state,
          filters: {
            nama_farm: action.data.nama_farm,
            surveyor: action.data.surveyor,
            group_id: action.data.group_id,
            status: action.data.status
          }
        }
        case UPDATE_SEARCH_KANDANG:
          return {
            ...state,
            search: action.data
          }
      default:
        return state;
    }
};

export const listSurveyor = (state = {...initialState , listSurveyor: null} , action) => {
  switch(action.type){
      case LIST_SURVEYOR_PENDING:
          return {
              ...state ,
              pending: true,
          }
      case LIST_SURVEYOR_SUCCESS:
          return {
              ...state,
              pending: false,
              listSurveyor: action.data,
              error: false
          }
      case LIST_SURVEYOR_ERROR:
          return {
              ...state ,
              pending: false,
              error: action.error
          }
      default:
          return state;
  }
}

export const listArea = (state = {...initialState , listArea: null} , action) => {
  switch(action.type){
      case LIST_AREA_PENDING:
          return {
              ...state ,
              pending: true,
          }
      case LIST_AREA_SUCCESS:
          return {
              ...state,
              pending: false,
              listArea: action.data,
              error: false
          }
      case LIST_AREA_ERROR:
          return {
              ...state ,
              pending: false,
              error: action.error
          }
      default:
          return state;
  }
}

export const listGroup = (state = {...initialState , listGroup: null} , action) => {
  switch(action.type){
      case LIST_GROUP_PENDING:
          return {
              ...state ,
              pending: true,
          }
      case LIST_GROUP_SUCCESS:
          return {
              ...state,
              pending: false,
              listGroup: action.data,
              error: false
          }
      case LIST_GROUP_ERROR:
          return {
              ...state ,
              pending: false,
              error: action.error
          }
      default:
          return state;
  }
}

export const editKandang = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_KANDANG_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_KANDANG_SUCCESS:
      return {
        ...state,
        pending: false,
        error: false,
      };
    case EDIT_KANDANG_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const detailKandang = (state = initialState, action) => {
  switch (action.type) {
    case DETAIL_KANDANG_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_KANDANG_SUCCESS:
      return {
        ...state,
        pending: false,
        detailKandang: action.data,
        error: false
      };
    case DETAIL_KANDANG_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export function deleteKandang(state = initialState ,action) {
  switch (action.type) {
    case DELETE_KANDANG_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_KANDANG_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteFarm: action.data,
        error: false
      };
    case DELETE_KANDANG_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}


export const getListKandang             = state => state.listKandang
export const getPageData                = state => state.pageData
export const getFilters                 = state => state.filters
export const getSearchText              = state => state.search
export const getListKandangPending      = state => state.pending
export const getListKandangError        = state => state.error
export const getListSurveyor            = state => state.listSurveyor
export const getListSurveyorError       = state => state.error
export const getListSurveyorPending     = state => state.pending
export const getListArea                = state => state.listArea
export const getListAreaError           = state => state.error
export const getListAreaPending         = state => state.pending
export const getListGroup               = state => state.listGroup
export const getListGroupError          = state => state.error
export const getListGroupPending        = state => state.pending
export const getEditKandangPending      = state => state.pending
export const getEditKandangError        = state => state.error
export const getDetailKandangSuccess    = (state) => state.detailKandang;
export const getDetailKandangPending    = (state) => state.pending;
export const getDetailKandangError      = (state) => state.error;
export const getDeleteKandangPending    = (state) => state.pending;
export const getDeleteKandangError      = (state) => state.error;