import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { Modal } from "react-bootstrap";
import { InfoRounded } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import SelectComponent from "../../../components/commons/forms/select";
import { toastError } from "../../../components/toast";
import { downloadReportDocMingguan } from "../../../store/actions/doc";
import {
  getDownloadDocMingguanError,
  getDownloadDocMingguanPending,
} from "../../../store/reducers/doc";

export const ModalUnduhReportDoc = (props) => {
  const { downloadDocMingguanPending, idDocSurvey } = props;
  const [disabled, setDisabled] = useState(false);
  const [idSurvey, setIdSurvey] = useState({});
  const [listIdSurvey, setListIdDocSurvey] = useState({});

  const handleUnduhButton = () => {
    if (!idDocSurvey) {
      toastError("ID Doc Survey harus diisi");
      return;
    }

    const param = {
      id_doc_survey: idDocSurvey?.value,
    };

    props.downloadReportDocMingguan(param);
  };

  const handleClose = () => {
    setIdSurvey(null);
    props.setShow(false);
  };

  useEffect(() => {
    if (downloadDocMingguanPending) setDisabled(true);
    if (!downloadDocMingguanPending) {
      handleClose();
    }
  }, [downloadDocMingguanPending]);

  return (
    <Modal size={"md"} show={props.show} onHide={handleClose} centered>
      <Modal.Header closeButton>Unduh Report</Modal.Header>
      <Modal.Body>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin diunduh</div>
        </div>
        <div className="select-wrapper">
          <SelectComponent
            disabled={true}
            options={listIdSurvey}
            label={"Id Survey DOC"}
            onChange={(e) => {
              setIdSurvey(e);
            }}
            customParseData={(item) => ({
              label: item?.id_doc_survey,
              value: item?.id_doc_survey,
            })}
            value={
              idDocSurvey && idDocSurvey?.value
                ? { value: idDocSurvey.value, label: idDocSurvey.label }
                : { value: "", label: "Semua Id DOC Survey" }
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          disableElevation
          className="btn-btl-ubah-pass"
          style={{
            right: "2px",
          }}
          onClick={handleClose}>
          Batal
        </Button>
        <Button
          variant="contained"
          disableElevation
          className="btn-simpan-ubah-pass"
          style={{
            right: "2px",
          }}
          onClick={handleUnduhButton}
          disabled={disabled}>
          {disabled ? (
            <CircularProgress size={18} color="inherit" disableShrink />
          ) : (
            "Unduh"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ downloadReportDocMingguan }) => {
  return {
    downloadDocMingguanError: getDownloadDocMingguanError(
      downloadReportDocMingguan,
    ),
    downloadDocMingguanPending: getDownloadDocMingguanPending(
      downloadReportDocMingguan,
    ),
  };
};

const mapDispatchToProps = {
  downloadReportDocMingguan,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalUnduhReportDoc);
