import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import SelectComponent from "../../../components/commons/forms/select";
import { connect } from "react-redux";
import { listDocMingguan, filterDocMingguan } from "../../../store/actions/doc";
import { listFarm } from "../../../store/actions/farm";
import { getListFarm } from "../../../store/reducers/farm";
import { listKandang } from "../../../store/actions/kandang";
import { getListKandang } from "../../../store/reducers/kandang";
import { InfoRounded } from "@mui/icons-material";
import {
  getFiltersMingguan,
  getListDocMingguan,
  getPageDataMingguan,
  getSearchTextDocMingguan,
} from "../../../store/reducers/doc";
import { useState } from "react";
import { useEffect } from "react";
import { listStandarGrower } from "../../../store/actions/report";
import { getDataStandartGrower } from "../../../store/reducers/report";

const ModalFilterDocMingguan = (props) => {
  const {
    filters,
    listGrowerSuccess,
    listFarmSuccess,
    listKandangSucces,
    idDocSurvey,
  } = props;
  const [listFarm, setListFarm] = useState({});
  const [listKandang, setListKandang] = useState({});
  const [listIdSurvey, setListIdDocSurvey] = useState({});
  const [farm, setFarm] = useState({});
  const [idSurvey, setIdSurvey] = useState({});
  const [listMinggu, setListMinggu] = useState({});
  const [minggu, setMinggu] = useState({});
  const daftarMinggu = [];

  useEffect(() => {
    props.listFarm();
    props.listStandarGrower();
  }, []);

  useEffect(() => {
    if (listGrowerSuccess) {
      for (const key in listGrowerSuccess.data) {
        daftarMinggu.push({ value: key, label: `Minggu ${key}` });
      }
      if (daftarMinggu) {
        setListMinggu(
          daftarMinggu.map((item) => ({
            value: parseInt(item.value),
            label: item.label,
          })),
        );
      }
    }
  }, [listGrowerSuccess]);

  useEffect(() => {
    if (listFarmSuccess) {
      setListFarm(
        listFarmSuccess?.map((item) => ({
          label: item?.nama_farm,
          value: item?.id,
        })),
      );
    }
  }, [listFarmSuccess]);

  useEffect(() => {
    if (farm && farm?.value) {
      props.listKandang({
        nama_farm: farm?.label,
      });
    }
  }, [farm]);

  useEffect(() => {
    if (listKandangSucces) {
      setListKandang(
        listKandangSucces?.map((item) => ({
          label: item?.barcode,
          value: item?.id,
        })),
      );
    }
  }, [listKandangSucces]);

  const submitForm = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterDocMingguan({
        id_doc_survey: idDocSurvey,
        minggu: minggu,
      });
    }, 200);
  };

  const handleResetButton = () => {
    props.setShow(false);
    props.filterDocMingguan({
      id_doc_survey: idDocSurvey,
      minggu: null,
    });
    setIdSurvey(idDocSurvey);
    setMinggu("");
  };

  const handleBatal = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterDocMingguan({
        id_doc_survey: idDocSurvey,
        minggu: minggu,
      });
      setIdSurvey(idDocSurvey);
      setMinggu("");
    }, 1000);
  };

  return (
    <div>
      <Modal size={"md"} onHide={handleBatal} show={props.show} centered>
        <ModalHeader closeButton>Filter</ModalHeader>
        <ModalBody>
          <div className="info">
            <InfoRounded color="inherit" fontSize="medium" />
            <div className="info-text">Pilih data yang ingin difilter</div>
          </div>
          <div className="select-wrapper">
             <SelectComponent
              disabled={true}
              options={listIdSurvey}
              label={"ID DOC Surveyor"}
              customParseData={(item) => ({
                label: item?.id_doc_survey,
                value: item?.id_doc_survey,
              })}
              onChange={(e) => {
                setIdSurvey(e);
              }}
              value={
                idSurvey === null
                  ? { value: "", label: "Semua Id Survey" }
                  : filters?.id_doc_survey?.value && !idSurvey?.value
                  ? {
                      value: filters?.id_doc_survey?.value,
                      label: filters?.id_doc_survey?.label,
                    }
                  : idSurvey?.value
                  ? { value: idSurvey?.value, label: idSurvey?.label }
                  : { value: "", label: "Semua Id Survey" }
              }
            />
            <SelectComponent
              options={listMinggu}
              label={"Minggu"}
              customParseData={(item) => ({
                label: item?.label,
                value: item?.value,
              })}
              onChange={(e) => {
                setMinggu(e);
              }}
              value={
                minggu === null
                  ? { value: "", label: "Semua Minggu" }
                  : filters?.minggu?.value && !minggu?.value
                  ? {
                      value: filters?.minggu?.value,
                      label: filters?.minggu?.label,
                    }
                  : minggu?.value
                  ? { value: minggu?.value, label: minggu?.label }
                  : { value: "", label: "Semua Minggu" }
              }
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: `space-between` }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 ps-2">
                <Button
                  variant="outlined"
                  className="btn-btl-ubah-pass"
                  style={{ right: "2px" }}
                  onClick={handleResetButton}>
                  Reset
                </Button>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-end">
                  <Button
                    variant="outlined"
                    className="btn-btl-ubah-pass"
                    style={{ right: "2px" }}
                    onClick={handleBatal}>
                    Batal
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-simpan-ubah-pass"
                    style={{ right: "2px" }}
                    onClick={() => submitForm()}>
                    Terapkan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listDocMingguan, listFarm, listKandang, listIP }) {
  return {
    listFarmSuccess: getListFarm(listFarm),
    listKandangSucces: getListKandang(listKandang),
    listDocMingguanSuccess: getListDocMingguan(listDocMingguan),
    filters: getFiltersMingguan(listDocMingguan),
    pageData: getPageDataMingguan(listDocMingguan),
    searchText: getSearchTextDocMingguan(listDocMingguan),
    listGrowerSuccess: getDataStandartGrower(listIP),
  };
}

export default connect(mapStateToProps, {
  listDocMingguan,
  filterDocMingguan,
  listFarm,
  listKandang,
  listStandarGrower,
})(ModalFilterDocMingguan);
