import {
  UPDATE_DOC_PAGE,
  LIST_DOC_PENDING,
  LIST_DOC_SUCCESS,
  LIST_DOC_ERROR,
  UPDATE_FILTER_DOC,
  UPDATE_SEARCH_DOC,
  TAMBAH_DOC_PENDING,
  TAMBAH_DOC_SUCCESS,
  TAMBAH_DOC_ERROR,
  EDIT_DOC_PENDING,
  EDIT_DOC_SUCCESS,
  EDIT_DOC_ERROR,
  DELETE_DOC_PENDING,
  DELETE_DOC_SUCCESS,
  DELETE_DOC_ERROR,
  DETAIL_DOC_ERROR,
  DETAIL_DOC_PENDING,
  DETAIL_DOC_SUCCESS,
  DOWNLOAD_DOC_ERROR,
  DOWNLOAD_DOC_PENDING,
  DOWNLOAD_DOC_SUCCESS,
  DETAIL_DOC_MINGGUAN_ERROR,
  DETAIL_DOC_MINGGUAN_PENDING,
  DETAIL_DOC_MINGGUAN_SUCCESS,
  UPDATE_DOC_MINGGUAN_PAGE,
  LIST_DOC_MINGGUAN_PENDING,
  LIST_DOC_MINGGUAN_SUCCESS,
  LIST_DOC_MINGGUAN_ERROR,
  UPDATE_FILTER_MINGGUAN_DOC,
  UPDATE_SEARCH_MINGGUAN_DOC,
  DOWNLOAD_DOC_MINGGUAN_ERROR,
  DOWNLOAD_DOC_MINGGUAN_SUCCESS,
  DOWNLOAD_DOC_MINGGUAN_PENDING,
} from "../../actions/doc";

const initialState = {
  listDocs: [],
  detailDoc: {},
  detailMingguanDoc: {},
  listDocsMingguan: [],
  pending: false,
  error: null,
  pageData: {
    total: null,
    lengthItem: 10,
    page: 1,
  },
  filters: {
    id_cage: {},
    id_farm: {},
    surveyor: {},
    tanggal_monitoring: null,
  },
  filterDocMingguan: {
    // id_farm: {},
    // id_cage: {},
    id_doc_survey: {},
    minggu: {},
  },
  search_text: "",
  search: "",
  // id_cage: "",
};

export const listDoc = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        listDocs: action.data.data,
        pageData: {
          ...state.pageData,
          total: action.data.total,
        },
      };
    case LIST_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case UPDATE_DOC_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          page: action.data,
        },
      };
    case UPDATE_FILTER_DOC:
      return {
        ...state,
        filters: {
          id_cage: action.data.id_cage,
          id_farm: action.data.id_farm,
          surveyor: action.data.surveyor,
          tanggal_monitoring: action.data.tanggal_monitoring,
        },
      };
    case UPDATE_SEARCH_DOC:
      return {
        ...state,
        search: action.data,
      };
    default:
      return state;
  }
};

export const tambahDoc = (state = initialState, action) => {
  switch (action.type) {
    case TAMBAH_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case TAMBAH_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case TAMBAH_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const editDoc = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export function deleteDoc(state = initialState, action) {
  switch (action.type) {
    case DELETE_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteDoc: action.data,
      };
    case DELETE_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const detailDoc = (state = initialState, action) => {
  switch (action.type) {
    case DETAIL_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        detailDoc: action.data,
        error: false,
      };
    case DETAIL_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const downloadReportDoc = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DOWNLOAD_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DOWNLOAD_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const detailMingguanDoc = (state = initialState, action) => {
  switch (action.type) {
    case DETAIL_DOC_MINGGUAN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_DOC_MINGGUAN_SUCCESS:
      return {
        ...state,
        pending: false,
        detailMingguanDoc: action.data,
        error: false,
      };
    case DETAIL_DOC_MINGGUAN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const listDocMingguan = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DOC_MINGGUAN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_DOC_MINGGUAN_SUCCESS:
      return {
        ...state,
        pending: false,
        listDocsMingguan: action.data.data,
        pageData: {
          ...state.pageData,
          total: action.data.total,
        },
      };
    case LIST_DOC_MINGGUAN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case UPDATE_DOC_MINGGUAN_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          page: action.data,
        },
      };
    case UPDATE_FILTER_MINGGUAN_DOC:
      return {
        ...state,
        filterDocMingguan: {
          // id_farm: action.data.id_farm,
          // id_cage: action.data.id_cage,
          id_doc_survey: action.data.id_doc_survey,
          minggu: action.data.minggu,
        },
      };

    case UPDATE_SEARCH_MINGGUAN_DOC:
      return {
        ...state,
        search_text: action.data,
      };
    default:
      return state;
  }
};

export const downloadReportDocMingguan = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_DOC_MINGGUAN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DOWNLOAD_DOC_MINGGUAN_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DOWNLOAD_DOC_MINGGUAN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getListDoc = (state) => state.listDocs;
export const getListDocPending = (state) => state.pending;
export const getListDocError = (state) => state.error;
export const getPageData = (state) => state.pageData;
export const getFilters = (state) => state.filters;
export const getSearchTextDoc = (state) => state.search;
export const getDeleteDocPending = (state) => state.pending;
export const getDeleteDoc = (state) => state.deleteDoc;
export const getDeleteDocError = (state) => state.error;
export const getDetailDocSuccess = (state) => state.detailDoc;
export const getDetailDocPending = (state) => state.pending;
export const getDetailDocError = (state) => state.error;
export const getDownloadDocPending = (state) => state.pending;
export const getDownloadDocError = (state) => state.error;
export const getDetailDocMingguanSuccess = (state) => state.detailMingguanDoc;
export const getDetailDocMingguanPending = (state) => state.pending;
export const getDetailDocMingguanError = (state) => state.error;
export const getListDocMingguan = (state) => state.listDocsMingguan;
export const getListDocPendingMingguan = (state) => state.pending;
export const getListDocErrorMingguan = (state) => state.error;
export const getPageDataMingguan = (state) => state.pageData;
export const getFiltersMingguan = (state) => state.filterDocMingguan;
export const getSearchTextDocMingguan = (state) => state.search_text;
export const getDownloadDocMingguanPending = (state) => state.pending;
export const getDownloadDocMingguanError = (state) => state.error;
