import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { connect } from "react-redux";
import _ from "lodash";
import DetailKandang from "./DetailKandang";
import { detailKandang } from "../../../store/actions/kandang";
import {
  getDetailKandangSuccess,
  getDetailKandangPending,
  getDetailKandangError,
} from "../../../store/reducers/kandang";
import { listReport, filterReport } from "../../../store/actions/report";
import { getFilters } from "../../../store/reducers/report";

function HeaderDetailKandang(props) {
  const {
    filters,
    detailKandangSuccess,
    detailKandangPending,
    detailKandangError,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [showDetail, setShowDetail] = useState("Kandang");
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalUnduhReport, setShowModalUnduhReport] = useState(false);
  const [idFarm, setIdFarm] = useState(null);
  const [namaFarm, setNamaFarm] = useState(null);
  const [idKandang, setIdKandang] = useState(null);
  const [barcodeKandang, setBarcodeKandang] = useState(null);

  useEffect(() => {
    props.detailKandang(props.match.params.id);
    setIdFarm(props.match.params.idFarm);
  }, []);

  useEffect(() => {
    if (detailKandangSuccess) {
      setNamaFarm(detailKandangSuccess?.farm?.nama_farm);
      setIdKandang(detailKandangSuccess?.id);
      setBarcodeKandang(detailKandangSuccess?.barcode);
    }
  }, [detailKandangSuccess]);

  const handleShowReport = () => {
    props.filterReport({
      farm: { value: idFarm, label: namaFarm },
      kandang: { value: idKandang, label: barcodeKandang },
    });
    history.push(`/report`);
  };

  const switchComponent = (show) => {
    switch (show) {
      case "Report":
        return handleShowReport();
      case "Kandang":
        return (
          <DetailKandang
            detailKandangSuccess={detailKandangSuccess}
            detailKandangPending={detailKandangPending}
            detailKandangError={detailKandangError}
          />
        );
      default:
        return <div>Loading...</div>;
    }
  };

  const buttonSwitch = (title = "", className = "", onClick = () => {}) => {
    return (
      <Button
        variant="contained"
        disableElevation
        className={className}
        sx={{
          paddingY: "10px",
        }}
        onClick={onClick}>
        {title}
      </Button>
    );
  };

  const onKandangClick = () => {
    setShowDetail("Kandang");
  };

  const onReportClick = () => {
    setShowDetail("Report");
  };

  const handleUnduhReportClick = () => {
    setShowModalUnduhReport(true);
  };

  const handleGoBack = () => {
    history.replace({
      pathname: `/farmmanagement/${location?.state?.publicIdFarm}`,
      state: {
        activeTab: location?.state?.activeTab ?? "Kandang",
      },
    });
  };

  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <ArrowBack sx={{ cursor: "pointer" }} onClick={handleGoBack} />
          <span
            className="text-extra-bold"
            style={{
              fontSize: "24px",
              fontFamily: "PlusJakartaSans-Bold",
              marginLeft: "26px",
            }}>
            Kandang Management
          </span>
        </div>
        {showDetail === "Report" && (
          <div className="d-flex align-items-center">
            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                color: "black",
                fontWeight: "800",
              }}
              size="large"
              className={`filters`}
              onClick={(e) => setShowModalFilter(!showModalFilter)}>
              {filters?.tanggal_mulai ||
              filters?.tanggal_akhir ||
              filters?.tanggal_mulai_pullet_in ||
              filters?.tanggal_akhir_pullet_in ||
              filters?.ip?.value ? (
                <span className="dot"></span>
              ) : (
                ``
              )}
              Filter
            </Button>
            <div className="custom-devider" />
            <Button
              color="primary"
              variant="contained"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                fontWeight: "800",
                marginLeft: "12px",
                width: "inherit !important",
              }}
              size="large"
              onClick={handleUnduhReportClick}>
              Unduh Report
            </Button>
          </div>
        )}
      </div>
      <Row style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Col>
          {buttonSwitch(
            "Informasi Kandang",
            showDetail === "Kandang"
              ? "detail-farm-new-btn-active"
              : "detail-farm-new-btn-non-active",
            onKandangClick,
          )}
          {buttonSwitch(
            "Report",
            showDetail === "Report"
              ? "btn-report-new-active"
              : "btn-report-new-non-active",
            onReportClick,
          )}
        </Col>
      </Row>
      <div>{switchComponent(showDetail)}</div>
    </Container>
  );
}

const mapStateToProps = ({ listReport, detailKandang }) => {
  return {
    detailKandangSuccess: getDetailKandangSuccess(detailKandang),
    detailKandangPending: getDetailKandangPending(detailKandang),
    detailKandangError: getDetailKandangError(detailKandang),
    filters: getFilters(listReport),
  };
};

export default connect(mapStateToProps, {
  detailKandang,
  listReport,
  filterReport,
})(HeaderDetailKandang);
