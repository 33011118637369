import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import SelectComponent from "../../../components/commons/forms/select";

import { 
  listKandang, 
  listSurveyor, 
  filterKandang 
} from "../../../store/actions/kandang";
import { 
  getListSurveyor, 
  getFilters, 
} from "../../../store/reducers/kandang";

const ModalFilterKandang = (props) => {
  const [filterKepKan, setFilterKepKan] = useState({});
  const [filterStatus, setFilterStatus] = useState({});

  const { 
    listKepKanSuccess, 
    filters, 
    filterNamaFarm
  } = props;

  const statusOptions = [
    { value: "aktif", label: "Aktif" },
    { value: "tidakaktif", label: "Tidak Aktif" },
    { value: "panen", label: "Panen" },
  ];

  useEffect(() => {
    props.listSurveyor();
    props.filterKandang({ 
      nama_farm: filterNamaFarm, 
    });
  }, []);

  const submitForm = () => {
    props.setShow(false);
    setTimeout(() => {
      props.filterKandang({ 
        nama_farm: filterNamaFarm, 
        surveyor: filterKepKan, 
        status: filterStatus
      });
    }, 1000);
  };

  const resetButton = () => {
    props.setShow(false);
    props.filterKandang({ 
      nama_farm: filterNamaFarm, 
      surveyor: null, 
      status: null 
    });
    setFilterKepKan("");
    setFilterStatus("");
  };

  const handleBatal = () => {
    setTimeout(() => {
      props.setShow(false);
      setFilterKepKan("");
      setFilterStatus("");
    }, 1000);
  };

  return (
    <Modal size={"md"} onHide={handleBatal} show={props.show} centered>
      <ModalHeader closeButton>Filter</ModalHeader>
      <ModalBody>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin difilter</div>
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={statusOptions}
            label={"Status Kandang"}
            onChange={(e) => {
              setFilterStatus(e);
            }}
            value={
              filterStatus === null
                ? { value: "", label: "Semua status kandang" }
                : filters.status?.value && !filterStatus?.value
                ? { value: filters?.status?.value, label: filters?.status?.label }
                : filterStatus?.value
                ? { value: filterStatus?.value, label: filterStatus?.label }
                : { value: "", label: "Semua status kandang" }
            }
          />
        </div>

        <div className="select-wrapper">
          <SelectComponent
            options={listKepKanSuccess ? listKepKanSuccess : []}
            label={"Surveyor"}
            isAsync={true}
            asyncUrl={`/v1/user/surveyor`}
            customParseData={(item) => ({
              label: item?.full_name,
              value: item?.user_id
            })}
            onChange={(e) => {
              setFilterKepKan(e);
            }}
            value={
              filterKepKan === null
                ? { value: "", label: "Semua Surveyor" }
                : filters.surveyor?.value && !filterKepKan?.value
                ? { value: filters?.surveyor?.value, label: filters?.surveyor?.label }
                : filterKepKan?.value
                ? { value: filterKepKan?.value, label: filterKepKan?.label }
                : { value: "", label: "Semua Surveyor" }
            }
          />
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: `space-between` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-2">
              <Button
                variant="outlined"
                disableElevation
                className="btn-btl-ubah-pass"
                style={{
                  right: "2px",
                }}
                onClick={resetButton}
              >
                Reset
              </Button>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={handleBatal}
                >
                  Batal
                </Button>

                <Button
                  variant="contained"
                  disableElevation
                  className="btn-simpan-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={(e) => submitForm()}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ listKandang, listSurveyor }) => {
  return {
    listKepKanSuccess: getListSurveyor(listSurveyor),
    filters: getFilters(listKandang),
  };
};

export default connect(mapStateToProps, { listSurveyor, listKandang, filterKandang })(ModalFilterKandang);
