import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import inputRules from "../../utils/inputRules";
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, Button } from "@mui/material";
import { connect } from "react-redux";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";

import Input from "../../components/commons/forms/authentication/input";
import {
  getLoginUser,
  getLoginUserError,
  getLoginUserPending,
  getLoginCustomerCare,
  getLoginCustomerCareError,
  getLoginCustomerCarePending,
} from "../../store/reducers/auth";
import { loginUser, loginCustomerCare } from "../../store/actions/auth";
import { getCustomerCare } from "../../utils";

function Login(props) {
  const [visiblePwd, setVisiblePwd] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorUsername, setErrorUsername] = useState("");
  const [errorPwd, setErrorPwd] = useState("");
  const history = useHistory();
  const [userData, setUserData] = useState(null);

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);
  const [loginAsCustomerCare, setLoginAsCustomerCare] = useState(false);

  const handleInput = (e, set, error) => {
    const value = e.target.value;
    const rule = e.target.getAttribute(`rule`);
    if (!value) {
      error(`${rule} wajib diisi`);
    } else {
      // const match = new RegExp(inputRules[rule]).test(value);
      // if (!match) {
      //   if (rule === "Email") {
      //     error(`Format email tidak sesuai`);
      //   } else {
      //     error(`Password tidak sesuai`);
      //   }
      // } else {
      //   error(``);
      // }
      error(``);
    }
    set(value);
  };

  const handleSubmit = () => {
    setDisableBtnSubmit(true);
    const data = { username, password };
    handleError(data);

    for (var key in data) {
      if (data[key] === "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    if (loginAsCustomerCare) {
      props.loginCustomerCare(data, successLogin, failedLogin);
    } else {
      props.loginUser(data, successLogin, failedLogin);
    }

    // history.push("/farmmanagement");
  };

  const failedLogin = () => {
    setDisableBtnSubmit(false);
  };
  const successLogin = () => {
    setDisableBtnSubmit(false);
  };

  const handleError = (data) => {
    if (!data.username) {
      setErrorUsername("Username wajib diisi");
    }
    if (!data.password) {
      setErrorPwd("Password wajib diisi");
    }
  };

  // useEffect(() => {
  //   const dataUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  //   setUserData(dataUser);
  //   const nowTokenExpired = new Date().getTime();
  //   if (dataUser?.token && nowTokenExpired < dataUser?.tokenExpired) {
  //     history.push("/");
  //   } else {
  //     localStorage.removeItem(LOCAL_STORAGE_KEY);
  //   }
  // }, []);

  return (
    <div
      className="login-wrapper"
      style={{ overflowX: "hidden", overflowY: "auto" }}>
      <Row>
        <Col lg={6} md={6} sm={12} style={{ position: "relative" }}>
          <div className="login-form-wrapper">
            <div className="title-wrapper">
              <span>Masuk</span>
              <span>Masukkan username dan password Anda</span>
            </div>
            <div className="form-wrapper">
              <Input
                label={`Username`}
                type={`text`}
                placeholder={`Masukkan Username`}
                defultValue={username || null || ""}
                rule={`Username`}
                onChange={(e) => handleInput(e, setUsername, setErrorUsername)}
                error={errorUsername}
              />

              <Input
                label={`Password`}
                type={visiblePwd ? "text" : "password"}
                placeholder={`Masukkan Password`}
                defaultValue={password || null || ""}
                onChange={(e) => handleInput(e, setPassword, setErrorPwd)}
                error={errorPwd}
                rightIcon={
                  <IconButton
                    onClick={(e) => setVisiblePwd(visiblePwd ? false : true)}>
                    {visiblePwd ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
            </div>
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                id="loginAsCustomerCare"
                checked={loginAsCustomerCare}
                onChange={() => setLoginAsCustomerCare(!loginAsCustomerCare)}
                className="checkbox"
              />
              <label
                htmlFor="loginAsCustomerCare"
                style={{ fontWeight: "500" }}
                className="label-checkbox">
                Login sebagai Customer Care
              </label>
            </div>

            <Button
              variant="contained"
              className="submit-btn-wrapper login-btn"
              onClick={(e) => handleSubmit()}
              disabled={disableBtnSubmit}>
              Masuk
            </Button>

            <div className="footer-wrapper">
              <span>Copyright &copy; 2022 PT Cipta Sama Abadi</span>
            </div>
          </div>
        </Col>
        <Col
          lg={6}
          md={6}
          sm={12}
          style={{
            backgroundImage: `url("${process.env.REACT_APP_HOST}/img/bg.jpg")`,
          }}
          className="bg desktop">
          <div className="blue-bg" />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = ({ loginUser }) => {
  return {
    loginUserSuccess: getLoginUser(loginUser),
    loginUserPending: getLoginUserPending(loginUser),
    loginUserError: getLoginUserError(loginUser),
    // loginCustomerCareSuccess: getLoginCustomerCare(loginCustomerCare),
    // loginCustomerCarePending: getLoginCustomerCarePending(loginCustomerCare),
    // loginCustomerCareError: getLoginCustomerCareError(loginCustomerCare),
  };
};

export default connect(mapStateToProps, { loginUser, loginCustomerCare })(
  Login,
);
