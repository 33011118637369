import { createBrowserHistory } from "history";
import { LOCAL_STORAGE_KEY } from "./constants";

export const history = createBrowserHistory();

export const month = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Augustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const getUser = () => {
  const takeDataFromLocal = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (takeDataFromLocal) {
    const user = JSON.parse(takeDataFromLocal);
    return user;
  } else return null;
};

export const role = {
  management: `management`,
  kepala_kandang: `kepalakandang`,
  customer_care: `customer_care`,
  admin: `admin`,
};

export const toCapitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// input string dd/mm/yyyy
export const defaultDateFormat = (str) => {
  let arr = str.split("/");
  [arr[0], arr[2]] = [arr[2], arr[0]];
  return arr.join("-");
};

export const dateFormat = (date) => {
  const newDate = new Date(date);
  const hari =
    newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
  const bulan = month[newDate.getMonth()];
  return hari + " " + bulan + " " + newDate.getFullYear();
};

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  if (date === null) return null;
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
}
