import React from 'react'
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const Index = (props) => {
    const { pageData, totalData, handleClickPage } = props;
  
    const change = (page) => {
      if (handleClickPage) {
        handleClickPage(page);
      }
    }

    const getPage = (length, total, activePage) => {
      const totalPage = Math.ceil(total / length);
      const activePageLessthanThree = activePage < 3 ? 1 : 2;
      const minusOfStartListPage = activePage === totalPage ? activePageLessthanThree : 1;
      const startListPage = activePage > 1 ? activePage - minusOfStartListPage : 1;
      const plusActivePage = activePage === 1 ? 2 : 1;
      const plusOfEndListPage = activePage + 1 > totalPage ? activePage : activePage + plusActivePage;
      const endListPage = totalPage <= 3 ? totalPage : plusOfEndListPage;
      const listPageNum = [];
      for (let i = startListPage; i <= endListPage; i++) {
        listPageNum.push(i);
      }
      return listPageNum.map((v, k) => {
        return (
          <div
            key={k}
            className={`page-item page-num ${activePage === v ? "active" : ""}`}
            onClick={(e) => change(v)}
          >
            {v}
          </div>
        );
      });
    };
  
  return (
    <>
    {pageData?.total !== 0 && pageData?.total !== null && (
      <div className="pagination-wrapper">
        <div className="data-count-info">
          {totalData}{" "}
          dari {pageData.total ? pageData.total : 0} data
        </div>
        <div className="page-wrapper">
          <div
            className={`page-item page-btn ${
              pageData.page === 1 ? "disabled" : ""
            }`}
            onClick={(e) =>
              change(pageData.page === 1 ? undefined : pageData.page - 1)
            }
          >
            <ArrowBack />
          </div>

          {getPage(pageData.lengthItem, pageData.total, pageData.page)}

          <div
            className={`page-item page-btn ${
              pageData.page === Math.ceil(pageData.total / pageData.lengthItem)
                ? "disabled"
                : ""
            }`}
            onClick={(e) =>
              change(
                pageData.page === Math.ceil(pageData.total / pageData.lengthItem)
                  ? undefined
                  : pageData.page + 1
              )
            }
          >
            <ArrowForward />
          </div>
        </div>
      </div>
    )}
    </>
  )
};

export default Index;

