import React from "react";
import { ListGroup } from "react-bootstrap";

const ListItem = ({ label, value, style }) => {
  return (
    <ListGroup.Item style={{ padding: "1rem" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 text-extra-bold">{label}</div>
          <div className="col-md-8" style={style}>
            {value}
          </div>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default ListItem;
