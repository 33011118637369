import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import moment from "moment";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import SelectComponent from "../../../components/commons/forms/select";
import CustomDatePicker from "../../../components/commons/forms/datepicker/CustomDatePicker";
import { connect } from "react-redux";
import { listDoc, filterDoc } from "../../../store/actions/doc";
import { InfoRounded } from "@mui/icons-material";
import {
  getFilters,
  getListDoc,
  getPageData,
  getSearchTextDoc,
} from "../../../store/reducers/doc";
import { useState } from "react";
import { useEffect } from "react";
import { formatDate } from "../../../utils";
import { listSurveyor, listKandang } from "../../../store/actions/kandang";
import {
  getListSurveyor,
  getListKandang,
} from "../../../store/reducers/kandang";
import { listFarm } from "../../../store/actions/farm";
import { getListFarm } from "../../../store/reducers/farm";

const ModalFilterDoc = (props) => {
  const {
    filters,
    listDocSuccess,
    listSurveyorSuccess,
    listKandangSuccess,
    listFarmSuccess,
  } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const [filterTanggal, setFilterTanggal] = useState("");
  const [errorTanggal, setErrorTanggal] = useState("");
  const [listFarm, setListFarm] = useState({});
  const [listKandang, setListKandang] = useState({});
  const [listTanggal, setListTanggal] = useState({});
  const [listSurveyor, setListSurveyor] = useState({});
  const [surveyor, setSurveyor] = useState({});
  const [kandang, setKandang] = useState({});
  const [farm, setFarm] = useState({});

  useEffect(() => {
    props.listDoc();
    props.listSurveyor();
    props.listKandang();
    props.listFarm();
  }, []);

  useEffect(() => {
    if (listFarmSuccess) {
      setListFarm(
        listFarmSuccess?.map((item) => ({
          label: item?.nama_farm,
          value: item?.id,
        })),
      );
    }
  }, [listFarmSuccess]);

  useEffect(() => {
    if (farm && farm?.value) {
      props.listKandang({
        nama_farm: farm?.label,
      });
    }
  }, [farm]);

  useEffect(() => {
    if (listKandangSuccess) {
      setListKandang(
        listKandangSuccess?.map((item) => ({
          label: item?.barcode,
          value: item?.id,
        })),
      );
    }
  }, [listKandangSuccess]);

  useEffect(() => {
    if (listSurveyorSuccess) {
      setListSurveyor(
        listSurveyorSuccess?.map((item) => ({
          label: item?.label,
          value: item?.value,
        })),
      );
    }
  }, [listSurveyorSuccess]);

  useEffect(() => {
    if (listDocSuccess) {
      setListTanggal(
        listDocSuccess?.map((item) => ({
          label: item?.tanggal,
          value: item?.tanggal,
        })),
      );
    }
  }, [listDocSuccess]);

  const submitForm = () => {
    setTimeout(() => {
      const formattedDate = filterTanggal
        ? moment(selectedDate).format("DD-MM-YYYY")
        : "";
      props.setShow(false);
      props.filterDoc({
        id_cage: kandang,
        id_farm: farm,
        surveyor: surveyor,
        tanggal_monitoring: formattedDate,
      });
    }, 200);
  };

  const handleResetButton = () => {
    props.setShow(false);
    props.filterDoc({
      id_cage: null,
      id_farm: null,
      surveyor: null,
      tanggal_monitoring: null,
    });
    setKandang("");
    setFarm("");
    setSurveyor("");
    setFilterTanggal("");
    setSelectedDate(new Date());
  };

  const handleClose = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterDoc({
        id_cage: null,
        id_farm: null,
        surveyor: null,
        tanggal_monitoring: null,
      });
      setKandang("");
      setFarm("");
      setSurveyor("");
      setFilterTanggal("");
      setSelectedDate(new Date());
    }, 100);
  };

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      setFilterTanggal(formatDate(date));
      setErrorTanggal("");
    } else {
      setSelectedDate("");
      setErrorTanggal("");
    }
  };
  return (
    <div>
      <Modal size={"md"} onHide={handleClose} show={props.show} centered>
        <ModalHeader closeButton>Filter</ModalHeader>
        <ModalBody>
          <div className="info">
            <InfoRounded color="inherit" fontSize="medium" />
            <div className="info-text">Pilih data yang ingin difilter</div>
          </div>
          <div className="select-wrapper">
            <SelectComponent
              options={listFarm}
              label={"Farm"}
              isAsync={true}
              asyncUrl={`/v1/farm`}
              customParseData={(item) => ({
                label: item?.nama_farm,
                value: item?.id,
              })}
              onChange={(e) => {
                setFarm(e);
              }}
              value={
                farm === null
                  ? { value: "", label: "Semua Farm" }
                  : filters?.farm?.value && !farm?.value
                  ? { value: filters?.farm?.value, label: filters?.farm?.label }
                  : farm?.value
                  ? { value: farm?.value, label: farm?.label }
                  : { value: "", label: "Semua Farm" }
              }
            />
          </div>
          <div>
            <SelectComponent
              options={listKandang}
              label={"Kandang"}
              isAsync={true}
              asyncUrl={`/v1/cage`}
              customParseData={(item) => ({
                label: item?.id_cage,
                value: item?.id_cage,
              })}
              onChange={(e) => {
                setKandang(e);
              }}
              value={
                kandang === null
                  ? { value: "", label: "Semua Kandang" }
                  : filters.id_cage.value && !kandang.value
                  ? {
                      value: filters.id_cage.value,
                      label: filters.id_cage.label,
                    }
                  : kandang.value
                  ? { value: kandang.value, label: kandang.label }
                  : { value: "", label: "Semua Kandang" }
              }
            />
          </div>
          <div className="select-wrapper">
            <SelectComponent
              options={listSurveyor}
              isAsync={true}
              asyncUrl={`/v1/user/surveyor`}
              label={"Surveyor"}
              customParseData={(item) => ({
                label: item?.full_name,
                value: item?.id,
              })}
              onChange={(e) => {
                setSurveyor(e);
              }}
              value={
                surveyor === null
                  ? { value: "", label: "Semua Surveyor" }
                  : filters?.id_surveyor?.value && !surveyor?.value
                  ? {
                      value: filters?.id_surveyor?.value,
                      label: filters?.id_surveyor?.label,
                    }
                  : surveyor?.value
                  ? { value: surveyor?.value, label: surveyor?.label }
                  : { value: "", label: "Semua Surveyor" }
              }
            />
          </div>
          <div className="select-wrapper">
            <CustomDatePicker
              label={"Tanggal Monitoring"}
              onChange={handleDateChange}
              selected={selectedDate}
              placeholder={"Masukkan Tanggal Monitoring"}
              error={errorTanggal}
              value={filterTanggal}
              groupClass="form-group"
            />
          </div>{" "}
        </ModalBody>
        <ModalFooter style={{ justifyContent: `space-between` }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 ps-2">
                <Button
                  variant="outlined"
                  className="btn-btl-ubah-pass"
                  style={{ right: "2px" }}
                  onClick={handleResetButton}>
                  Reset
                </Button>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-end">
                  <Button
                    variant="outlined"
                    className="btn-btl-ubah-pass"
                    style={{ right: "2px" }}
                    onClick={handleClose}>
                    Batal
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-simpan-ubah-pass"
                    style={{ right: "2px" }}
                    onClick={() => submitForm()}>
                    Terapkan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listDoc, listSurveyor, listKandang, listFarm }) {
  return {
    filters: getFilters(listDoc),
    pageData: getPageData(listDoc),
    searchText: getSearchTextDoc(listDoc),
    listDocSuccess: getListDoc(listDoc),
    listSurveyorSuccess: getListSurveyor(listSurveyor),
    listKandangSuccess: getListKandang(listKandang),
    listFarmSuccess: getListFarm(listFarm),
    listTanggalSuccess: getListDoc(listDoc),
  };
}

export default connect(mapStateToProps, {
  listDoc,
  filterDoc,
  listSurveyor,
  listKandang,
  listFarm,
})(ModalFilterDoc);
