import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Container, ListGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import ListItem from "../../../components/commons/listGroup/listItem";
import { numberWithCommas } from "../../../utils/constants";

function DetailDoc(props) {
  const {
    detailDocSuccess,
    detailDocPending,
    detailDocError,
    activeTab,
    showDoc,
  } = props;
  const history = useHistory();
  const handleGoBack = () => {
    const state = {
      activeTab: activeTab,
      showDoc: showDoc,
    };
    history.replace({
      pathname: "/report",
      state: state,
    });
  };
  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div>
        {detailDocPending ? (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: "50%" }}>
            Mengambil data report...
          </div>
        ) : detailDocError ? (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: "50%" }}>
            Gagal mengambil data report...
          </div>
        ) : (
          <div className="mb-5">
            <ListGroup className="cek" style={{ borderRadius: "12px" }}>
              <ListItem
                label="Jumlah Box Sampling"
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.jumlah_box_sampling + " Box"
                }
              />
              <ListItem
                label="DOC Mati di Box"
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.jumlah_doc_mati + " Ekor"
                }
              />
              <ListItem
                label="Jumlah DOC Kurang"
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.jumlah_doc_kurang + " Ekor"
                }
              />
              <ListItem
                label="Jumlah DOC Lebih"
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.jumlah_doc_lebih + " Ekor"
                }
              />
              <ListItem
                label="DOC Dehidrasi"
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.doc_dehydrasi + " Ekor"
                }
              />
              <ListItem
                label="DOC Lemah (Tidak Aktif)"
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.doc_lemah + " Ekor"
                }
              />
              <ListItem
                label="DOC Kecil (BW < 37 gr)"
                value={
                  detailDocSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailDocSuccess.doc_kecil + " Ekor"),
                      )
                }
              />
              <ListItem
                label="DOC Cross Beak"
                value={
                  detailDocSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailDocSuccess.doc_cross_beak + " Ekor"),
                      )
                }
              />
              <ListItem
                label="DOC Missing Eye"
                value={
                  detailDocSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailDocSuccess.doc_missing_eye + " Ekor"),
                      )
                }
              />
              <ListItem
                label="DOC Pusar Basah"
                value={
                  detailDocSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailDocSuccess.doc_pusar_basah + " Ekor"),
                      )
                }
              />
              <ListItem
                label="DOC Black Navel"
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.doc_black_navel + " Ekor"
                }
              />
              <ListItem
                label="DOC String Navel"
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.doc_string_navel + " Ekor"
                }
              />
              <ListItem
                label="DOC Torticolis"
                value={
                  detailDocSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailDocSuccess.doc_torticolis + " Ekor"),
                      )
                }
              />
              <ListItem
                label="DOC Pasta Vent"
                value={
                  detailDocSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailDocSuccess.doc_pasta_vent + " Ekor"),
                      )
                }
              />
              <ListItem
                label="DOC Red Hock"
                value={
                  detailDocSuccess == null
                    ? ""
                    : numberWithCommas(
                        String(detailDocSuccess.doc_red_hock + " Ekor"),
                      )
                }
              />
              <ListItem
                label="Asal Hatchery"
                style={{ textTransform: "capitalize" }}
                value={
                  detailDocSuccess == null
                    ? ""
                    : detailDocSuccess.asal_pullet == null
                    ? "-"
                    : detailDocSuccess.asal_pullet
                }
              />
            </ListGroup>
            <div className="d-flex flex-row-reverse">
              <Button
                variant="outlined"
                disableElevation
                className="btn-kembali-farm"
                onClick={handleGoBack}>
                Kembali
              </Button>
            </div>
          </div>
        )}
      </div>
      <div></div>
    </Container>
  );
}

export default withRouter(DetailDoc);
