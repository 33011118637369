import React from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import { numberWithCommas } from "../../../utils/constants";

function DetailKandang(props) {
  const {
    detailKandangSuccess,
    detailKandangPending,
    detailKandangError,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    history.replace({
      pathname: `/farmmanagement/${location?.state?.publicIdFarm}`,
      state: {
        activeTab: location?.state?.activeTab ?? "Kandang",
      },
    });
  };

  return (
    <div>
      {detailKandangPending ? (
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{ height: "50%" }}>
          Mengambil data kandang...
        </div>
      ) : detailKandangError ? (
        <div
          className="d-flex align-items-center justify-content-center w-100"
          style={{ height: "50%" }}>
          Gagal mengambil data kandang...
        </div>
      ) : (
        <div className="mb-5">
          <ListGroup className="cek" style={{ borderRadius: "12px" }}>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Barcode</div>
                  <div className="col-md-8">
                    {detailKandangSuccess == null
                      ? ""
                      : detailKandangSuccess?.barcode}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">
                    Kapasitas Kandang (ekor)
                  </div>
                  <div className="col-md-8">
                    {detailKandangSuccess == null
                      ? ""
                      : numberWithCommas(
                          String(
                            detailKandangSuccess?.kapasitas_kandang + " ekor",
                          ),
                        )}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Nama Farm</div>
                  <div className="col-md-8">
                    {detailKandangSuccess == null
                      ? ""
                      : detailKandangSuccess?.farm
                      ? detailKandangSuccess?.farm?.nama_farm
                      : ""}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Nama Group</div>
                  <div className="col-md-8">
                    {detailKandangSuccess == null
                      ? ""
                      : detailKandangSuccess?.farm
                      ? detailKandangSuccess?.farm?.nama_group
                      : ""}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Surveyor</div>
                  <div className="col-md-8">
                    {detailKandangSuccess == null
                      ? ""
                      : detailKandangSuccess?.farm
                      ? detailKandangSuccess?.farm?.surveyor_fullname
                      : ""}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Jenis Ayam</div>
                  <div className="col-md-8">
                    {detailKandangSuccess == null
                      ? ""
                      : detailKandangSuccess?.farm
                      ? detailKandangSuccess?.farm?.jenis_ayam
                      : ""}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Status Kandang</div>
                  <div className="col-md-8">
                    {detailKandangSuccess == null
                      ? ""
                      : detailKandangSuccess.status == "panen"
                      ? "Panen"
                      : detailKandangSuccess.status == "aktif"
                      ? "Aktif"
                      : "Tidak Aktif"}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
          <div className="d-flex flex-row-reverse">
            {/* <Button
              variant="contained"
              disableElevation
              className="btn-edit-farm"
              onClick={() => {
                history.push(
                  `/farmmanagement/${props.match.params.idFarm}/edit-kandang/${detailKandangSuccess?.barcode}`
                );
              }}
            >
              Edit
            </Button> */}
            <Button
              variant="outlined"
              disableElevation
              className="btn-kembali-farm"
              onClick={handleGoBack}>
              Kembali
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(DetailKandang);
