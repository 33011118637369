import { actionSuccess, actionError, actionPending } from "../action_types";
import API from "../API";
import { toastSuccess, toastError } from "../../../components/toast";
import { history } from "../../../utils";
import { LOCAL_STORAGE_KEY } from "../../../utils/constants";
import { CHANGE_PROFILE_OPTION } from "../profile";

export const LOGIN_USER_PENDING = `LOGIN_USER_PENDING`;
export const LOGIN_USER_SUCCESS = `LOGIN_USER_SUCCESS`;
export const LOGIN_USER_ERROR = `LOGIN_USER_ERROR`;
export const LOGIN_CUSTOMERCARE_PENDING = `LOGIN_CUSTOMERCARE_PENDING`;
export const LOGIN_CUSTOMERCARE_SUCCESS = `LOGIN_CUSTOMERCARE_SUCCESS`;
export const LOGIN_CUSTOMERCARE_ERROR = `LOGIN_CUSTOMERCARE_ERROR`;

export const LOGOUT_USER_PENDING = `LOGOUT_USER_PENDING`;
export const LOGOUT_USER_SUCCESS = `LOGOUT_USER_SUCCESS`;
export const LOGOUT_USER_ERROR = `LOGOUT_USER_ERROR`;
export const LOGOUT_CUSTOMERCARE_PENDING = `LOGOUT_CUSTOMERCARE_PENDING`;
export const LOGOUT_CUSTOMERCARE_SUCCESS = `LOGOUT_CUSTOMERCARE_SUCCESS`;
export const LOGOUT_CUSTOMERCARE_ERROR = `LOGOUT_CUSTOMERCARE_ERROR`;

const LOGIN_USER_URL = `/v1/auth`;
const LOGIN_CUSTOMERCARE_URL = `/v1/auth/customercare`;

export const loginUser =
  ({ username, password }, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(LOGIN_USER_PENDING));
    API.post(LOGIN_USER_URL, { username, password })
      .then((res) => {
        dispatch(actionSuccess(LOGIN_USER_SUCCESS, res.data.data));
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
        setTimeout(() => {
          history.push("/dashboard");
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        dispatch(actionError(LOGIN_USER_ERROR, error));
        const errorMessage = error.response.data.message;
        toastError(errorMessage, "");
        callbackFailed();
      });
  };

export const loginCustomerCare =
  ({ username, password }, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(LOGIN_CUSTOMERCARE_PENDING));
    API.post(LOGIN_CUSTOMERCARE_URL, { username, password })
      .then((res) => {
        dispatch(actionSuccess(LOGIN_CUSTOMERCARE_SUCCESS, res.data.data));
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
        setTimeout(() => {
          history.push("/farmmanagement");
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        dispatch(actionError(LOGIN_CUSTOMERCARE_ERROR, error));
        const errorMessage = error.response.data.message;
        toastError(errorMessage, "");
        callbackFailed();
      });
  };

export const logoutUser =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LOGOUT_USER_PENDING));
    try {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      dispatch(actionSuccess(CHANGE_PROFILE_OPTION, false));
      toastSuccess("Logout berhasil");
    } catch (error) {
      dispatch(actionError(LOGOUT_USER_ERROR, error));
      toastError(error, "");
    }
    dispatch(actionPending(LOGOUT_USER_SUCCESS));
    history.push("/");
  };

export const logoutCustomerCare =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LOGOUT_CUSTOMERCARE_PENDING));
    try {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      dispatch(actionSuccess(CHANGE_PROFILE_OPTION, false));
      toastSuccess("Logout Berhasil");
    } catch (error) {
      dispatch(actionError(LOGOUT_CUSTOMERCARE_ERROR, error));
      toastError(error, "");
    }
    dispatch(actionPending(LOGOUT_CUSTOMERCARE_SUCCESS));
    history.push("/");
  };
